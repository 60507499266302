import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { number, object, string } from 'yup'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import { theme } from '../../theme'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'

type IForgotPasswordForm = { step: number; email: string; password: string; code: string }

const ForgotPassword = () => {
  const strings = useStrings()
  const schema = object().shape({
    step: number(),
    code: string().when('step', { is: 2, then: string().required(strings.login_register_yup_code1) }),
    email: string().email(strings.login_yup_email1).required(strings.login_yup_email2),
    password: string().when('step', {
      is: 2,
      then: string()
        .matches(/^.{8,}$/, strings.login_yup_password1)
        .matches(/^.*[a-z].*$/, strings.login_yup_password2)
        .matches(/^.*[A-Z].*$/, strings.login_yup_password3)
        .matches(/^.*\d.*$/, strings.login_yup_password4)
        .required(strings.login_yup_password5)
    })
  })
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const methods = useForm<IForgotPasswordForm>({ resolver: yupResolver(schema), defaultValues: { step: 1 } })
  const { setError, watch, setValue } = methods

  const renderInner = () =>
    watch('step') === 1 ? (
      <Grid item>
        <ReactHookFormTextField label={strings.login_email_label} name='email' />
      </Grid>
    ) : (
      <>
        <Grid item>
          <ReactHookFormTextField label={strings.login_code_label} name='code' />
        </Grid>
        <Grid item>
          <ReactHookFormTextField label={strings.login_password_label} name='password' type='password' />
        </Grid>
      </>
    )

  const formSubmitHandler: SubmitHandler<IForgotPasswordForm> = async (data: IForgotPasswordForm) => {
    const { email, password, code } = data
    setLoading(true)
    try {
      if (watch('step') === 1) {
        await rootStore.authStore.forgotPassword(email)
        setValue('step', 2)
      } else {
        await rootStore.authStore.forgotPasswordSubmit(email, code, password)
        await rootStore.authStore.login(email, password)
        history.push('/dashboard')
      }
    } catch (e) {
      console.error(e)
      //@ts-ignore
      if (e.code === 'LimitExceededException') {
        setError('password', { type: 'custom', message: strings.login_error_too_many_attempts })
      } else {
        setError('password', { type: 'custom', message: strings.login_error })
      }
    } finally {
      setLoading(false)
    }
  }

  const disabled = () => {
    if (watch('step') === 1) {
      return !!(watch('email')?.length < 1 && watch('password')?.length < 1)
    } else {
      return !!(watch('code')?.length < 1 && watch('password')?.length < 1)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)} style={{ width: '100%' }}>
        <Grid container justifyContent='center' sx={{ [theme.breakpoints.only('xs')]: { pl: 2, pr: 2 } }}>
          <Grid container flexDirection='column' spacing={2} item sm={7}>
            <Grid item>
              <Typography variant='h3' textAlign='center'>
                {strings.login_reset_password_title}
              </Typography>
              <Typography variant='body2' textAlign='center'>
                {strings.login_reset_password_body}
              </Typography>
            </Grid>
            {renderInner()}
            <Grid item textAlign='center'>
              <LoadingButton variant='contained' loading={loading} type='submit' disabled={disabled()} color='tertiary'>
                {strings.next}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default ForgotPassword
