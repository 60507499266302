import { Dialog, Portal, Stack, Typography } from '@mui/material'
import React, { createContext, MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { State } from '../../reusableUtils/Helpers'

export const isDevelopment = process.env.REACT_APP_STAGE == 'development' || process.env.REACT_APP_STAGE == 'local'
export const MockView = ({ name, children }: { name: string; children?: React.ReactNode }) => {
  const mockStore = useContext(MockStoreContext)
  useEffect(() => {
    mockStore.createContainer(name)
    return () => mockStore.deleteContainer(name)
  }, [])

  return isDevelopment ? <Portal container={mockStore.getContainer(name)}>{children}</Portal> : null
}

const initialValue = {
  createContainer(name: string) {},
  getContainer(name: string): HTMLElement {
    return null as any
  },
  deleteContainer(name: string) {}
}
export const MockStoreContext = createContext(initialValue)

export const useMock = () => {
  const [mockOpen, setMockOpen] = useState(false)
  const [names, setNames] = useState<string[]>([])
  const containers = useRef<Record<string, HTMLDivElement | null>>({})
  useEffect(() => {
    document.addEventListener('keydown', event => {
      if (event.ctrlKey && event.key == 'm') setMockOpen(p => !p)
    })
  }, [])

  return {
    mockOpen,
    setMockOpen,
    names,
    setNames,
    containers,
    value: {
      createContainer: name => {
        containers.current[name] = null
        setNames(p => [...p, name])
      },
      getContainer: name => containers.current[name] ?? null,
      deleteContainer: name => {
        delete containers.current[name]
        setNames(p => {
          const i = p.findIndex(v => v == name)
          if (i != -1) p.splice(i, 1)
          return [...p]
        })
      }
    } as typeof initialValue
  }
}

export const MockDialog = ({ mockOpen, setMockOpen, names, containers }: State<'mockOpen', boolean> & { names: string[]; containers: MutableRefObject<any> }) => (
  <Dialog open={mockOpen} keepMounted onClose={() => setMockOpen(false)}>
    {names.map(name => (
      <Stack key={name} ref={e => (containers.current[name] = e as any)} spacing={1}>
        <Typography variant='h6'>{`${name}:`}</Typography>
      </Stack>
    ))}
  </Dialog>
)
