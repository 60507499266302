const cognito = {
  REGION: 'eu-central-1',
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  TOKEN: process.env.REACT_APP_COGNITO_TOKEN
}

export const adminUsers = ['office@getnano.io', 'hello.getnano@stylink.com', 'ugc@stylink.com']

export default {
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'com.freebeeapp'
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://mnkwl5qmzd.execute-api.eu-central-1.amazonaws.com/dev'
  },
  cognito
}
