function every(
  this: Array<any>,
  a, // expression to test each element of the array against
  b, // optionally change the 'this' context in the given expression
  c, // placeholder iterator variable
  d // placeholder variable (stores context of original array)
) {
  for (
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    c = 0, d = this;
    c < d.length;
    c++ // iterate over all of the array elements
  )
    if (!a.call(b, d[c], c, d))
      // call the given expression, passing in context, value, index, and original array
      return !1 // if any expression evaluates false, immediately return since 'every' is false
  return !0 // otherwise return true since all expressions evaluated to true
}
if (!Array.prototype.every) {
  Object.defineProperty(Array.prototype, 'every', { value: every, writable: true, enumerable: false, configurable: true })
}
// https://stackoverflow.com/questions/68464114/why-am-i-getting-at-is-not-a-function/70557417
function atFunc(this: Array<any>, n: number) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0
  // Allow negative indexing from the end
  if (n < 0) n += this.length
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined
  // Otherwise, this is just normal property access
  return this[n]
}

if (!Array.prototype.at) {
  const TypedArray = Reflect.getPrototypeOf(Int8Array) as Int8ArrayConstructor
  for (const C of [Array, String, TypedArray]) {
    Object.defineProperty(C.prototype, 'at', { value: atFunc, writable: true, enumerable: false, configurable: true })
  }
}
