import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import SidebarDrawer from '../components/common/SidebarDrawer'
import rootStore from '../stores/rootStore'
import { observer } from 'mobx-react'

interface IPrivateRouteProps {
  component: React.ElementType
  path: string
  exact?: boolean
}

const PrivateRoute: React.FC<IPrivateRouteProps> = observer(
  (props: IPrivateRouteProps) => {
    const { component: Component, path, exact } = props
    const [loadingUser, setLoadingUser] = useState(true)

    useEffect(() => {
      if (rootStore.userStore.authenticatedUser) {
        setLoadingUser(false)
      } else {
        getUser(setLoadingUser)
      }
    }, [])

    async function getUser(
      callback: React.Dispatch<React.SetStateAction<boolean>>
    ) {
      try {
        await rootStore.userStore.getUser()
      } catch (e) {
        await rootStore.authStore.logout()
      } finally {
        callback(false)
      }
    }

    if (!rootStore.authStore.isAuthenticated)
      return <Route render={_ => <Redirect to='/login' />} />

    if (loadingUser)
      return (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ minHeight: '100vh' }}>
          <CircularProgress className='loadingFreebee' color='primary' />
        </Grid>
      )

    return (
      <Route
        path={path}
        exact={exact}
        render={props => (
          <SidebarDrawer removepadding={true}>
            <Component {...props} />
          </SidebarDrawer>
        )}
      />
    )
  }
)

export default PrivateRoute
