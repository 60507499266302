import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { analytics } from '../..'
import rootStore from '../../stores/rootStore'
import { AuthPage } from '../auth/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faTiktok, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { useSnackbar } from 'notistack'
import { useStrings } from '../../assets/localization/strings'

interface IOnboardingFeedbackProps {
  pageSwitch: (page: AuthPage) => void
}

const OnboardingFeedback: React.FC<IOnboardingFeedbackProps> = (props: IOnboardingFeedbackProps) => {
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { pageSwitch } = props
  const [loading, setLoading] = useState<boolean>(false)
  const strings = useStrings()

  const onboardingFeedbacks = [
    { label: 'Google', icon: <FontAwesomeIcon icon={faGoogle} /> },
    { label: 'TikTok', icon: <FontAwesomeIcon icon={faTiktok} /> },
    { label: 'Instagram', icon: <FontAwesomeIcon icon={faInstagram} /> },
    { label: 'Facebook', icon: <FontAwesomeIcon icon={faFacebook} /> },
    { label: 'Youtube', icon: <FontAwesomeIcon icon={faYoutube} /> },
    { label: strings.onboardingReferral, icon: <FontAwesomeIcon icon={faComments} /> }
  ]

  const onFeedback = async (onboardingFeedback: string) => {
    setLoading(true)
    const key = enqueueSnackbar('Laden...', { variant: 'info' })
    try {
      analytics.logEvent('SignUp_Weiter')
      await rootStore.authStore.setFeedback(onboardingFeedback)
      await rootStore.brandStore.getBrands(undefined)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
      closeSnackbar(key)
      if (rootStore.brandStore.selectedBrand && rootStore.brandStore.selectedBrand.brandId) {
        history.push(`/${rootStore.brandStore.selectedBrand.brandId}/dashboard`)
      } else {
        pageSwitch(AuthPage.platformMode)
      }
    }
  }

  return (
    <Grid container justifyContent='center' spacing={3}>
      <Grid item xs={12} container justifyContent='center'>
        <Typography variant='h2' color='textPrimary' mb={2}>
          {strings.onboardingTitle}
        </Typography>
      </Grid>
      {onboardingFeedbacks.map(onboardingFeedback => (
        <Grid item xs={5} key={onboardingFeedback.label}>
          <Button
            variant='outlined'
            startIcon={onboardingFeedback.icon}
            fullWidth
            color='tertiary'
            onClick={() => onFeedback(onboardingFeedback.label)}
            disabled={loading}
            id={onboardingFeedback.label}>
            {onboardingFeedback.label}
          </Button>
        </Grid>
      ))}
      <Grid item xs={12} container justifyContent='center'>
        <Button variant='text' color='tertiary' onClick={() => onFeedback('Sonstiges')} disabled={loading} id='Sonstiges'>
          {strings.onboardingOther}
        </Button>
      </Grid>
    </Grid>
  )
}

export default OnboardingFeedback
