/* Used for Videotyp and Videopackage */

import { Button, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { useStrings } from '../../../assets/localization/strings'
import { VideoFeature } from '../../../models/GeneralTypes'
import { numberToEuro, State } from '../../../reusableUtils/Helpers'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color, theme } from '../../../theme'
import InfoChip from './InfoChip'

export interface IRadioCardGroupOption<T extends string = string> {
  content?: string
  showControls: boolean
  title: string
  value: T
  description: string
  price?: number
  isPriceAdditional: boolean // '+' in front of price or not
  discount?: number // 0-1 = 0%-100%
  topBadge?: string
  addButton?: boolean
  thumbnail?: string
  disabled?: boolean
}

interface IReactHookFormRadioCardGroupFullImageProps {
  name: string
  options: IRadioCardGroupOption[]
  videosOnBottom?: boolean
  required?: boolean
  multiple?: boolean
  defaultValue?: string
}

const ReactHookFormRadioCardGroupFullImage = ({ name, options, videosOnBottom = false, multiple, required = false }: IReactHookFormRadioCardGroupFullImageProps) => {
  const { watch, setValue } = useFormContext()
  const current = watch(name)
  const isReadonly = useReadonly(name)
  const strings = useStrings()

  const renderPrice = (o: IRadioCardGroupOption, selected: boolean, inTitle = false) => {
    if (!o.price) return <></>
    const actualPrice = Math.round(o.price * (o.discount ?? 1))

    const position = !inTitle ? { position: 'absolute', bottom: 9, right: 9 } : {}

    return (
      <Grid item xs={12} textAlign='right' sx={position}>
        {o.discount && o.discount < 1 && (
          <Typography sx={{ fontSize: '14px', fontWeight: 400, color: selected ? color.stylinkDarkPurple : 'inherit', textDecoration: 'line-through' }} display='inline' pr={1}>
            {o.isPriceAdditional && '+'}
            {numberToEuro(o.price)}€
          </Typography>
        )}
        <Typography sx={{ fontSize: inTitle ? '16px' : '20px', fontWeight: 600, color: selected ? color.stylinkDarkPurple : 'inherit' }} display='inline'>
          {o.isPriceAdditional && '+'}
          {numberToEuro(actualPrice)}€
        </Typography>
      </Grid>
    )
  }

  const renderTitle = (o: IRadioCardGroupOption) => {
    if (o.description) return <Typography sx={{ fontSize: '17px', fontWeight: 600, color: color.grey2, textAlign: 'left' }}>{o.title}</Typography>

    return (
      <Grid container>
        <Grid item container xs={8} alignItems='center'>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, color: color.grey2, textAlign: 'left' }}>{o.title}</Typography>
        </Grid>
        <Grid item container xs={4} alignItems='center'>
          {renderPrice(o, true, true)}
        </Grid>
      </Grid>
    )
  }

  const onAddButton = (o: IRadioCardGroupOption, selected: boolean) => {
    const isArray = Array.isArray(current)
    if (isArray && multiple) {
      const removed = current.filter(it => it != o.value)
      setValue(name, selected ? removed : removed.concat(o.value))
    } else if (isArray) {
      if (!required && selected) {
        setValue(name, [])
      } else {
        setValue(name, [o.value])
      }
    } else {
      setValue(name, o.value)
    }
  }

  const renderButton = (o: IRadioCardGroupOption, selected: boolean) => (
    <Button variant={selected ? 'contained' : 'outlined'} type='button' fullWidth sx={{ marginTop: '8px' }} onClick={() => onAddButton(o, selected)} disabled={isReadonly}>
      {selected ? strings.deselect : strings.select}
    </Button>
  )

  const renderVideo = (o: IRadioCardGroupOption) => (
    <Grid item xs={12}>
      <div className='videoPlayerWrapper'>
        <ReactPlayer url={o.content} width='100%' height='auto' controls={o.showControls} />
      </div>
    </Grid>
  )

  return (
    <RadioGroup>
      <Grid container spacing={2}>
        {options.map(o => {
          const selected = Array.isArray(current) ? current.includes(o.value) : current == o.value
          return (
            <Grid item key={o.value} xs={12} sm={6} md={4} sx={{ [theme.breakpoints.only('xs')]: { mt: 2 } }}>
              <Button
                sx={{
                  border: '1.5px solid',
                  borderColor: selected ? color.stylinkDarkPurple : color.grey3,
                  borderRadius: '10px',
                  textTransform: 'none',
                  alignItems: 'flex-start',
                  position: 'relative',
                  padding: 0,
                  background: selected ? color.backgroundPurple : 'inherit',
                  height: o.addButton ? '90%' : '100%',
                  width: '100%'
                }}
                value={o.value}
                disabled={isReadonly}
                onClick={() => onAddButton(o, selected)}>
                <Radio checked={selected} sx={{ position: 'absolute', right: '0px', top: '0px' }} value={o.value} />
                {o.topBadge && <InfoChip label={o.topBadge} />}
                <Grid container>
                  {!videosOnBottom && renderVideo(o)}
                  <Grid item container xs={12} sx={{ padding: '20px 20px 20px 20px', paddingBottom: o.description ? '50px' : '20px' }}>
                    <Grid item container xs={12}>
                      {renderTitle(o)}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: '14px', fontWeight: 400, color: color.grey2, textAlign: 'left', whiteSpace: 'pre-line' }}>{o.description}</Typography>
                    </Grid>
                    {!!o.description && renderPrice(o, selected)}
                  </Grid>
                </Grid>
              </Button>
              {o.addButton && renderButton(o, selected)}
            </Grid>
          )
        })}
      </Grid>
    </RadioGroup>
  )
}

export const RadioCardGroupFullImage = ({
  videoFeature,
  setVideoFeature,
  options,
  multiple = false
}: State<'videoFeature', VideoFeature[]> & { options: IRadioCardGroupOption<VideoFeature>[]; multiple: boolean }) => {
  const strings = useStrings()
  type Option = typeof options[number]
  const renderPrice = (o: Option, selected: boolean) => {
    if (!o.price) return <></>
    const actualPrice = Math.round(o.price * (o.discount ?? 1))

    return (
      <Grid item xs={12} textAlign='right' sx={{ position: 'absolute', bottom: 9, right: 9 }}>
        {o.discount != null && o.discount < 1 && (
          <Typography sx={{ fontSize: '14px', fontWeight: 400, color: selected ? color.stylinkDarkPurple : 'inherit', textDecoration: 'line-through' }} display='inline' pr={1}>
            {o.isPriceAdditional && '+'}
            {numberToEuro(o.price)}€
          </Typography>
        )}
        <Typography sx={{ fontSize: '20px', fontWeight: 600, color: selected ? color.stylinkDarkPurple : 'inherit' }} display='inline'>
          {o.isPriceAdditional && '+'}
          {numberToEuro(actualPrice)}€
        </Typography>
      </Grid>
    )
  }

  const renderButton = (o: Option, selected: boolean) => (
    <Button disabled={o.disabled} variant={selected ? 'contained' : 'outlined'} type='button' fullWidth sx={{ marginTop: '8px' }} onClick={() => onAddButton(o)}>
      {selected ? strings.deselect : strings.select}
    </Button>
  )

  const onAddButton = (o: Option) =>
    setVideoFeature(p => {
      if (!multiple) return p.includes(o.value) ? [] : [o.value]
      return p.filter(f => f != o.value).concat(p.includes(o.value) ? [] : o.value)
    })

  return (
    <Grid container spacing={2} justifyContent='center'>
      {options.map(o => {
        const selected = videoFeature.includes(o.value as any)
        return (
          <Grid item key={o.value} xs={12} sm={6} md={4}>
            <Button
              disabled={o.disabled}
              sx={{
                border: '1.5px solid',
                borderColor: selected ? color.stylinkDarkPurple : color.grey3,
                borderRadius: '10px',
                textTransform: 'none',
                height: o.addButton ? '90%' : '100%',
                alignItems: 'flex-start',
                position: 'relative',
                p: 0,
                background: selected ? color.backgroundPurple : 'inherit'
              }}
              value={o.value}
              onClick={() => onAddButton(o)}>
              <Radio checked={selected} sx={{ position: 'absolute', right: '0px', top: '0px' }} value={o.value} />
              {o.topBadge && <InfoChip label={o.topBadge} />}
              <Grid container>
                <Grid item xs={12}>
                  <div className='videoPlayerWrapper'>
                    <ReactPlayer url={o.content} width='100%' height='auto' controls={o.showControls} />
                  </div>
                </Grid>
                <Grid item container xs={12} sx={{ padding: '20px 20px 50px 20px' }}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600, color: color.grey2, textAlign: 'left' }}>{o.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 400, color: color.grey2, textAlign: 'left', whiteSpace: 'pre-line' }}>{o.description}</Typography>
                  </Grid>
                  {renderPrice(o, selected)}
                </Grid>
              </Grid>
            </Button>
            {o.addButton && renderButton(o, selected)}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ReactHookFormRadioCardGroupFullImage
