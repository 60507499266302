import CloseIcon from '@mui/icons-material/Close'
import { Alert } from '@mui/lab'
import { Dialog, Grid, IconButton, Snackbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useRef, useState } from 'react'
import AutoSizeInput from 'react-input-autosize'
import styles from '../../style/CampaignDashboard.module.css'
import '../../style/campaignSidebar.css'

import { observer } from 'mobx-react'
import rootStore from '../../stores/rootStore'
import LoaderButton from './layout/LoaderButton'

const onlyNumberRegx = RegExp(/^(\s*|\d+)$/)
interface Props {
  modalOpen: boolean
  setModalOpen(state: boolean): void
}

const useStyles = makeStyles({
  contentContainer: {
    maxWidth: '480px',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  sliderContainer: {
    marginTop: '50px',
    marginBottom: '20px'
  },
  inputFieldUnFocused: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '8px',
    borderColor: 'black',
    padding: '8px'
  },
  inputFieldFocused: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius: '8px',
    borderColor: '#a890fe',
    padding: '8px'
  },
  h4Xsmall: {
    margin: 0,
    padding: 0,
    fontSize: 'x-small !important',
    display: 'inline !important'
  },
  h4Small: {
    margin: 0,
    padding: 0,
    fontSize: 'small !important',
    display: 'inline !important'
  },
  h4Big: {
    margin: 0,
    padding: 0,
    fontSize: 'large !important'
  },
  line: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#F2F2F2',
    width: '400px',
    marginBottom: '15px'
  },
  summaryContainer: {
    marginTop: '25px',
    padding: '15px'
  },
  buttonContainer: {
    marginTop: '50px'
  },
  paragraph: {
    textAlign: 'center'
  },
  addition: {
    paddingBottom: '20px'
  }
})

const CreditModal = observer((props: Props) => {
  const { modalOpen, setModalOpen } = props
  const [credit, setCredit] = useState(500)
  const [focused, setFocused] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)
  const classes = useStyles()
  const inputRef = useRef<any>(null)

  const handleClose = () => {
    setCredit(0)
    setModalOpen(false)
  }

  const closeAlert = () => {
    setUploadFailed(false)
    setUploadSuccess(false)
  }

  const handleCardSubmit = async () => {}

  const handleChange = (e: any) => {}

  const creditSelection = () => (
    <Grid container>
      <Grid item xs={12}>
        <div className={focused ? classes.inputFieldFocused : classes.inputFieldUnFocused}>
          <Grid container>
            <Grid
              item
              xs={6}
              onClick={() => {
                if (inputRef !== null && inputRef.current !== null) inputRef.current.focus()
              }}>
              <h4 className={classes.h4Small}>Betrag eingeben</h4>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent='flex-end'>
                <h4 className={classes.h4Small}>
                  €
                  <AutoSizeInput
                    type='number'
                    autoFocus
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    inputStyle={{
                      border: 'none',
                      outline: 'none',
                      maxWidth: '180px'
                    }}
                    value={credit === 0 ? undefined : credit}
                    onChange={handleChange}
                    ref={inputRef}
                  />
                </h4>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )

  const summary = () => (
    <Grid container className={classes.summaryContainer}>
      <Grid item xs={12}>
        <Grid container className={classes.addition}>
          <Grid item xs={11}>
            <h4 className={classes.h4Small}>Du bezahlst</h4>
          </Grid>
          <Grid item xs={1}>
            <Grid container justifyContent='flex-end'>
              <h4 className={classes.h4Small}>€{credit || 0}</h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.addition}>
          <Grid item xs={11}>
            <h4 className={classes.h4Small}>Bonus</h4>
            <h4 className={classes.h4Xsmall}> +10% Extra</h4>
          </Grid>
          <Grid item xs={1}>
            <Grid container justifyContent='flex-end'>
              <h4 className={classes.h4Small}>€{Math.round(credit * 0.1)}</h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.line} />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={11}>
            <h4 className={classes.h4Big}>Du erhältst:</h4>
          </Grid>
          <Grid item xs={1}>
            <Grid container justifyContent='flex-end'>
              <h4 className={classes.h4Big}>€{credit ? Math.round(credit * 1.1) : 0}</h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Dialog onClose={handleClose} open={modalOpen}>
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container className={classes.contentContainer}>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <h1 className={styles.creditDialogText}>Guthaben aufladen</h1>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {creditSelection()}
            </Grid>
            <Grid item xs={12} onClick={() => setFocused(false)}>
              {summary()}
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='center' className={classes.buttonContainer}>
                <LoaderButton text='kostenpflichtig aufladen' isLoading={buttonLoading} disabled={!(credit > 0)} onClick={handleCardSubmit} className={styles.modalSubmitButton} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <Grid item xs={8}>
                  <p className={classes.paragraph}>Das Guthaben wird vom angegebenen Zahlungsmittel abgebucht.</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      <Snackbar open={uploadSuccess || uploadFailed} autoHideDuration={4000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity={uploadSuccess ? 'success' : 'error'}>
          {uploadSuccess ? 'Guthaben wurde erfolgreich hochgeladen!' : 'Es ist ein Fehler aufgetreten!'}
        </Alert>
      </Snackbar>
    </>
  )
})

export default CreditModal
