/* https://stackoverflow.com/questions/63463502/pass-component-props-in-private-route-with-typescript-and-react */
import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { CircularProgress, Grid } from '@mui/material'
import rootStore from '../stores/rootStore'
import { observer } from 'mobx-react'

interface IPublicRouteProps {
  path: string
  component: React.ElementType
  exact?: boolean
}

const PublicRoute: React.FC<IPublicRouteProps> = observer(
  (props: IPublicRouteProps) => {
    const { path, component: Component, exact } = props
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      if (
        !rootStore.authStore.isAuthenticated ||
        (rootStore.brandStore.selectedBrand &&
          rootStore.brandStore.selectedBrand.brandId)
      ) {
        setLoading(false)
      } else {
        getBrands(setLoading)
      }
    }, [])

    async function getBrands(
      callback: React.Dispatch<React.SetStateAction<boolean>>
    ) {
      await rootStore.brandStore.getBrands(undefined)
      callback(false)
    }

    if (loading)
      return (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ minHeight: '100vh' }}>
          <CircularProgress className='loadingFreebee' color='primary' />
        </Grid>
      )

    if (!rootStore.authStore.isAuthenticated)
      return (
        <Route
          path={path}
          exact={exact}
          render={props => <Component {...props} />}
        />
      )

    if (rootStore.brandStore.selectedBrand)
      return (
        <Route
          path={path}
          render={props => (
            <Redirect
              to={`${rootStore.brandStore.selectedBrand?.brandId}/dashboard`}
            />
          )}
        />
      )

    return (
      <Route path={path} render={props => <Redirect to='/createBrand' />} />
    )
  }
)

PublicRoute.defaultProps = {
  exact: false
}

export default PublicRoute
