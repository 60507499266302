import { Button, CircularProgress, Grid } from '@mui/material'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { analytics } from '../..'
import { useStrings } from '../../assets/localization/strings'
import { ShippingService, StatusInfo } from '../../models/GeneralTypes'
import { Applicant, CampaignInsightsUgc, ParticipantUgc, UgcContent, UgcContentFinished } from '../../requestModels'
import rootStore from '../../stores/rootStore'
import styles from '../../style/contentCampaign/content-CampaignDashboard.module.css'
import { MockView } from '../common/MockView'
import ErrorReload from '../error/ErrorReload'
import NotAuth from '../error/NotAuth'
import { BookMoreDialog } from './BookMoreDialog'
import { Coupon, CouponDialog } from './CouponDialog'
import DetailsNavigation from './DetailsNavigation'
import Applications from './applications/Applications'
import CheckContent from './check/CheckContent'
import Content from './content/Content'
import Creators from './creators/Creators'

export enum OverviewStep {
  APPLICATIONS = 'applications',
  CREATORS = 'creators',
  CHECK = 'check',
  CONTENT = 'content'
}

export const ContentCampaignOverviewContext = createContext({
  accepted: undefined as unknown as number,
  campaign: undefined as unknown as CampaignInsightsUgc,
  setCampaign: undefined as unknown as (_: CampaignInsightsUgc) => void,
  setContent: undefined as unknown as Dispatch<SetStateAction<UgcContentFinished[] | undefined>>,
  setParticipants: undefined as unknown as Dispatch<SetStateAction<ParticipantUgc[] | undefined>>,
  acceptInfluencer: undefined as unknown as (influencerId: string) => Promise<void>,
  reload: undefined as unknown as () => Promise<void>
})

const ContentCampaignOverview = observer(() => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>(undefined)
  const [step, setStep] = useState<OverviewStep>()
  const [campaign, setCampaign] = useState<CampaignInsightsUgc>()
  const [applicants, setApplicants] = useState<Applicant[]>()
  const [participants, setParticipants] = useState<ParticipantUgc[]>()
  const [contentForCheck, setContentForCheck] = useState<UgcContent[]>()
  const [content, setContent] = useState<UgcContentFinished[]>()
  const [coupon, setCoupon] = useState<Coupon>()
  const [showCoupon, setShowCoupon] = useState(false)
  const [showBookMore, setShowBookMore] = useState(false)
  const strings = useStrings()

  const { brandId, campaignId, step: paramStep } = useParams<{ campaignId: string; brandId: string; step: OverviewStep }>()

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const load = async () => {
    rootStore.campaignStore.selectedCampaign = undefined
    const campaign = await rootStore.campaignStore.getUgcCampaign(campaignId, brandId)
    if (!campaign) {
      history.push(`/${brandId}/${campaignId}/fromOverview`)
      return
    }
    setCampaign(campaign)
    setApplicants(campaign.applicants)
    setParticipants(campaign.active)
    setContentForCheck(campaign.contentCheck)
    setContent(campaign.finished)

    const shouldDeterminStep = !Object.values(OverviewStep).includes(paramStep)
    if (shouldDeterminStep)
      if (campaign.hasToCheck) {
        setStep(OverviewStep.CHECK)
      } else if (campaign.isAllContent) {
        setStep(OverviewStep.CONTENT)
      } else if (campaign.applied == 0 && campaign.hasCreators) {
        setStep(OverviewStep.CREATORS)
      } else {
        setStep(OverviewStep.APPLICATIONS)
      }
    else {
      setStep(paramStep)
    }

    rootStore.campaignStore.selectedCampaign = campaign
  }
  useEffect(() => {
    const onMount = async () => {
      try {
        setLoading(true)
        await load()
      } catch (e) {
        console.error(e)
        setError(e)
      } finally {
        setLoading(false)
      }
    }
    onMount()
  }, [])

  useEffect(() => {
    if (!step) return // needed
    history.replace(`/content/${brandId}/${campaignId}/${step}`)
  }, [step])

  const acceptInfluencer = async (influencerId: string) => {
    if (!campaign) return

    try {
      if (campaign.accepted >= campaign.numberOfSlots) {
        enqueueSnackbar(strings.campaign_overview_no_more_slots, { variant: 'error' })
        return
      }

      await rootStore.shopStore.acceptInfluencer(campaignId, influencerId, brandId)
      campaign.accepted++
      if (!campaign.fullSlotsCoupon) {
        const coupon = await rootStore.shopStore.getCouponAfterAcceptingLastInfluencer({ brandId, campaignId })
        // check if there is still another applicant
        if ((applicants?.length || 0) > 1) {
          if (coupon?.code == 'default') {
            setShowBookMore(true)
          } else if (coupon?.code) {
            setCoupon(coupon)
            setShowCoupon(!!coupon)
            campaign.fullSlotsCoupon = coupon?.code
          }
        }
      }

      setApplicants(applicants => applicants?.filter(el => el.influencerId !== influencerId))
      load()
      setCampaign({ ...campaign })
    } catch (e) {
      console.error(e)

      if (e.message.includes('There are no discount codes left.')) {
        alert(strings.campaign_overview_no_more_codes)
      }

      // TODO check if more numberOfVideos
    }
  }

  const denyInfluencer = async (influencerId: string, denyText: string) => {
    try {
      await rootStore.shopStore.denyUgcInfluencer(campaignId, influencerId, brandId, denyText)

      setApplicants(applicants => applicants?.filter(i => i.influencerId !== influencerId))
    } catch (e) {
      console.error(e)
      setError(e)
    }
  }

  const onSent = async (influencerId: string, shippingCode: string, shippingService: ShippingService | string) => {
    try {
      await rootStore.statusStore.onSentWithShipping(campaignId, influencerId, brandId, shippingCode, shippingService)
      await load()
    } catch (e) {
      enqueueSnackbar(strings.campaign_overview_onsent_error, { variant: 'error' })
    }
  }

  const removeInfluencer = (influencer: ParticipantUgc) => {
    if (participants) {
      if (influencer.statusInfo === 'canceled') {
        const array = [...participants]
        const index = array.indexOf(influencer)

        if (index !== -1) {
          array.splice(index, 1)
          setParticipants(array)
          setParticipants(array)
        }
      } else if (influencer.statusInfo === 'accepted') {
        const index = participants.indexOf(influencer)
        if (index !== -1) {
          participants[index].statusInfo = StatusInfo.Canceled
          setParticipants([...participants])
        }
      }
    }
  }

  if (loading && !campaign)
    return (
      <Grid container height='100%' alignItems='center' justifyContent='center'>
        <CircularProgress className='loadingFreebee' color='primary' />
      </Grid>
    )

  if (!campaign || error) return <ErrorReload />

  const filterContentForCheck = (influencerId: string) => setContentForCheck(c => (c ? [...c.filter(el => el.influencerId !== influencerId)] : []))

  if (!campaign || error) return <ErrorReload />

  const acceptContent = async (influencerId: string, todoId: string) => {
    try {
      const { campaignId } = campaign

      await rootStore.campaignStore.manageInfluencerContent(campaignId, influencerId, todoId, true)

      analytics.logEvent('accept_influencer')
      filterContentForCheck(influencerId)
      await load()
    } catch (e) {
      enqueueSnackbar(strings.campaign_overview_accept_influencer_error, { variant: 'error' })
      console.error(e)
    }
  }

  const renderStep = () => {
    if (step === OverviewStep.APPLICATIONS && applicants) {
      return <Applications influencers={applicants} campaign={campaign} denyInfluencer={denyInfluencer} />
    }
    if (step === OverviewStep.CREATORS && participants) {
      return (
        <Creators
          participants={participants}
          setParticipants={setParticipants as Dispatch<SetStateAction<ParticipantUgc[]>>}
          campaign={campaign}
          setCampaign={setCampaign as Dispatch<SetStateAction<CampaignInsightsUgc>>}
          onSent={onSent}
          //sortParticipantsTable={sortParticipantsTable}
          removeInfluencer={removeInfluencer}
          // TODO: do not pass as promise
          setStep={setStep}
        />
      )
    }
    if (step === OverviewStep.CHECK && campaign && contentForCheck) {
      return (
        <CheckContent
          urlParams={{ brandId, campaignId, step: paramStep }}
          campaignInsights={campaign}
          contentForCheck={contentForCheck}
          setContentForCheck={v => setContentForCheck(v as any)}
          acceptContent={acceptContent}
          filterContentForCheck={filterContentForCheck}
        />
      )
    }
    if (step === OverviewStep.CONTENT && content) {
      return <Content content={content} campaign={campaign} />
    }
    return ''
  }

  if (error?.['status'] == 401)
    return (
      <Grid className={styles.campaignDashboardConainter}>
        <Grid className={styles.dashboard}>
          <Grid className={styles.dashboardInner}>
            <NotAuth />
          </Grid>
        </Grid>
      </Grid>
    )

  console.log(campaign)

  return (
    <ContentCampaignOverviewContext.Provider value={{ campaign, setCampaign, setContent, setParticipants, accepted: campaign.accepted, acceptInfluencer, reload: load }}>
      <MockView name='ContentCampaignOverview'>
        <Button onClick={() => setShowBookMore(true)}>Open BookMoreDialog</Button>
        <Button onClick={() => setCampaign(p => p && { ...p, applied: 1 })}>Set applied = 1</Button>
        <Button onClick={() => setCampaign(p => p && { ...p, applied: 0 })}>Set applied = 0</Button>
      </MockView>
      {coupon && <CouponDialog open={showCoupon} setOpen={setShowCoupon} coupon={coupon} />}
      {showBookMore && <BookMoreDialog open={showBookMore} setOpen={setShowBookMore} />}
      <Grid className={styles.campaignDashboardConainter}>
        <Grid className={styles.dashboard}>
          <Grid className={styles.dashboardInner}>
            <DetailsNavigation accepted={campaign?.accepted} content={content} setStep={setStep} step={step} />
            <Grid className={styles.detailsContainer} container>
              {loading ? (
                <Grid container height='100%' alignItems='center' justifyContent='center'>
                  <CircularProgress className='loadingFreebee' color='primary' />
                </Grid>
              ) : (
                renderStep()
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <TimelineCard applicants={[]} participants={[]} content={[]} contentForCheck={[]} /> */}
    </ContentCampaignOverviewContext.Provider>
  )
})

export default ContentCampaignOverview
