import { CircularProgress, Fab, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { analytics } from '../..'
import type { Role as RoleBackend } from '../../../../getnano-backend/utils/Types'
import deleteIcon from '../../assets/icons/ic_close.svg'
import { useStrings } from '../../assets/localization/strings'
import { Role } from '../../models/GeneralTypes'
import rootStore from '../../stores/rootStore'
import styling from '../../style/campaign-details.module.css'
import styles from '../../style/CampaignDashboard.module.css'
import NotAuth from '../error/NotAuth'

type BrandUser = Awaited<ReturnType<typeof rootStore.brandStore.getAllUsers>>[number] & { editing: boolean }

const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)

const UserSettings = observer(() => {
  const [loading, setLoading] = useState(true)
  const [isRemoving, setIsRemoving] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [newUser, setNewUser] = useState('')
  const [role, setRole] = useState<RoleBackend>(Role.Marketer)
  const [newUserError, setNewUserError] = useState<string>()
  const [editError, setEditError] = useState<string>()
  const [editorError, setEditorError] = useState<any>()
  const [users, setUsers] = useState<BrandUser[]>([])
  const strings = useStrings()
  const props = { name: 'users' }

  useEffect(() => {
    const onMount = async () => {
      try {
        setLoading(true)
        const usersTemp = (await rootStore.brandStore.getAllUsers()).map(u => ({ ...u, editing: false }))
        setUsers(usersTemp)
      } catch (e) {
        setEditorError(e)
      } finally {
        setLoading(false)
      }
    }
    onMount()
  }, [])

  const removeUser = async (email: string) => {
    if (isRemoving) return

    try {
      setIsRemoving(true)
      await rootStore.brandStore.removeUser(email)

      setUsers(users => users.filter(user => user.email !== email))
    } catch (e) {
      setEditorError(e)
    } finally {
      setIsRemoving(false)
    }
  }

  const onNewUser = () => setIsAdding(true)

  const onAddPerson = async () => {
    if (!newUser) return

    if (!emailRegex.test(newUser)) {
      setNewUserError(strings.brand_users_insert_invalid_email)
      return
    }

    await rootStore.brandStore.addUserToBrand(newUser, role)

    users.push({ email: newUser, role, editing: false })

    setUsers(users)
    setNewUser('')
    setIsAdding(false)
  }

  const onSavePerson = async (user: BrandUser) => {
    if (!users) return

    try {
      await rootStore.brandStore.updateBrandOfUser(user.email, user.role)
    } catch (e) {
      setEditError(strings.brand_users_error)
    }

    setUsers(users => users.map(u => (u.email === user.email ? { ...u, editing: false } : u)))
  }

  const handleChange = (event: ChangeEvent<any>) => {
    event.preventDefault()
    const { value } = event.currentTarget

    setNewUser(value)
    setNewUserError(undefined)
  }

  const handleChangeRole = async (event: ChangeEvent<any>) => {
    const { value } = event.target
    setRole(value)
  }

  const handleEdit = (event: ChangeEvent<any>, user: BrandUser) => {
    const { value } = event.target

    setRole(value)
    setUsers(users => users.map(u => (u.email === user.email ? { ...u, role: value, editing: true } : u)))
  }

  const renderOptions = (role: string | undefined, onChange: (_: React.ChangeEvent<HTMLSelectElement>) => void) => (
    <select value={role} onChange={onChange}>
      <option value='Marketer'>{strings.user_role_marketer}</option>
      <option value='Admin'>{strings.user_role_admin}</option>
      <option value='Accountant'>{strings.user_role_accountant}</option>
      <option value='Analyst'>{strings.user_role_analyst}</option>
    </select>
  )

  const renderTable = () => {
    if (!users) return <div>{strings.error}</div>

    return (
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={styles.statusColumn}>
                <b>{strings.brand_users_table_email}</b>
              </TableCell>
              <TableCell align='left'>
                <b>{strings.brand_users_table_role}</b>
              </TableCell>
              <TableCell align='right' />
              <TableCell align='right' />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: BrandUser) => (
              <TableRow key={user.email}>
                <TableCell colSpan={1} component='td' align='left'>
                  {user.email}
                </TableCell>
                <TableCell colSpan={1} component='td' align='left'>
                  {rootStore.brandStore.selectedBrand && rootStore.brandStore.selectedBrand.role === 'Admin' ? renderOptions(user.role, e => handleEdit(e, user)) : user.role}
                </TableCell>
                {rootStore.brandStore.selectedBrand?.role === 'Admin' ? (
                  <TableCell colSpan={2} align='right'>
                    {user.editing ? (
                      <button
                        className='link'
                        onClick={() => {
                          onSavePerson(user)
                          analytics.logEvent('UserSettings_SavePerson')
                        }}>
                        {strings.save}
                      </button>
                    ) : (
                      <img src={deleteIcon} style={{ cursor: 'pointer' }} onClick={() => removeUser(user.email)} alt='deleteicon' />
                    )}
                  </TableCell>
                ) : (
                  <>
                    <TableCell />
                    <TableCell />
                  </>
                )}
              </TableRow>
            ))}
            <TableRow style={!isAdding ? { display: 'none' } : {}}>
              <TableCell colSpan={1} component='td' align='left'>
                <TextField
                  label={strings.brand_users_email_title}
                  name='newUser'
                  value={newUser}
                  onChange={handleChange}
                  variant='standard'
                  fullWidth
                  error={newUserError !== undefined}
                  helperText={newUserError}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onAddPerson()
                    }
                  }}
                />
              </TableCell>
              <TableCell colSpan={1} component='td' align='left'>
                {renderOptions(undefined, handleChangeRole)}
              </TableCell>
              <TableCell colSpan={2} align='right'>
                <button
                  className='link'
                  onClick={() => {
                    onAddPerson()
                    analytics.logEvent('UserSettings_Einladen')
                  }}>
                  {strings.brand_users_invite}
                </button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderRules = () => (
    <Grid>
      <div dangerouslySetInnerHTML={{ __html: strings.brand_users_info }} />
    </Grid>
  )

  if (editorError?.status === 401)
    return (
      <Grid className={styles.campaignDashboardConainter}>
        <Grid className={styles.dashboard}>
          <Grid item xs={12}>
            <h1>{strings.brand_users_campaign_title}</h1>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <h2>{strings.brand_users_campaign_subtitle}</h2>
            </Grid>
            <Grid className={styling.detailsContainer} container>
              <NotAuth />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )

  if (loading) {
    return (
      <div className='loadingFreebee'>
        <CircularProgress className='loadingFreebee' color='primary' />
      </div>
    )
  }

  return (
    <Grid className={styles.dashboard}>
      <Grid className={styling.detailsContainer} container>
        {rootStore.brandStore.selectedBrand && rootStore.brandStore.selectedBrand.role === 'Admin' ? (
          <>
            {renderRules()}
            {renderTable()}
            <Grid item>{editError}</Grid>
            <Grid item xs={12} style={editError ? { paddingTop: 'none' } : { paddingTop: '24px' }} hidden={isAdding} onClick={onNewUser}>
              <button className='link' onClick={() => analytics.logEvent('UserSettings_BearbeiterInnen-hinzufügen')}>
                {strings.brand_users_insert}
              </button>
              <Fab aria-label='add' className='createCampaignFab'>
                +
              </Fab>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <b>{strings.brand_users_insert_admin_only}</b>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
})
export default UserSettings
