import ErrorIcon from '@mui/icons-material/Error'
import { Box, Button, CircularProgress, Dialog, IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import DownloadContentIcon from '../../../assets/icons/ic_download_content.svg'
import ThreeStarsIcon from '../../../assets/icons/ic_upgrade_package.svg'
import { useStrings } from '../../../assets/localization/strings'
import { VideoFeature } from '../../../models/GeneralTypes'
import { UgcContentFinished } from '../../../requestModels'
import { check } from '../../../reusableUtils/Helpers'
import { usePricing } from '../../../reusableUtils/pricing'
import rootStore from '../../../stores/rootStore'
import PaymentModal from '../../common/PaymentModal'
import { Player, PlayerBottomText } from '../../common/Player'
import { RadioCardGroupFullImage } from '../../common/form/ReactHookFormRadioCardGroupFullImage'
import { getVideoFeatureOptions } from '../../createUgcCampaign/CampaignAdditionalFeatures'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'

export const getEditedLinks = (influencer: UgcContentFinished, createdAt: number = Date.now()) => {
  const [rawLink] = influencer.editedLinks.map(ed => ed.rawLink).unique()
  const edits = influencer.editedLinks.filter(ed => ed.rawLink == rawLink)
  const edit =
    createdAt < 1681837435939
      ? edits.ascendingSorted(ed => ed.videoFeatures.length).at(-1)
      : edits.ascendingSorted(ed => (ed.videoFeatures.includes(VideoFeature.large) ? 2 : ed.videoFeatures.includes(VideoFeature.medium) ? 1 : 0)).at(-1)
  check(edit, 'Must have at least 1 content')
  const linkToDisplay = edit.editedLink || rawLink
  return {
    rawLink,
    content: linkToDisplay,
    currentEdit: edit,
    canUpgradeTo: !influencer.editedLinks.flatMap(ed => ed.videoFeatures).includes(VideoFeature.large), // if customer didn't buy all/large upgrade
    linksToDownload: [linkToDisplay, rawLink].unique(),
    beingEdited: !!edits.find(ed => !ed.editedLink)
  }
}

export const upgradeVideoButtonClass = 'upgrade-button'
type Props = { pricingObj: ReturnType<typeof usePricing>; influencer: UgcContentFinished }
const ContentView = observer(({ pricingObj: { pricing, getPricing }, influencer }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const { campaign, reload } = useContext(ContentCampaignOverviewContext)
  const { content, currentEdit, linksToDownload, canUpgradeTo, beingEdited } = getEditedLinks(influencer, campaign.createdAt)
  const strings = useStrings()
  const [videoFeatures, setVideoFeatures] = useState(currentEdit.videoFeatures)
  const { enqueueSnackbar } = useSnackbar()

  const upgrade = async (coupon: string) => {
    await rootStore.campaignStore.upgradeVideo({
      brandId: campaign.brandId,
      campaignId: campaign.campaignId,
      influencerId: influencer.influencerId,
      todoId: influencer.todoId,
      count: influencer.count,
      videoFeatures,
      coupon
    })
    setShowPayment(false)
    reload()
    enqueueSnackbar(strings.campaign_overview_content_upgraded_message, { variant: 'success' })
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const renderVideoFeatures = () => {
    if (pricing == null) return <CircularProgress />
    if (pricing instanceof Error)
      return (
        <IconButton onClick={getPricing}>
          <ErrorIcon />
        </IconButton>
      )
    return (
      <RadioCardGroupFullImage
        videoFeature={videoFeatures}
        setVideoFeature={setVideoFeatures}
        options={getVideoFeatureOptions(strings, pricing, currentEdit.videoFeatures, campaign.createdAt).filterNotNull()}
        multiple={campaign.createdAt < 1681837435939 ? true : false}
      />
    )
  }

  const newFeatures = videoFeatures.filter(f => !currentEdit.videoFeatures.includes(f))
  const renderUpgrade = () => (
    <Box
      component='button'
      sx={{ p: '0px 6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: '20px' }}
      onClick={() => {
        setVideoFeatures(currentEdit.videoFeatures) // reset video features
        setShowUpgrade(true)
      }}>
      <Stack direction='row' alignItems='center' className={upgradeVideoButtonClass}>
        <img src={ThreeStarsIcon} style={{ width: '12px', marginRight: '6px' }} />
        <Typography variant='caption' fontWeight='600' color='white'>
          {strings.campaign_overview_content_upgrade_label}
        </Typography>
      </Stack>
    </Box>
  )
  const renderBeingEdited = () =>
    beingEdited && (
      <Box sx={{ ml: '7px', mt: '7px', mr: 'auto', p: '6px', backgroundColor: theme => theme.palette.primary.main, borderRadius: 1 }}>
        <Typography variant='caption' fontWeight='800' color='white'>
          {strings.campaign_overview_content_being_edited}
        </Typography>
      </Box>
    )
  const renderDownload = () => (
    <Box>
      {linksToDownload.length > 1 && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
          <MenuItem>
            <Link href={linksToDownload[0]} target='_blank' underline='none' color='black'>
              {strings.campaign_overview_content_download}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href={linksToDownload[1]} target='_blank' underline='none' color='black'>
              {strings.campaign_overview_content_raw_download}
            </Link>
          </MenuItem>
        </Menu>
      )}
      <Box component='a' {...(linksToDownload.length > 1 ? { onClick: handleClick } : { href: content, target: '_blank' })}>
        <Box component='img' src={DownloadContentIcon} alt='bezahlt' width='15px' height='20px' color={theme => theme.palette.primary.main} />
      </Box>
    </Box>
  )
  return (
    <Box height='100%' p={1}>
      <PaymentModal open={showPayment} handleClose={() => setShowPayment(false)} callback={upgrade} videoFeatures={newFeatures} numberOfVideos={1} />
      <Dialog open={showUpgrade} onClose={() => setShowUpgrade(false)} fullWidth maxWidth='md'>
        <Box p={6}>
          {renderVideoFeatures()}
          {videoFeatures.some(f => !currentEdit.videoFeatures.includes(f)) && (
            <Button
              fullWidth
              variant='contained'
              sx={{ ml: 'auto', mt: '24px' }}
              onClick={() => {
                setShowUpgrade(false)
                setShowPayment(true)
              }}>
              {strings.next}
            </Button>
          )}
        </Box>
      </Dialog>
      <Player src={content}>
        <>
          <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: 1, mr: 2, ml: 1 }}>
            {canUpgradeTo ? renderUpgrade() : renderBeingEdited()}
            {renderDownload()}
          </Stack>
          <PlayerBottomText icon={influencer.imageUrl} name={influencer.givenname} />
        </>
      </Player>
    </Box>
  )
})

export default ContentView
