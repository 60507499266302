import React, { FC } from 'react'
import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'

interface IReactHookFormCheckboxProps {
  label: React.ReactNode
  name: string
}

const ReactHookFormCheckbox: FC<IReactHookFormCheckboxProps> = ({ label, name }: IReactHookFormCheckboxProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <FormControl required error={!!errors[name]} component='fieldset' variant='standard'>
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={props => <Checkbox name={name} {...props} checked={props.field.value} onChange={e => props.field.onChange(e.target.checked)} />}
          />
        }
        label={label}
      />
      {errors[name]?.message && <FormHelperText>{errors[name]?.message ?? ''}</FormHelperText>}
    </FormControl>
  )
}

export default ReactHookFormCheckbox
