import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import { color } from '../../../theme'

const InfoChip = styled(Chip)<ChipProps>(({ theme }) => ({
  position: 'absolute',
  top: '-15px',
  background: color.stylinkPurple,
  borderRadius: '5px',
  color: color.white,
  fontFamily: 'DMSans',
  fontSize: '12px',
  fontWeight: 600,
  letterSpacing: '0px',
  lineHeight: '10px',
  height: '25px'
}))

export default InfoChip
