import React from 'react'
import { Grid, Typography } from '@mui/material'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import ReactHookFormCropperDropzone from '../common/form/ReactHookFormCropperDropzone'
import { useFormContext, useWatch } from 'react-hook-form'
import { IBrandForm } from './BrandForm'
import { useStrings } from '../../assets/localization/strings'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'

const BrandLogo = ({ mt }: { mt: number }) => {
  const strings = useStrings()
  const { control } = useFormContext<IBrandForm>()
  const isAgency = useWatch({ control, name: 'isAgency' })
  const label = isAgency ? strings.brand_creation_info_agency_logo : strings.brand_creation_info_brand_logo
  return (
    <>
      <Grid item xs={12} mt={mt}>
        <Typography variant='h5'>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} mt={2}>
        <ReactHookFormCropperDropzone name='brandLogo' label={label} accept='image/jpeg, image/png' height='185px' width='185px' aspectRatio={1 / 1} />
      </Grid>
    </>
  )
}

const BrandInfo = ({ campaignForm }: { campaignForm?: boolean }) => {
  const strings = useStrings()
  const { control } = useFormContext<IBrandForm>()
  const isAgency = useWatch({ control, name: 'isAgency' })

  const industryOptions: IOption<string>[] = [
    isAgency ? { value: 'MARKETING_AND_ADVERTISING', label: strings.brand_creation_info_industry_option_agency } : null,
    { value: 'LUXURY_GOODS_JEWELRY', label: strings.brand_creation_info_industry_option_accessories },
    { value: 'APPAREL_FASHION', label: strings.brand_creation_info_industry_option_apparel },
    { value: 'COSMETICS', label: strings.brand_creation_info_industry_option_beauty },
    { value: 'FOOD_BEVERAGES', label: strings.brand_creation_info_industry_option_beverages },
    { value: 'MANAGEMENT_CONSULTING', label: strings.brand_creation_info_industry_option_business },
    { value: 'E_LEARNING', label: strings.brand_creation_info_industry_option_education },
    { value: 'ENTERTAINMENT', label: strings.brand_creation_info_industry_option_entertainment },
    { value: 'FINANCIAL_SERVICES', label: strings.brand_creation_info_industry_option_financial },
    { value: 'SPORTS', label: strings.brand_creation_info_industry_option_fitness },
    { value: 'FOOD_BEVERAGES', label: strings.brand_creation_info_industry_option_food },
    { value: 'COMPUTER_GAMES', label: strings.brand_creation_info_industry_option_games },
    { value: 'HEALTH_WELLNESS_AND_FITNESS', label: strings.brand_creation_info_industry_option_health },
    { value: 'STAFFING_AND_RECRUITING', label: strings.brand_creation_info_industry_option_jobs },
    { value: 'INFORMATION_TECHNOLOGY_AND_SERVICES', label: strings.brand_creation_info_industry_option_mobile },
    { value: 'VETERINARY', label: strings.brand_creation_info_industry_option_pets },
    { value: 'ALTERNATIVE_DISPUTE_RESOLUTION', label: strings.brand_creation_info_industry_option_other }
  ].filterNotNull()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{isAgency ? strings.brand_creation_info_agency_name_question : strings.brand_creation_info_brand_name_question}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='brandName' label={isAgency ? strings.brand_creation_info_agency_name : strings.brand_creation_info_brand_name} />
      </Grid>
      <BrandLogo mt={4} />
      {!campaignForm && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography variant='h5'>{strings.brand_creation_info_industry}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <ReactHookFormSelect name='industry' label={strings.brand_creation_info_industry + '*'} options={industryOptions} />
          </Grid>
        </>
      )}
    </Grid>
  )
}
export const BrandInfoJustLogo = () => (
  <Grid container>
    <BrandLogo mt={0} />
  </Grid>
)

export default BrandInfo
