import { Button, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import LightBulb from '../../../assets/icons/ic_lightBulb.svg'
import noApplicants from '../../../assets/illustrations/im_verfication_no-applications.png'
import { useStrings } from '../../../assets/localization/strings'
import { Applicant, CampaignInsightsUgc } from '../../../requestModels'
import rootStore from '../../../stores/rootStore'
import styles from '../../../style/contentCampaign/application.module.css'
import { MockView } from '../../common/MockView'
import PaymentModal from '../../common/PaymentModal'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'
import InfluencerCard from '../Influencer'
import ProductPreferencesDialog from '../creators/ProductPreferencesDialog'

type Props = {
  influencers: Applicant[]
  campaign: CampaignInsightsUgc
  denyInfluencer(influencerId: string, denyText: string): Promise<void>
}

export const Applications = ({ influencers, denyInfluencer }: Props) => {
  const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>()
  const [selectedUgcInfluencer, setSelectedUgcInfluencer] = useState<Applicant>()

  const { campaign, setCampaign, accepted, acceptInfluencer } = useContext(ContentCampaignOverviewContext)
  const { enqueueSnackbar } = useSnackbar()
  const strings = useStrings()

  const renderInfluencers = () => {
    if (!influencers) return <></>
    if (influencers.length === 0)
      return (
        <Grid container direction='column' justifyContent='center' alignItems='center' className={styles.noApplicantsContainer}>
          <Grid item>
            <img className={styles.noApplicants} src={noApplicants} alt='no applicants' />
          </Grid>
          <Grid item>
            <h2>{strings.campaignOverviewApplicantsEmptyTitle}</h2>
          </Grid>
          <Grid item>
            <span>{strings.campaignOverviewApplicantsEmptyBody}</span>
          </Grid>
        </Grid>
      )

    return (
      <Grid container spacing={2} className={styles.cardContainer} style={{ marginTop: '20px' }}>
        {influencers.map(
          influencer =>
            influencer.contentCV && (
              <Grid item key={`${influencer.influencerId}`}>
                <InfluencerCard
                  key={`${influencer.influencerId}`}
                  influencer={influencer}
                  content={influencer.contentCV}
                  acceptInfluencer={async (...args) => {
                    if (accepted >= campaign.numberOfSlots) {
                      setSelectedInfluencerId(influencer.influencerId)
                    } else {
                      await acceptInfluencer(...args)
                    }
                  }}
                  denyInfluencer={denyInfluencer}
                  campaign={campaign}
                  showDenySteps
                  showAge
                  setSelectedUgcInfluencer={influencer.productPreferences?.length ? inf => setSelectedUgcInfluencer(inf as any) : undefined}
                />
              </Grid>
            )
        )}
      </Grid>
    )
  }

  const renderTopCard = () => {
    return (
      <Grid container className={styles.cardContainer} alignItems='center'>
        <Grid item sm={12} md={8} lg={10}>
          <span style={{ fontSize: 'medium' }}>
            <Grid container style={{ marginBottom: '10px' }}>
              <img src={LightBulb} style={{ width: '12px', marginRight: '10px' }} alt='!' />
              <b>{strings.campaign_overview_stats_title}</b>
            </Grid>
          </span>
          <span style={{ fontSize: 'small' }}>
            <p>{strings.campaign_overview_stats_body1}</p>
            <p>{strings.campaign_overview_stats_body2}</p>
          </span>
        </Grid>
        <Grid item sm={12} md={4} lg={2}>
          <Grid container spacing={2} sx={{ fontSize: 'small', float: 'left', marginRight: '10px' }}>
            <Grid item>
              <p>
                <b>{strings.campaign_overview_participant} </b>
              </p>
            </Grid>
            <Grid item>
              <b>
                {'    '}
                {campaign.accepted} / {campaign.numberOfSlots}
              </b>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <MockView name='Applicants'>
        <Button onClick={() => setSelectedInfluencerId(influencers[0]?.influencerId)}>Select 1st inf to pay</Button>
      </MockView>
      <PaymentModal
        open={!!selectedInfluencerId}
        handleClose={() => setSelectedInfluencerId(undefined)}
        callback={async coupon => {
          if (!selectedInfluencerId) return
          await rootStore.shopStore.buyMoreSlots({ brandId: campaign.brandId, campaignId: campaign.campaignId, extraNumberOfSlots: 1, coupon })
          campaign.numberOfSlots += 1
          setCampaign({ ...campaign })
          setSelectedInfluencerId(undefined)
          await acceptInfluencer(selectedInfluencerId)
          enqueueSnackbar('Erfolgreich gebucht', { variant: 'success' })
        }}
        title='Weiteren Bewerber'
        body='Buche jetzt einen weiteren Bewerber.'
        videoType={campaign.videoType}
        videoDuration={campaign.videoDuration}
        videoFeatures={campaign.videoFeatures}
        numberOfVideos={1}
      />
      <ProductPreferencesDialog open={!!selectedUgcInfluencer} influencer={selectedUgcInfluencer} campaign={campaign} onClose={() => setSelectedUgcInfluencer(undefined)} />
      {renderTopCard()}
      <Grid container>{renderInfluencers()}</Grid>
    </>
  )
}
export default Applications
