import { createTheme, PaletteColorOptions } from '@mui/material'

export const color = {
  stylinkDarkPurple: '#190851',
  stylinkPurple: '#a890fe',
  stylinkYellow: '#f7f98f',
  stylinkOffwhite: '#fefdef',
  black: '#000',
  white: '#FFF',
  grey1: '#828282',
  grey2: '#4F4F4F',
  grey3: '#E0E0E0',
  grey4: '#F8F8F8',
  grey5: '#BDBDBD',
  grey6: '#F2F2F2',
  backgroundPurple: '#F4F1FC'
}

export const theme = createTheme({
  palette: {
    primary: {
      main: color.stylinkPurple,
      contrastText: color.white
    },
    secondary: {
      main: color.white,
      contrastText: color.stylinkPurple
    },
    tertiary: {
      main: color.black,
      contrastText: color.white
    }
  },
  typography: {
    fontFamily: ['DMSans'].join(','),
    h1: {
      fontSize: '24px',
      fontWeight: 600,
      color: color.black,
      fontFamily: 'Bricolage Grotesque'
    },
    h2: {
      fontSize: '22px',
      fontWeight: 600,
      color: color.black
    },
    h3: {
      fontWeight: 600,
      fontSize: '17px',
      color: color.black
    },
    h4: {
      fontWeight: 600,
      fontSize: '16px',
      color: color.black
    },
    h5: {
      fontWeight: 600,
      fontSize: '15px',
      color: color.black
    },
    body1: {
      fontWeight: 400,
      fontSize: '12px',
      color: color.black
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      color: color.black
    },
    caption: {
      color: color.grey5,
      fontSize: '10px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          fontSize: '17px',
          fontWeight: 400,
          color: color.grey2
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 2px 0 rgba(229, 229, 229, 0.3)',
          border: '1px solid',
          borderColor: color.grey6
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px'
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          height: '32px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: color.white
        }
      }
    },
    MuiFab: {
      styleOverrides: { root: { zIndex: 10 } }
    }
  }
})

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColorOptions
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
  }
}
