import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Grid, Link } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { boolean, object, string } from 'yup'
import { analytics, TagManager } from '../..'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import { theme } from '../../theme'
import { AuthPage } from '../auth/Auth'
import ReactHookFormCheckbox from '../common/form/ReactHookFormCheckbox'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { emailPasswordSchema } from './Login'

type ISignupForm = { firstname: string; surname: string; email: string; password: string; conditionsCheck: boolean }

const SignUp = ({ pageSwitch }: { pageSwitch(page: AuthPage): void }) => {
  const strings = useStrings()
  const schema = object().shape({
    firstname: string().min(2, strings.login_register_yup_firstname1),
    surname: string().min(2, strings.login_register_yup_surname1),
    ...emailPasswordSchema(strings),
    conditionsCheck: boolean().required(strings.login_register_yup_confirmation)
  })
  const [loading, setLoading] = useState(false)
  const methods = useForm<ISignupForm>({ resolver: yupResolver(schema), defaultValues: { conditionsCheck: false } })
  const { setError, watch } = methods

  const formSubmitHandler: SubmitHandler<ISignupForm> = async (data: ISignupForm) => {
    const { firstname, surname, email, password } = data
    setLoading(true)

    try {
      const signUpResult = await rootStore.authStore.register(email.trim(), password, firstname, surname)

      const tagManagerArgs = {
        dataLayer: {
          userId: signUpResult.userSub,
          event: 'signUp'
        }
      }
      TagManager.dataLayer(tagManagerArgs)

      await rootStore.authStore.login(email, password)
      analytics.logEvent('signUp', {})
      pageSwitch(AuthPage.onboarding)
    } catch (e) {
      console.error(e)
      //@ts-ignore
      if (e.code === 'UsernameExistsException') {
        setError('email', { type: 'custom', message: strings.login_error_email_exists })
      } else {
        setError('email', { type: 'custom', message: strings.login_error })
      }
    } finally {
      setLoading(false)
    }
  }

  const disabled = watch('email')?.length < 1 || watch('password')?.length < 1 || watch('firstname')?.length < 1 || watch('surname')?.length < 1 || !watch('conditionsCheck')

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Grid container justifyContent='center' sx={{ [theme.breakpoints.only('xs')]: { pl: 2, pr: 2 } }}>
          <Grid container flexDirection='column' spacing={2} item sm={7}>
            <Grid item>
              <ReactHookFormTextField label={strings.login_register_firstname_label} name='firstname' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_register_surname_label} name='surname' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_email_label} name='email' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_password_label} name='password' type='password' />
            </Grid>
            <Grid item>
              <ReactHookFormCheckbox
                name='conditionsCheck'
                label={
                  <>
                    {strings.login_register_confirmation_part1}{' '}
                    <Link href='https://getnano.io/de/agb/' target='_blank'>
                      {strings.login_register_confirmation_part2}
                    </Link>{' '}
                    {strings.login_register_confirmation_part3}{' '}
                    <Link href='https://getnano.io/de/datenschutzerklaerung/' target='_blank'>
                      {strings.login_register_confirmation_part4}
                    </Link>{' '}
                    {strings.login_register_confirmation_part5}
                  </>
                }
              />
            </Grid>
            <Grid item textAlign='center'>
              <LoadingButton variant='contained' loading={loading} type='submit' disabled={disabled} color='tertiary' id='REGISTER'>
                {strings.login_register_submit_button}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default SignUp
