import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'
import { analytics, TagManager } from '../..'
import { Strings, useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import { color, theme } from '../../theme'
import { AuthPage } from '../auth/Auth'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'

type ILoginForm = { email: string; password: string }

export const emailPasswordSchema = (strings: Strings) => ({
  email: string().trim().email(strings.login_yup_email1).required(strings.login_yup_email2),
  password: string()
    .matches(/^.{8,}$/, strings.login_yup_password1)
    .matches(/^.*[a-z].*$/, strings.login_yup_password2)
    .matches(/^.*[A-Z].*$/, strings.login_yup_password3)
    .matches(/^.*\d.*$/, strings.login_yup_password4)
    .required(strings.login_yup_password5)
})

const Login = ({ pageSwitch }: { pageSwitch(page: AuthPage): void }) => {
  const strings = useStrings()
  const schema = object().shape(emailPasswordSchema(strings))
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const methods = useForm<ILoginForm>({ resolver: yupResolver(schema) })
  const { setError, watch } = methods

  const formSubmitHandler: SubmitHandler<ILoginForm> = async ({ email, password }: ILoginForm) => {
    setLoading(true)

    try {
      const loginResult = await rootStore.authStore.login(email.trim(), password)

      const tagManagerArgs = {
        dataLayer: {
          userId: loginResult.username,
          event: 'login'
        }
      }

      TagManager.dataLayer(tagManagerArgs)

      analytics.logEvent('login', {})
      history.push('/dashboard')
    } catch (e) {
      console.error(e)
      //@ts-ignore
      if (e.code === 'LimitExceededException') {
        setError('email', { type: 'custom', message: strings.login_error_too_many_attempts })
      } else {
        setError('email', { type: 'custom', message: strings.login_error_wrong_credentials })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)} style={{ width: '100%' }}>
        <Grid container justifyContent='center' sx={{ [theme.breakpoints.only('xs')]: { pl: 2, pr: 2 } }}>
          <Grid container flexDirection='column' spacing={2} item sm={7}>
            <Grid item>
              <ReactHookFormTextField label={strings.login_email_label} name='email' />
            </Grid>
            <Grid item>
              <ReactHookFormTextField label={strings.login_password_label} name='password' type='password' />
            </Grid>
            <Grid container item justifyContent='flex-end'>
              <Button onClick={() => pageSwitch(AuthPage.forgotPassword)} variant='text' size='small' sx={{ [theme.breakpoints.only('xs')]: { color: color.black } }}>
                {strings.login_forgot_password_button}
              </Button>
            </Grid>
            <Grid item textAlign='center'>
              <LoadingButton variant='contained' loading={loading} color='tertiary' type='submit' disabled={watch('email')?.length < 1 && watch('password')?.length < 1} id='LOGIN'>
                {strings.login_button}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default Login
