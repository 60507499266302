import { CircularProgress, Grid, TextField } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
/* mobx */
import { observer } from 'mobx-react'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import styles from '../../style/settings.module.css'
import LoaderButton from '../common/layout/LoaderButton'
import ErrorReload from '../error/ErrorReload'

const Settings = () => {
  const [user, setUser] = useState({ firstname: '', surname: '', email: '', enabledAnalytics: false })
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [analyticsLoading, setAnalyticsLoading] = useState(false)
  const { firstname, surname, email, enabledAnalytics } = user
  const strings = useStrings()

  const validateForm = () => user.firstname.replace(/\s/g, '') && user.surname.replace(/\s/g, '')

  useEffect(() => {
    const componentDidMount = async () => {
      try {
        if (rootStore.userStore.authenticatedUser) {
          setLoading(false)
          setUser(rootStore.userStore.authenticatedUser)
        }
      } catch (e) {
        //@ts-ignore
        this.setState({ error: e })
        console.error(e)
      }
    }
    componentDidMount()
  }, [])

  const onSaveClick = async () => {
    setSaving(true)
    try {
      await rootStore.userStore.updateUser(user)
    } catch (e) {
      setError(true)
    }
    setSaving(false)
    setIsEditing(false)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { name, value } = event.target

    setIsEditing(true)
    setUser(user => ({ ...user, [name]: value }))
  }

  const changeAnalytics = async () => {
    setAnalyticsLoading(true)
    await rootStore.userStore.optOutAnalytics()
    setUser({ ...user, enabledAnalytics: !user.enabledAnalytics })
    setAnalyticsLoading(false)
    if (!user.enabledAnalytics) alert(strings.profile_settings_analytics_disabled)
  }

  if (error) return <ErrorReload />

  return (
    <Grid container wrap='nowrap'>
      <Grid item xs={12} className={styles.container}>
        <h1>{strings.profile_settings_title}</h1>
        <Grid container alignItems='center' style={{ height: '100%' }}>
          {loading ? (
            <div className='loadingFreebee'>
              <CircularProgress className='loadingFreebee' color='primary' />
            </div>
          ) : (
            <Grid xs={12} item className={styles.form}>
              <TextField
                className={styles.textField}
                label={strings.profile_settings_firstname}
                name='firstname'
                variant='standard'
                value={firstname}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                className={styles.textField}
                label={strings.profile_settings_surname}
                name='surname'
                variant='standard'
                value={surname}
                onChange={handleChange}
                fullWidth
              />
              <TextField className={styles.textField} label={strings.profile_settings_email} variant='standard' value={email} fullWidth disabled />
              <TextField className={styles.textField} label={strings.profile_settings_password} variant='standard' value='********' fullWidth disabled />
              <Grid item xs={12} className={styles.passwordContainer}>
                <Link className='link' to='/newPassword'>
                  {strings.profile_settings_change_password}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <div className='tar'>
                  <span
                    className='link'
                    onClick={async () => {
                      if (!analyticsLoading) await changeAnalytics()
                    }}>
                    {enabledAnalytics ? strings.profile_settings_analytics_unable : strings.profile_settings_analytics_enable}
                    {analyticsLoading && <CircularProgress size='small' />}
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.buttonContainer}>
                <LoaderButton onClick={onSaveClick} disabled={!isEditing || !validateForm} isLoading={saving} text={strings.profile_settings_save} />
                {error ? strings.profile_settings_save_error : ''}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(Settings)
