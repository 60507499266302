import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import ic_moodboard from '../../../assets/icons/ic_moodboard.svg'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color } from '../../../theme'
import { useMediaStore } from '../../createUgcCampaign/MediaStore'
import 'cropperjs/dist/cropper.css'
import { useSnackbar } from 'notistack'

type Props = { name: string; label: string; accept: string; width: string; height: string; aspectRatio: number }

const ReactHookFormCropperDropzone = ({ name, label, width, height, aspectRatio, accept }: Props) => {
  const [file, setFile] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()
  const isReadonly = useReadonly(name)

  const {
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const inputFile = useRef<HTMLInputElement | null>(null)
  const onDrop = useCallback<DropzoneOptions['onDrop'] & {}>(
    droppedFile => {
      if (isReadonly) return
      const file = droppedFile[0]
      if (file.size / 1_000_000 > 3) enqueueSnackbar('Max. 3MB erlaubt', { variant: 'error' })
      else setFile(URL.createObjectURL(file))
    },
    [file, isReadonly]
  )
  const { getRootProps, isDragActive } = useDropzone({ onDrop, accept })
  const [[store = undefined], { remove, replace }] = useMediaStore(name, false)

  const cropperRef = useRef<HTMLImageElement>(null)
  const onCrop = () => {
    const imageElement: any = cropperRef?.current
    const cropper: any = imageElement?.cropper
    const url: string = cropper.getCroppedCanvas().toDataURL()
    replace(store, url)
    clearErrors(name)
    setFile(undefined)
  }

  const deleteFile = () => isReadonly && remove(store)

  const renderCropper = () => (
    <Dialog open={!!file} onClose={() => setFile(undefined)}>
      <DialogTitle>{label || 'Brand Logo'}</DialogTitle>
      <DialogContent>
        <Cropper style={{ width: '400px', height: '300px' }} aspectRatio={aspectRatio} guides src={file} ref={cropperRef} />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onCrop} id='CROP'>
          Zuschneiden
        </Button>
      </DialogActions>
    </Dialog>
  )

  const renderInner = () => {
    const src = store?.src
    if (!src)
      return (
        <>
          <Grid item textAlign='center'>
            <img alt='moodboard' src={ic_moodboard} />
          </Grid>
          <Grid item>
            <Typography variant='h3' textAlign='center'>
              {label}
            </Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography variant='caption'>File: JPG/PNG; min. 400x400</Typography>
          </Grid>
        </>
      )

    return (
      <Grid container item xs={12} spacing={1} p={1}>
        <Grid item xs={12} position='relative'>
          <HighlightOffIcon fontSize='small' sx={{ position: 'absolute', top: -5, right: -5 }} onClick={deleteFile} />
          <img id={store.valueId} src={src} alt={name} width='100%' />
        </Grid>
      </Grid>
    )
  }

  const inputProps = !file && !isReadonly ? { ...getRootProps() } : undefined
  const background = isDragActive ? color.backgroundPurple : color.grey4
  // const isMobile = !!navigator.userAgent.match(/(safari|android)/i)
  return (
    <>
      {renderCropper()}
      <input type='file' style={{ display: 'none' }} name={name} ref={inputFile} onChange={e => onDrop(e.target.files ? Array.from(e.target.files) : [], [], undefined as any)} />
      <Grid container sx={{ height, width, background, border: '1px dotted', borderColor: color.grey3, borderRadius: '8px' }}>
        <Grid container item flexDirection='column' justifyContent='space-evenly' {...inputProps} onClick={() => inputFile.current?.click()}>
          {renderInner()}
        </Grid>
      </Grid>
      {errors[name] && (
        <FormControl error>
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    </>
  )
}

export default ReactHookFormCropperDropzone

// const getCroppedImg = (image, crop, fileName) => {
//   const canvas = document.createElement('canvas')
//   const pixelRatio = window.devicePixelRatio
//   const scaleX = image.naturalWidth / image.width
//   const scaleY = image.naturalHeight / image.height
//   const ctx = canvas.getContext('2d')
//   check(ctx, 'Missing canvas')

//   canvas.width = crop.width * pixelRatio * scaleX
//   canvas.height = crop.height * pixelRatio * scaleY

//   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
//   ctx.imageSmoothingQuality = 'high'

//   ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY)

//   return new Promise((resolve, reject) => {
//     canvas.toBlob(
//       blob => {
//         if (!blob) {
//           reject(new Error('Canvas is empty'))
//           return
//         }
//         blob.name = fileName
//         window.URL.revokeObjectURL(this.fileUrl)
//         this.fileUrl = window.URL.createObjectURL(blob)
//         resolve(this.fileUrl)
//       },
//       'image/jpeg',
//       1
//     )
//   })
// }
