import { Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { color, theme } from '../theme'

type Ref = React.RefObject<HTMLDivElement | null>
type Render = () => React.ReactNode
export type Sections = ([Render, string | undefined] | undefined | null)[]
export const useSections = (_sections: Sections) => {
  const sections = _sections.filterNotNull()
  const [viewRefs, setViewRefs] = useState<Map<string, { visible?: boolean; ref?: Ref }>>(new Map())
  useEffect(() => {
    setViewRefs(sections.reduce((sum, s) => (s[1] ? sum.set(s[1], {}) : sum), new Map()))
  }, [JSON.stringify(sections.map(s => s[1]))])
  const lastVisible = (() => {
    const visibles = Array.from(viewRefs.entries()).filter(p => p[1].visible)
    return visibles.at(-1)?.[0]
  })()

  return {
    sections,
    viewRefs: Array.from(viewRefs.entries()),
    setViewRefs: (label: string, ref: Ref, visible: boolean) => setViewRefs(new Map(viewRefs.set(label, { ref, visible }))),
    lastVisible
  }
}

type FocusableProps = { render: Render; onVisibleChange: (_: boolean, ref: Ref) => void }
const Focusable = ({ render, onVisibleChange }: FocusableProps) => {
  const { ref: cardRef, inView: isVisible } = useInView()
  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    onVisibleChange(isVisible, sectionRef)
  }, [isVisible])

  return (
    <Grid item xs={12}>
      <Card variant='outlined' ref={cardRef}>
        <CardContent ref={sectionRef}>{render()}</CardContent>
      </Card>
    </Grid>
  )
}

const NonFocusable = ({ render }: { render: Render }) => (
  <Grid item xs={12}>
    <Card variant='outlined'>
      <CardContent>{render()}</CardContent>
    </Card>
  </Grid>
)

type FocusableSectionsProps = { sections: [Render, string | undefined][]; setViewRefs(...a: [string, Ref, boolean]): void }
export const FocusableSections = ({ sections, setViewRefs }: FocusableSectionsProps) => (
  <>
    {sections.map(
      ([render, label], i) =>
        render &&
        (label ? <Focusable key={i + label} render={render} onVisibleChange={(visible, ref) => setViewRefs(label, ref, visible)} /> : <NonFocusable key={i} render={render} />)
    )}
  </>
)

type SectionsProps = {
  sections: [Render, string | undefined][]
  viewRefs: [string, { visible?: boolean; ref?: React.RefObject<HTMLDivElement | null> }][]
  lastVisible: string | undefined
}
export const Sections = ({ sections, viewRefs, lastVisible }: SectionsProps) => {
  return (
    <>
      {viewRefs
        .filter(([section]) => sections.find(p => p[1] == section)) // filter out hidden sections
        .map(([section, { ref }]) => (
          <Grid item key={section} mb={1} onClick={() => ref?.current?.scrollIntoView({ behavior: 'smooth' })}>
            <Typography color={lastVisible == section ? color.stylinkDarkPurple : 'none'} fontSize={'12px'} fontWeight={600}>
              {section}
            </Typography>
          </Grid>
        ))}
    </>
  )
}
