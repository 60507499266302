import ErrorIcon from '@mui/icons-material/Error'
import { Box, Checkbox, CircularProgress, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import ReactPlayer from 'react-player'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import { IUGCCampaign } from './usePersistedUGCForm'

type Creator = Awaited<ReturnType<typeof rootStore.campaignStore.getAllFinishedCreators>>[number]
export const InviteCreators = ({ creators, loadCreators }: { creators: Creator[] | Error | undefined; loadCreators(): any }) => {
  const { control, setValue } = useFormContext<IUGCCampaign>()
  const selectedCreators = useWatch({ control, name: 'inviteInfluencers' })
  const [selectedCreator, setSelectedCreator] = useState<string>()
  const strings = useStrings()

  useEffect(() => {
    setValue('inviteInfluencers', [])
  }, [JSON.stringify(creators)])

  if (creators == null) return <CircularProgress />
  if (creators instanceof Error)
    return (
      <IconButton onClick={() => loadCreators()}>
        <ErrorIcon fontSize='small' />
      </IconButton>
    )

  const renderImage = (imageUrl?: string) => <img src={imageUrl} alt='Logo' height='30px' style={{ borderRadius: '100%', objectFit: 'contain' }} />
  const creator = creators.find(c => c.influencerId == selectedCreator)
  const link = creator?.link
  return (
    <>
      {creator && (
        <Dialog open={!!link} onClose={() => setSelectedCreator(undefined)}>
          <Stack>
            <div className='videoPlayerWrapper'>
              <ReactPlayer url={link} maxWidth='50vh' maxHeight='50vh' width='auto' height='auto' controls />
            </div>
            <Stack direction='row' alignItems='center' spacing={1}>
              {renderImage(creator.imageUrl)}
              <Box>
                <Typography variant='body2'>{creator.givenname}</Typography>
              </Box>
            </Stack>
          </Stack>
        </Dialog>
      )}
      <Stack spacing={2}>
        <Box>
          <Typography variant='h5'>{strings.campaign_creation_invite_title}</Typography>
        </Box>
        <Box>
          <Typography variant='body2'>{strings.campaign_creation_invite_body}</Typography>
        </Box>
        <Grid container>
          {creators.map(creator => {
            const id = creator.influencerId
            const selected = selectedCreators.includes(id)
            const select = (isSelected: boolean) => {
              const removed = selectedCreators.filter(_id => _id != id)
              setValue('inviteInfluencers', isSelected ? [...removed, id] : removed)
            }
            return (
              <Grid key={id} item xs={6}>
                <Stack direction='row' alignItems='center' spacing={1} width='95%' sx={{ borderBottom: '1px solid #BEBEBE', cursor: 'pointer' }}>
                  {renderImage(creator.imageUrl)}
                  <Box onClick={() => setSelectedCreator(id)}>
                    <Typography variant='body2' sx={{ textDecoration: 'underline' }}>
                      {creator.givenname}
                    </Typography>
                  </Box>
                  <Box width='100%' />
                  <Box>
                    <Checkbox checked={selected} onChange={e => select(e.target.checked)} />
                  </Box>
                </Stack>
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </>
  )
}
