import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Grid, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useFormContext, useWatch } from 'react-hook-form'
import ReactPlayer from 'react-player'
import ic_moodboard from '../../../assets/icons/ic_moodboard.svg'
import { useStrings } from '../../../assets/localization/strings'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color, theme } from '../../../theme'
import { MediaStore, useMediaStore } from '../../createUgcCampaign/MediaStore'

/**
 * TODO
 * Video Bugs a bit (seems to reload everytime you click on area)
 * Deleting always opens Input
 */
const ReactHookFormDropzone = ({ name, mode = 'append', accept }: { name: string; mode: 'append'; accept: string }) => {
  const { setValue } = useFormContext()
  const isReadonly = useReadonly(name)
  const strings = useStrings()
  const [stores, { remove, add }] = useMediaStore(name, true)

  const onDrop = useCallback<DropzoneOptions['onDrop'] & {}>(
    droppedFiles => {
      if (isReadonly) return
      for (const file of droppedFiles) {
        if (stores.some(s => s.isSameSource(file))) continue
        add(file)
      }
    },
    [setValue, name, mode, add]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

  const deleteFile = (store: MediaStore) => remove(store)

  const renderInner = () => {
    if (!stores?.length) {
      return (
        <>
          <Grid item xs={0} sm={4} textAlign='center'>
            <img alt='moodboard' src={ic_moodboard} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant='h3' sx={{ [theme.breakpoints.only('xs')]: { textAlign: 'center' } }}>
              {strings.drag_and_drop_title}
            </Typography>
            <Typography variant='body2' sx={{ [theme.breakpoints.only('xs')]: { textAlign: 'center' } }}>
              {strings.drag_and_drop_body}
            </Typography>
            <Typography variant='caption' sx={{ [theme.breakpoints.only('xs')]: { textAlign: 'center' } }}>
              {strings.drag_and_drop_caption}
            </Typography>
          </Grid>
        </>
      )
    }
    return (
      <Grid container item xs={12} spacing={1} p={1}>
        {stores.map(store => {
          return (
            <Grid item xs={2} position='relative' key={store.id}>
              <HighlightOffIcon fontSize='small' sx={{ position: 'absolute', top: -5, right: -5 }} onClick={() => deleteFile(store)} />
              {store.isImage ? <img src={store.src} width='100%' /> : <ReactPlayer url={store.src} width='100%' height='auto' />}
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const background = isDragActive ? color.backgroundPurple : color.grey4
  return (
    <Grid container sx={{ height: '286px', width: '100%', background, border: '1px dotted', borderColor: color.grey3, borderRadius: '8px' }}>
      <Grid item xs={12} container {...{ ...getRootProps(), ...(isReadonly ? { onClick: undefined } : {}) }} alignItems='center' justifyContent='center'>
        <input {...{ name, mode, accept }} id={name} {...{ ...getInputProps(), ...(isReadonly ? { onClick: undefined } : {}) }} />
        {renderInner()}
      </Grid>
    </Grid>
  )
}

export default ReactHookFormDropzone
