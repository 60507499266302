import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useStrings } from '../../assets/localization/strings'

export const EmptyCampaigns = ({ onCreate }: { onCreate(): void }) => {
  const strings = useStrings()
  const price = 189
  return (
    <Grid container spacing={3} pt={3} pb={3}>
      {/* TODO
      <Grid item container xs={12} justifyContent='center'>
        <Player src={strings.dashboard_empty_tour} width={526} />
      </Grid> 
      */}
      <Grid item xs={12} container justifyContent='center' mt={3}>
        <Typography variant='h6' textAlign='center' width='100%'>
          {strings.dashboard_empty_title}
        </Typography>
        <Typography variant='body1' textAlign='center' width='100%'>
          {strings.dashboard_empty_body(price)}
        </Typography>
        <Button sx={{ mt: 2 }} variant='contained' onClick={onCreate} id='CREATE_CAMPAIGN_LINK_TUTORIAL'>
          {strings.dashboard_create_ugc_button}
        </Button>
      </Grid>
    </Grid>
  )
}
