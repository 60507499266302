import { Grid } from '@mui/material'
import React from 'react'
import planet from '../../assets/illustrations/Planet.svg'
import { useStrings } from '../../assets/localization/strings'

export const ErrorReload = () => {
  const strings = useStrings()
  return (
    <Grid container wrap='nowrap' alignItems='center' style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <h2>{strings.error_reload_body}</h2>
        <img style={{ marginTop: '2em' }} src={planet} alt='planet' />
      </Grid>
    </Grid>
  )
}

export default ErrorReload
