import { CircularProgress } from '@mui/material'
import React from 'react'

const LoaderButton = ({ isLoading, text, disabled = false, ...props }) => (
  <button disabled={disabled || isLoading} {...props}>
    <b id={props.id}>{!isLoading ? text : <CircularProgress className='loadingFreebee' size={24} />}</b>
  </button>
)

export default LoaderButton
