import { CircularProgress, Grid } from '@mui/material'
import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import planet from '../../assets/illustrations/Planet.svg'
import rootStore from '../../stores/rootStore'
import UnsubscribeError from '../error/UnsubscribeError'

interface RouteParams {
  brandId?: string
  influencerId?: string
  userId?: string
}

interface State {
  error?: any
  loading: boolean
}

export class Unsubscribed extends Component<RouteComponentProps<RouteParams>, State> {
  constructor(props: RouteComponentProps<RouteParams>) {
    super(props)
    this.state = {
      loading: true,
      error: undefined
    }
  }

  async componentDidMount() {
    const { brandId, influencerId, userId } = this.props.match.params

    console.log(brandId, influencerId, userId)
    console.log(this.state.loading)

    try {
      if (brandId) {
        await rootStore.authStore.unsubscribeBrand(brandId)
      } else if (influencerId) {
        await rootStore.authStore.unsubscribeInfluencer(influencerId)
      } else if (userId) {
        console.log('Before Unsub')
        await rootStore.authStore.unsubscribeUser(userId)
        console.log('After Unsub')
      } else {
        this.setState({ error: 'Fehler' })
      }
    } catch (e) {
      console.error(e)
      this.setState({
        error: e
      })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    const { error, loading } = this.state

    if (loading) {
      return (
        <div className='loadingFreebee'>
          <CircularProgress className='loadingFreebee' color='primary' />
        </div>
      )
    }

    if (error) {
      return <UnsubscribeError />
    }

    return (
      <Grid container wrap='nowrap' alignItems='center' style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <h2>Erfolgreich abgemeldet.</h2>
          <img style={{ marginTop: '2em' }} src={planet} alt='planet' />
        </Grid>
      </Grid>
    )
  }
}

export default Unsubscribed
