export enum ContentCampaignLocale {
  DELIVERY = 'delivery',
  MONEY = 'MONEY'
}

export { ContentCampaignLocale as Locale }

export enum ShippingService {
  AT_POST = 'Österreichische Post',
  DE_POST = 'Deutsche Post',
  DHL = 'DHL',
  DPD = 'DPD',
  GLS = 'GLS',
  UPS = 'UPS',
  OTHER = 'Andere'
}

export enum CampaignType {
  ugc = 'ugc'
}

export const ShippingServicesArray = [
  ShippingService.AT_POST,
  ShippingService.DE_POST,
  ShippingService.DHL,
  ShippingService.DPD,
  ShippingService.GLS,
  ShippingService.UPS,
  ShippingService.OTHER
]

export enum VideoType {
  ad = 'ad',
  honest = 'honest',
  showcase = 'showcase',
  premium = 'premium'
  // testimonial = 'testimonial'
}

export enum VideoFormat {
  square = 'square',
  horizontal = 'wide',
  vertical = 'vertical',
  fullscreen = 'fullscreen'
}

export enum VideoFeature {
  small = 'subtitles',
  medium = 'medium',
  large = 'large',
  up_small_medium = 'up_small_medium',

  /** @deprecated */
  subtitles = 'subtitles',
  /** @deprecated */
  music = 'music',
  /** @deprecated */
  animations = 'animations',
  /** @deprecated */
  up_small_large = 'up_small_large',
  /** @deprecated */
  up_medium_large = 'up_medium_large'
}

export enum VideoDuration {
  Sec15 = 15,
  Sec30 = 30,
  Sec60 = 60
}

export enum Service {
  selfService = 'selfService',
  nanoService = 'nanoService'
}

export enum ProductType {
  physical = 'physical',
  digital = 'digital'
}

export enum DeliveryType {
  online = 'online',
  delivery = 'delivery'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  ANY = 'any',
  DIVERSE = 'diverse'
}

export enum VoiceOver {
  male = 'male',
  female = 'female',
  computer = 'computer',
  any = 'any'
}

export enum AgeOptions {
  age1 = '16-18',
  age2 = '18-30',
  age3 = '30+'
}

export enum Role {
  Admin = 'Admin',
  Marketer = 'Marketer',
  Analyst = 'Analyst',
  Accountant = 'Accountant'
}

export enum PaymentType {
  Creditcard = 'creditcard',
  Sepa = 'sepa'
}

export enum UGCTodoType {
  awaitFeedback = 'awaitFeedback',
  seeFeedback = 'seeFeedback',
  awaitContentFeedback = 'awaitContentFeedback',
  awaitDelivery = 'awaitDelivery',
  uploadContent = 'uploadContent'
}

export enum StatusInfo {
  // Means that user sent his docs, but company needs to go over the docs
  Applied = 'applied',
  Accepted = 'accepted',
  Finished = 'finished',
  /** @deprecated */
  Rejected = 'rejected',
  /** @deprecated */
  CodeReceived = 'code received',
  /** @deprecated */
  Sent = 'sent',
  /** @deprecated */
  Overtime = 'overtime',
  /** @deprecated */
  Canceled = 'canceled',
  // Means that konrad needs to verify whether the user is reliable
  /** @deprecated */
  ToBeVerified = 'to_be_verified',
  /** @deprecated */
  Reported = 'reported'
}
