import { Grid, Hidden, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import logo from '../../assets/logo/logo_square_simple.png'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import OnboardingFeedback from './OnboardingFeedback'
import SignUp from './SignUp'
import Instructions from './Instructions'
import PlatformMode from './PlatformMode'
import background from '../../assets/backgrounds/bg_signup.svg'
import { theme } from '../../theme'
import { useStrings } from '../../assets/localization/strings'

export enum AuthPage {
  login = 'login',
  signup = 'signup',
  forgotPassword = 'forgotPassword',
  onboarding = 'onboarding',
  platformMode = 'platformMode'
}

const Auth = ({ page }: { page: AuthPage }) => {
  const strings = useStrings()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState('')
  const query = new URLSearchParams(useLocation().search)
  const code = query.get('code')

  useEffect(() => {
    setCurrentPage(page)
  }, [])

  const pageSwitch = (setPage: AuthPage) => {
    setCurrentPage(setPage)
    history.push(`/${setPage}`)
  }

  const renderPage = () => {
    switch (currentPage) {
      case AuthPage.login:
        return <Login pageSwitch={pageSwitch} />
      case AuthPage.signup:
        return <SignUp pageSwitch={pageSwitch} />
      case AuthPage.forgotPassword:
        return <ForgotPassword />
      case AuthPage.onboarding:
        return <OnboardingFeedback pageSwitch={pageSwitch} />
      case AuthPage.platformMode:
        return <PlatformMode />
      default:
        return <></>
    }
  }

  const renderSwitch = () => {
    if (page === AuthPage.forgotPassword) return <></>

    let switchTo: AuthPage = page === AuthPage.login ? AuthPage.signup : AuthPage.login
    if (!code && switchTo === AuthPage.signup) switchTo = AuthPage.signup

    let switchText = ''
    let switchButtonText = ''

    if (switchTo === AuthPage.login) {
      switchText = strings.login_register_text
      switchButtonText = strings.login_login_button
    } else {
      switchText = strings.login_login_text
      switchButtonText = strings.login_register_button
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <div>
              {switchText}{' '}
              <a
                id='switch'
                style={{ textTransform: 'none' }}
                onClick={e => {
                  e.preventDefault()
                  pageSwitch(switchTo)
                }}
                href=''>
                <b>{switchButtonText}</b>
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <a href='https://getnano.io/de/agb/' rel='noreferrer' target='_blank' style={{ fontWeight: 600, textDecoration: 'underline' }}>
              {strings.login_agb_button}
            </a>
            <Typography style={{ padding: '0 8px' }}>|</Typography>
            <a target='_blank' rel='noreferrer' href='https://getnano.io/de/datenschutzerklaerung/' style={{ fontWeight: 600, textDecoration: 'underline' }}>
              {strings.login_data_protection_button}
            </a>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container component='main' height='100vh' sx={{ [theme.breakpoints.only('xs')]: { backgroundImage: `url(${background})` } }}>
      <Grid container item xs={12} md={6} flexDirection='column' justifyContent='space-around'>
        <Grid container item justifyContent='center'>
          <img style={{ cursor: 'pointer' }} src={logo} alt='Logo' width='200px' onClick={() => pageSwitch(AuthPage.login)} />
        </Grid>
        <Grid item>{renderPage()}</Grid>
        <Grid item>{(page === AuthPage.login || page === AuthPage.signup) && renderSwitch()}</Grid>
      </Grid>
      <Hidden mdDown={true}>
        <Instructions />
      </Hidden>
    </Grid>
  )
}

export default Auth
