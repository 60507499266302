import { Close, Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Dialog, DialogContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import Add from '../../../assets/icons/ic_add_orange.svg'
import { useStrings } from '../../../assets/localization/strings'
import { UgcContent } from '../../../requestModels'
import rootStore from '../../../stores/rootStore'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'

type Props = { handleClose(influencerId?: string): void; open: boolean; influencer: UgcContent; campaignId: string; todoId: string }
export const DenyDialog = ({ handleClose, open, influencer, campaignId, todoId }: Props) => {
  const [selectedReasons, setSelectedReasons] = useState([''])
  const [loading, setLoading] = useState(false)
  const { reload } = useContext(ContentCampaignOverviewContext)
  const { enqueueSnackbar } = useSnackbar()
  const strings = useStrings()

  // TODO Problem that it is not called in the ContentCampaignOverview
  // seeAcceptContent --> loadParticipants() is missing
  const sendFeedback = async () => {
    setLoading(true)
    try {
      const { influencerId } = influencer
      await rootStore.campaignStore.manageInfluencerContent(
        campaignId,
        influencerId,
        todoId,
        false,
        selectedReasons.filter(s => s != '')
      )
      await reload()
      handleClose(influencerId)
    } catch (e) {
      console.error(e)
      enqueueSnackbar(strings.error, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={() => handleClose()}>
      <DialogContent sx={{ pr: 5, pl: 5 }}>
        <Grid container>
          <Grid container item xs={6} alignItems='flex-end'>
            <Typography variant='h3'>{strings.deny_dialog_title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent='flex-end'>
              <IconButton onClick={() => handleClose(undefined)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} mt={2}>
            <Grid container item sx={{ aspectRatio: '9/16', maxHeight: '45vh' }} className='ugc-subscription-dialog-video-wrapper' xs={5} alignItems='center'>
              <ReactPlayer url={influencer.contentLinks[0]} config={{ file: { attributes: { controlsList: 'nodownload' } } }} onContextMenu={e => e.preventDefault()} controls />
            </Grid>
            <Grid item xs={7} pl={2}>
              <Grid container justifyContent='center' alignItems='center'>
                {selectedReasons.map((selectedReason, i) => (
                  <>
                    <Grid mb={2} key={i} item xs={selectedReasons.length === 1 ? 12 : 10}>
                      <TextField
                        label={selectedReason ? strings.deny_dialog_feedback(i + 1) : strings.deny_dialog_choose_feedback}
                        variant='outlined'
                        size='medium'
                        fullWidth
                        onChange={event => {
                          const value = event.target.value // do not remove
                          setSelectedReasons(p => {
                            p[i] = value
                            return [...p]
                          })
                        }}
                      />
                    </Grid>
                    {selectedReasons.length > 1 && (
                      <Grid item xs={2} mb={2}>
                        <IconButton
                          onClick={() =>
                            setSelectedReasons(p => {
                              p.splice(i, 1)
                              return [...p]
                            })
                          }>
                          <Delete />
                        </IconButton>
                      </Grid>
                    )}
                  </>
                ))}
                <Grid container item xs={12} justifyContent='center'>
                  {selectedReasons.length < 5 && (
                    <IconButton onClick={() => setSelectedReasons(p => [...p, ''])}>
                      <img src={Add} alt='.' />
                    </IconButton>
                  )}
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} textAlign='center'>
                    {strings.deny_dialog_max}
                  </Grid>
                  <Grid item xs={12} textAlign='center'>
                    {strings.deny_dialog_disclaimer}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Grid container justifyContent='flex-end'>
            <LoadingButton loading={loading} onClick={sendFeedback} variant='contained' disabled={!selectedReasons.join().length}>
              {strings.deny_dialog_send_feedback}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
