import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DangerousIcon from '@mui/icons-material/Dangerous'
import { CircularProgress, Grid, IconButton, TextField } from '@mui/material'
import React from 'react'
import { useStrings } from '../../assets/localization/strings'
import { extractUserMessageFromError } from '../../reusableUtils/Helpers'
import { ReactHookFormTextFieldStyle } from '../common/form/ReactHookFormTextField'

type Props = { coupon: string; setCoupon(_: string): void; state: Record<any, any> | Error | undefined; load(): any; couponFocused: React.MutableRefObject<boolean> }
export const CouponCodeView = ({ coupon, setCoupon, state, load, couponFocused }: Props) => {
  const strings = useStrings()
  const renderStatus = () => {
    if ((state == null && !couponFocused.current) || (state instanceof Error && (state as any).status != 422)) return null
    if (state == null)
      return (
        <IconButton>
          <CircularProgress size={15} />
        </IconButton>
      )
    if (state instanceof Error)
      return (
        <IconButton onClick={load}>
          <DangerousIcon fontSize='small' color='error' />
        </IconButton>
      )
    return (
      <IconButton>
        <CheckCircleIcon fontSize='small' color='success' />
      </IconButton>
    )
  }

  const error = (() => {
    const message = extractUserMessageFromError(state)
    if (!message) return
    try {
      const obj = JSON.parse(message)
      if ('key' in obj && 'args' in obj) {
        return strings[obj.key](...obj.args)
      }
      return undefined
    } catch {
      return undefined
    }
  })()
  return (
    <Grid container item xs={12}>
      <Grid item xs={coupon ? 11 : 12}>
        <TextField {...ReactHookFormTextFieldStyle} label={strings.discount} error={!!error} helperText={error} value={coupon} onChange={e => setCoupon(e.target.value)} />
      </Grid>
      {coupon && (
        <Grid item xs={1}>
          {renderStatus()}
        </Grid>
      )}
    </Grid>
  )
}
