import React from 'react'
import { Grid, Typography, Card, CardContent } from '@mui/material'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { useStrings } from '../../assets/localization/strings'
import ReactHookFormSelect from '../common/form/ReactHookFormSelect'
import countries from '../../reusableUtils/countries.json'
import ReactHookFormCheckbox from '../common/form/ReactHookFormCheckbox'
import { useWatch, useFormContext } from 'react-hook-form'
import { ICompanyForm } from './PaymentSettings'

const CompanyDetails = () => {
  const { control } = useFormContext<ICompanyForm>()
  const vatNumberCheck = useWatch({ control, name: 'vatNumberCheck' })

  const strings = useStrings()
  return (
    <Grid container>
      <Card variant='outlined' sx={{ pl: 2, pr: 2 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h5'>{strings.payment_modal_method_company_info_title}</Typography>
            </Grid>
            <Grid item xs={12} mt={2}>
              <ReactHookFormTextField name='companyName' label={strings.payment_modal_method_company_info_name} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ReactHookFormSelect name='country' label={strings.payment_modal_method_company_info_country} options={countries.map(c => ({ value: c.code, label: c.name }))} />
            </Grid>
            <Grid item xs={6} mt={2}>
              <ReactHookFormTextField name='zip' label={strings.payment_modal_method_company_info_zip} />
            </Grid>
            <Grid item xs={6} pl={2} mt={2}>
              <ReactHookFormTextField name='city' label={strings.payment_modal_method_company_info_city} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ReactHookFormTextField name='address' label={strings.payment_modal_method_company_info_address} />
            </Grid>
            <Grid item xs={12} mt={2}>
              <ReactHookFormCheckbox name='vatNumberCheck' label={strings.payment_modal_method_company_info_vatnumber_check} />
            </Grid>
            {vatNumberCheck && (
              <Grid item xs={12} mt={2}>
                <ReactHookFormTextField name='vatNumber' label={strings.payment_modal_method_company_info_vatnumber} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CompanyDetails
