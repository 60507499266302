import InfoIcon from '@mui/icons-material/Info'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, Grid, Hidden, Tooltip, Typography } from '@mui/material'
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeIbanElementChangeEvent } from '@stripe/stripe-js'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { analytics } from '../..'
import sepaIcon from '../../assets/icons/ic_piggybank.svg'
import sepaActiveIcon from '../../assets/icons/ic_piggybank_active.svg'
import paymentIcon from '../../assets/icons/ic_zahlungsinfo.svg'
import paymentActiveIcon from '../../assets/icons/ic_zahlungsinfo_active.svg'
import { useStrings } from '../../assets/localization/strings'
import { PaymentType } from '../../models/GeneralTypes'
import { check } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'
import styling from '../../style/campaign-details.module.css'
import CompanyDetails from './CompanyDetails'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, ref, string } from 'yup'
import vatRegEx from '../../vatRegEx.json'
import { FormProvider, useForm } from 'react-hook-form'
import ErrorReload from '../error/ErrorReload'
import { useSnackbar } from 'notistack'

enum ButtonText {
  saved = 'saved',
  save = 'save'
}

export interface ICompanyForm {
  companyName: string
  address: string
  country: string
  city: string
  zip: string
  vatNumber: string
  vatNumberCheck: boolean
  payment_method_id: string
}

const PaymentSettings = observer(() => {
  const stripe = useStripe()
  const elements = useElements()
  const strings = useStrings()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)
  const [valid, setValid] = useState(false)
  const [card, setCard] = useState<{ last4: string | null | undefined }>({ last4: undefined })
  const [paymentMethod, setPaymentMethod] = useState('creditcard')
  const [savingText, setSavingText] = useState(ButtonText.save)
  const [unauthorizedError, setUnauthorizedError] = useState(false)

  const schema = object().shape({
    companyName: string().required(strings.payment_modal_yup_company_name),
    address: string().required(strings.payment_modal_yup_company_address),
    country: string().required(strings.payment_modal_yup_company_country),
    city: string().required(strings.payment_modal_yup_company_city),
    zip: string().required(strings.payment_modal_yup_company_zip),
    vatNumber: string().test((_vatNumber, { createError, path, resolve }) => {
      const vatNumber = _vatNumber as string
      const country = resolve(ref('country')) as number
      const check = resolve(ref('vatNumberCheck')) as boolean
      if (!check) return true
      if (!vatNumber) return createError({ message: strings.payment_modal_yup_company_vat_number1, path })
      const regex = (vatRegEx as Record<string, string>)[country]
      if (!new RegExp(regex).test(vatNumber)) return createError({ message: strings.payment_modal_yup_company_vat_number2(country), path })
      return true
    })
  })
  const methods = useForm<ICompanyForm>({
    resolver: yupResolver(schema)
  })
  const { setValue, reset, getValues, formState } = methods
  const { selectedBrand } = rootStore.brandStore

  if (!selectedBrand) return <ErrorReload />

  useEffect(() => {
    const { companyName, address, city, zip, country, vatNumber } = selectedBrand
    setValue('vatNumberCheck', vatNumber ? true : false)
    setValue('companyName', companyName ?? '')
    setValue('address', address ?? '')
    setValue('city', city ?? '')
    setValue('zip', zip ?? '')
    setValue('country', country ?? '')
    setValue('vatNumber', vatNumber ?? '')
  }, [selectedBrand])

  async function getCard(callback: any) {
    try {
      const last4 = await rootStore.brandStore.getCard()
      setCard({ last4 })
    } catch (e) {
      if (e.response?.body.errorMessage === '[400] unauthorized') setUnauthorizedError(true)
      console.error(e)
    } finally {
      callback(false)
    }
  }

  useEffect(() => {
    if (!card.last4) getCard(setLoading)
  }, [])

  const handleCreditcardSave = async (event: Object) => {
    setError(false)
    setValid(false)

    // @ts-ignore
    if (!event.complete) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    if (!elements.getElement(CardElement)) {
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      // @ts-ignore
      card: elements.getElement(CardElement)
    })

    // @ts-ignore
    if (payload.error) {
      setError(true)
    } else {
      setValid(true)
    }
  }

  const handleIbanSave = async (event: StripeIbanElementChangeEvent) => {
    setValid(false)
    setError(false)

    if (!event.complete || updating || !rootStore.brandStore.selectedBrand) {
      return
    }

    if (!stripe || !elements) {
      return
    }

    const iban = elements.getElement(IbanElement)

    if (!iban) {
      return
    }

    const sourceData = {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
        name: rootStore.brandStore.selectedBrand.contactPerson,
        email: rootStore.brandStore.selectedBrand.contactEmail
      },
      mandate: {
        // Automatically send a mandate notification email to your customer
        // once the source is charged.
        notification_method: 'email'
      }
    }

    // @ts-ignore
    const payload = await stripe.createSource(iban, sourceData)

    if (payload.error) {
      setError(!!payload.error)
    } else {
      setValid(true)
    }
  }

  const updateCard = async () => {
    setError(false)
    setUpdating(true)
    analytics.logEvent('PaymentSettings_Speichern')

    if (!stripe || !elements) {
      return
    }

    if (!elements.getElement(CardElement)) {
      return
    }

    try {
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        // @ts-ignore
        card: elements.getElement(CardElement)
      })

      const last4 = await rootStore.brandStore.updateCard(payload.paymentMethod?.id, 'creditcard')

      setSavingText(ButtonText.saved)

      if (rootStore.brandStore.selectedBrand) rootStore.brandStore.selectedBrand.hasPaymentMethod = true

      setCard({ last4 })
    } catch (e) {
      setError(true)
    } finally {
      setUpdating(false)
    }
  }

  const updateSepa = async () => {
    setError(false)
    setUpdating(true)
    analytics.logEvent('PaymentSettings_Speichern')
    if (!stripe || !elements || !rootStore.brandStore.selectedBrand) {
      return
    }
    const iban = elements.getElement(IbanElement)
    if (!iban) {
      return
    }
    try {
      const { contactPerson, contactEmail, companyName, address, city, zip, country, vatNumber } = rootStore.brandStore.selectedBrand
      if (companyName) {
        const client_secret = await rootStore.brandStore.startPayment(PaymentType.Sepa, 'iban', address!, city!, zip!, country, companyName, vatNumber)
        check(client_secret, 'client_secret expected')
        const { setupIntent } = await stripe.confirmSepaDebitSetup(client_secret, {
          payment_method: { sepa_debit: iban, billing_details: { name: contactPerson, email: contactEmail } }
        })
        const last4 = setupIntent?.payment_method ? await rootStore.brandStore.updateCard(`${setupIntent.payment_method}`, 'sepa_debit') : undefined
        setSavingText(ButtonText.saved)
        if (rootStore.brandStore.selectedBrand) rootStore.brandStore.selectedBrand.hasPaymentMethod = true
        setCard({ last4 })
      }
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setUpdating(false)
    }
  }

  const renderNavigation = () => {
    return (
      <Grid className={styling.detailNav} style={{ marginBottom: '32px' }}>
        <span
          onClick={() => {
            setPaymentMethod('creditcard')
            setValid(false)
          }}
          className={styling.campaignNav}>
          <div className={paymentMethod === 'creditcard' ? styling.navTextLogoDiv : styling.navTextDisabled}>
            <Hidden only='xs'>
              <div className={paymentMethod === 'creditcard' ? 'link' : 'greylink'}>{strings.brand_payment_creditcard_title}</div>
            </Hidden>
            <img src={paymentMethod === 'creditcard' ? paymentActiveIcon : paymentIcon} alt='creditcard' />
          </div>
        </span>
        <span
          onClick={() => {
            setPaymentMethod('sepa')
            setValid(false)
          }}
          className={styling.campaignNav}>
          <div className={paymentMethod === 'sepa' ? styling.navTextLogoDiv : styling.navTextDisabled}>
            <Hidden only='xs'>
              <div className={paymentMethod === 'sepa' ? 'link' : 'greylink'}>{strings.brand_payment_sepa_title}</div>
            </Hidden>
            <img src={paymentMethod === 'sepa' ? sepaActiveIcon : sepaIcon} alt='sepa' />
          </div>
        </span>
      </Grid>
    )
  }

  const renderCreditCard = () => (
    <>
      <div style={{ marginTop: '16px' }}>
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '16px'
              }
            }
          }}
          onChange={handleCreditcardSave}
        />
      </div>
      {error && (
        <Typography variant='body1' style={{ color: '#f44336', textAlign: 'right' }}>
          {error}
        </Typography>
      )}
    </>
  )

  const renderSepa = () => (
    <>
      <div>
        <b>IBAN</b>
      </div>
      <div style={{ marginTop: '16px' }}>
        <IbanElement
          options={{
            supportedCountries: ['SEPA']
          }}
          onChange={handleIbanSave}
        />
      </div>
      {error && (
        <Typography variant='body1' style={{ color: '#f44336', textAlign: 'right' }}>
          {error}
        </Typography>
      )}
      <div>{strings.brand_payment_sepa_agreement}</div>
    </>
  )

  const formSubmitHandler = async ({ companyName, address, city, country, zip, vatNumber, vatNumberCheck }: ICompanyForm) => {
    setUpdating(true)

    try {
      const updatedBrand = {
        ...selectedBrand,
        companyName,
        address,
        city,
        country,
        zip,
        vatNumber: vatNumberCheck ? vatNumber : undefined
      }

      await rootStore.brandStore.updateBrand(updatedBrand)
      reset(getValues())
      enqueueSnackbar(strings.common_saved, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(strings.error, { variant: 'error' })
      console.error(e)
    } finally {
      setUpdating(false)
    }
  }

  if (loading)
    return (
      <div className='loadingFreebee'>
        <CircularProgress className='loadingFreebee' color='primary' />
      </div>
    )

  return (
    <Grid container mt={2}>
      <Grid item xs={12} md={6}>
        <Typography variant='h2'>{strings.brand_payment_credit_title} </Typography>
        <Typography variant='body1' mt={2}>
          <b>
            {strings.brand_payment_balance_current}{' '}
            {rootStore.brandStore.selectedBrand?.balance ? (rootStore.brandStore.selectedBrand.balance / 100).toLocaleString('de-DE', { maximumFractionDigits: 2 }) : '0,00'}€
          </b>
        </Typography>
        <Typography variant='h2' mt={4}>
          {strings.brand_payment_method_title}
        </Typography>
        {!unauthorizedError ? (
          <>
            <Typography variant='body1' mt={2}>
              {rootStore.brandStore.selectedBrand?.hasPaymentMethod
                ? card.last4
                  ? strings.brand_payment_method_saved(card.last4)
                  : strings.error
                : strings.brand_payment_method_saved_nothing}
            </Typography>
            <Typography variant='h4' mt={4}>
              {strings.brand_payment_method_choose}
            </Typography>
            {renderNavigation()}
            {paymentMethod === 'creditcard' ? renderCreditCard() : renderSepa()}
            {error && (
              <Typography variant='body1' style={{ color: '#f44336', textAlign: 'right' }}>
                {strings.brand_payment_method_choose_error}
              </Typography>
            )}
            <Grid item xs={12} style={{ textAlign: 'left' }} mb={2}>
              <LoadingButton loading={updating} disabled={!valid} onClick={paymentMethod === 'creditcard' ? updateCard : updateSepa} variant='contained'>
                {savingText == ButtonText.saved ? strings.saved : strings.brand_payment_save}
              </LoadingButton>
            </Grid>
          </>
        ) : (
          <Typography variant='body1'>{strings.brand_payment_method_no_role}</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(formSubmitHandler)} id='paymentForm'>
            <CompanyDetails />
            <Grid item xs={12} mt={2} sx={{ textAlign: 'right' }}>
              <LoadingButton loading={updating} type='submit' variant='contained' disabled={!formState.isDirty}>
                {strings.save}
              </LoadingButton>
            </Grid>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  )
})

export default PaymentSettings
