import React from 'react'
import { Grid, Typography } from '@mui/material'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { useStrings } from '../../assets/localization/strings'

const BrandContact = () => {
  const strings = useStrings()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.brand_creation_contact_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='firstname' label={strings.brand_creation_contact_firstname} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='surname' label={strings.brand_creation_contact_surname} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='contactEmail' label={strings.brand_creation_contact_email} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='contactPhone' label={strings.brand_creation_contact_phone} />
      </Grid>
    </Grid>
  )
}

export default BrandContact
