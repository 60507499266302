import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import ReactJoyride, { Step } from 'react-joyride'
import { useHistory, useParams } from 'react-router-dom'
import NoParticipants from '../../../assets/illustrations/im_no-participants.png'
import { useStrings } from '../../../assets/localization/strings'
import { CampaignInsightsUgc, UgcContentFinished } from '../../../requestModels'
import styles from '../../../style/contentCampaign/creator.module.css'
import { DashboardCard } from '../../dashboard/CampaignDashboard'
import ContentGrid from './ContentGrid'
import { UpgradeVideoDialog } from './UpgradeVideoDialog'
import rootStore from '../../../stores/rootStore'
import { theme } from '../../../theme'
import { upgradeVideoButtonClass } from './ContentView'
import { VideoType } from '../../../models/GeneralTypes'

const useStyles = makeStyles(() => ({
  cardContainer: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: '#f1f1f1',
    borderRadius: '10px',
    marginLeft: '0 !important',
    padding: '20px',
    marginBottom: '30px',
    marginTop: '10px',
    overflow: 'auto',
    color: '#4F4F4F',
    width: '100%'
  },
  icon: { width: '25px', marginRight: '10px' },
  moreContentButton: { background: '#a890fe', borderRadius: '10px', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)' },
  amountContainer: { fontSize: 'xx-large' }
}))

export const Content = ({ content, campaign }: { content: UgcContentFinished[]; campaign: CampaignInsightsUgc }) => {
  const { brandId, campaignId } = useParams<{ brandId: string; campaignId: string }>()
  const classes = useStyles()
  const history = useHistory()
  const strings = useStrings()
  const [showJoyride, setShowJoyride] = useState(false)

  const renderNoContent = () => (
    <Grid container direction='column' justifyContent='center' alignItems='center' className={styles.noParticipantsContainer}>
      <Grid item>
        <img className={styles.noParticipants} src={NoParticipants} alt='no participants' />
      </Grid>
      <Grid item>
        <h2>{strings.campaign_overview_content_empty_title}</h2>
      </Grid>
      <Grid item>
        <span>{strings.campaign_overview_content_empty_body}</span>
      </Grid>
    </Grid>
  )

  if (!content.length) return renderNoContent()

  const steps: Step[] = [
    {
      target: `.${upgradeVideoButtonClass}`,
      content: strings.upgrade_video_tutorial_joyride_content,
      disableBeacon: true,
      locale: { next: strings.understood }
    },
    { target: '.ee', content: 'dummy' }
  ]

  return (
    <Grid container>
      <ReactJoyride
        callback={e => {
          if (e.action != 'skip' && e.action != 'next') return
          rootStore.userStore.hideUpgradeVideo(campaignId)
          setShowJoyride(false)
        }}
        steps={steps}
        continuous
        hideCloseButton
        run={showJoyride}
        scrollToFirstStep
        styles={{ options: { zIndex: 10000, primaryColor: theme.palette.primary.main } }}
      />
      <UpgradeVideoDialog campaignId={campaignId} content={content} afterClose={() => setShowJoyride(true)} />
      {[VideoType.ad, VideoType.premium].includes(campaign.videoType) && (
        <Grid container item xs={12}>
          <DashboardCard
            title={strings.campaign_overview_content_duplicate_title}
            body={strings.campaign_overview_content_duplicate_body}
            button={strings.campaign_overview_content_duplicate_button}
            onClick={() => history.push(`/form/${brandId}/${campaignId}/duplicate`)}
          />
        </Grid>
      )}
      {/*<Grid item xs={12} md={8}>
                <Grid
                    container
                    style={{ height: '86%' }}
                    direction="column"
                    justifyContent="flex-end"
                >
                    <Grid container justifyContent="flex-end">
                        <NanoSelect
                            value={orderBy}
                            sx={{
                                borderRadius: '10px',
                            }}
                        >
                            <MenuItem value="rank">Bewertung</MenuItem>
                            <MenuItem value="time">Aktualität</MenuItem>
                        </NanoSelect>
                    </Grid>
                </Grid>
            </Grid>*/}
      {brandId && campaignId && (
        <div className={classes.cardContainer}>
          <ContentGrid influencers={content} urlParams={{ brandId, campaignId, step: 'content' }} setInfluencerDialog={() => {}} />
        </div>
      )}
    </Grid>
  )
}

export default Content
