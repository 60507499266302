import { Alert, Grid, Typography } from '@mui/material'
import React from 'react'
import { Strings, useStrings } from '../../assets/localization/strings'
import { VideoFeature } from '../../models/GeneralTypes'
import { Pricing, usePricingContext } from '../../reusableUtils/pricing'
import ReactHookFormRadioCardGroupFullImage from '../common/form/ReactHookFormRadioCardGroupFullImage'
import moment from 'moment'

// currentEdit && createdAt only used for upgrades
export const getVideoFeatureOptions = (strings: Strings, pricing: Pricing, currentEdit: VideoFeature[] = [], createdAt: number = Date.now()) => {
  return [
    {
      content: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/package_small_new.mp4',
      showControls: true,
      title: '',
      value: VideoFeature.small,
      description: strings.video_feature_small_description,
      price: pricing[VideoFeature.small],
      isPriceAdditional: true,
      addButton: true,
      disabled: currentEdit.includes(VideoFeature.small) || currentEdit.includes(VideoFeature.medium) || currentEdit.includes(VideoFeature.large)
    },
    {
      content: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/package_medium_new.mp4',
      showControls: true,
      title: '',
      value: currentEdit.includes(VideoFeature.small) ? VideoFeature.up_small_medium : VideoFeature.medium,
      description: strings.video_feature_medium_description,
      price: pricing[currentEdit.includes(VideoFeature.small) ? VideoFeature.up_small_medium : VideoFeature.medium],
      isPriceAdditional: true,
      addButton: true,
      topBadge: strings.most_popular,
      disabled: currentEdit.includes(VideoFeature.medium) || currentEdit.includes(VideoFeature.large)
    },
    // Show this only to older campaigns
    Date.now() > moment('2024-04-01').valueOf()
      ? undefined
      : {
          content: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/package_large_new.mp4',
          showControls: true,
          title: '',
          value: currentEdit.includes(VideoFeature.medium)
            ? VideoFeature.up_medium_large
            : currentEdit.includes(VideoFeature.small)
            ? VideoFeature.up_small_large
            : VideoFeature.large,
          description: strings.video_feature_large_description,
          price:
            pricing[
              currentEdit.includes(VideoFeature.medium) ? VideoFeature.up_medium_large : currentEdit.includes(VideoFeature.small) ? VideoFeature.up_small_large : VideoFeature.large
            ],
          isPriceAdditional: true,
          addButton: true,
          topBadge: strings.maximize_roas,
          disabled: currentEdit.includes(VideoFeature.large)
        }
  ]
}

const CampaignAdditionalFeatures = () => {
  const strings = useStrings()
  const pricing = usePricingContext()

  return (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <Typography variant='h5'>{strings.campaign_creation_video_features_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Alert severity='info'>{strings.campaign_creation_video_features_info}</Alert>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroupFullImage name='videoFeatures' options={getVideoFeatureOptions(strings, pricing).filterNotNull()} required={false} />
      </Grid>
    </Grid>
  )
}

export default CampaignAdditionalFeatures
