import React from 'react'
import { Grid, Typography, Button, TextField } from '@mui/material'
import ReactHookFormTextField from '../common/form/ReactHookFormTextField'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { IBrandForm } from './BrandForm'
import ReactHookFormSelect from '../common/form/ReactHookFormSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import { useStrings } from '../../assets/localization/strings'

export const BrandUsers = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext<IBrandForm>()
  const { fields, append, remove } = useFieldArray({ control, name: 'users', keyName: 'id' })
  const strings = useStrings()

  const roleOptions = [
    { value: 'Marketer', label: strings.brand_creation_users_role_marketer },
    { value: 'Admin', label: strings.brand_creation_users_role_admin },
    { value: 'Accountant', label: strings.brand_creation_users_role_accountant },
    { value: 'Analyst', label: strings.brand_creation_users_role_analyst }
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.brand_creation_users_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant='body2'>
          <div dangerouslySetInnerHTML={{ __html: strings.brand_creation_users_body }} />
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormTextField name='user' label={strings.brand_creation_users_email_label} />
        <Grid container justifyContent='flex-end'>
          <Button
            variant='text'
            onClick={() => {
              append({ id: `${watch('users').length}`, email: watch('user'), role: 'Marketer' })
              setValue('user', '')
            }}>
            {strings.brand_creation_users_invite}
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={2}>
        {fields.map(({ email }, i) => (
          <Grid container item spacing={1} alignItems='center' key={i}>
            <Grid item xs={12} sm={8}>
              <TextField
                label={strings.brand_creation_users_email_label}
                disabled
                size='small'
                value={email}
                fullWidth
                error={!!errors.users}
                helperText={errors?.users?.[i]?.email?.message || ''}
              />
            </Grid>
            <Grid item xs={11} sm={3}>
              <ReactHookFormSelect label={strings.brand_creation_users_role_label} name={`users.${i}.role`} options={roleOptions} />
            </Grid>
            <Grid container item xs={1} alignItems='center'>
              <DeleteIcon fontSize='small' onClick={() => remove(i)} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default BrandUsers
