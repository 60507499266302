import { Stack } from '@mui/material'
import Step from '@mui/material/Step'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import StepLabel from '@mui/material/StepLabel'
import Stepper, { StepperProps } from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { color } from '../../theme'

const ColorlibConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: color.stylinkPurple
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: color.stylinkPurple
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: color.stylinkPurple,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundColor: color.stylinkPurple
  })
}))

const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </ColorlibStepIconRoot>
  )
}

export interface IStep {
  label: string
  icon: React.ReactElement
}

interface IColoredStepperProps extends StepperProps {
  steps: IStep[]
  activeStep?: number
}

export const ColoredStepper = ({ steps, activeStep = 100, sx }: IColoredStepperProps) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} sx={sx}>
      {steps.map(step => (
        <Step key={step.label}>
          <StepLabel StepIconComponent={props => ColorlibStepIcon({ ...props, icon: step.icon })}>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
