import React from 'react'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useReadonly } from '../../../reusableUtils/useReadonly'

type IReactHookFormTextFieldProps = {
  label: string
  name: string
  type?: string
  multiline?: boolean
  maxRows?: number
  rows?: number
  showHelperError?: boolean
  focused?: React.MutableRefObject<boolean>
}
export const ReactHookFormTextFieldStyle = { fullWidth: true, autoComplete: 'off', size: 'small', variant: 'outlined' } as const
const ReactHookFormTextField = ({ label, name, type, multiline, maxRows, rows, showHelperError = true, focused }: IReactHookFormTextFieldProps) => {
  const isReadonly = useReadonly(name)
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          onFocus={() => {
            if (focused) focused.current = true
          }}
          {...field}
          onBlur={() => {
            field.onBlur()
            if (focused) focused.current = false
          }}
          label={label}
          error={error !== undefined}
          helperText={(showHelperError && error?.message) ?? ''}
          type={type}
          {...ReactHookFormTextFieldStyle}
          multiline={multiline}
          maxRows={maxRows}
          rows={rows}
          disabled={isReadonly}
        />
      )}
    />
  )
}

export default ReactHookFormTextField
