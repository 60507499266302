import React, { useState } from 'react'
import { Grid, Button, Dialog, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import BusinessIcon from '@mui/icons-material/Business'
import PeopleIcon from '@mui/icons-material/People'
import CloseIcon from '@mui/icons-material/Close'
import { useStrings } from '../../assets/localization/strings'

const useStyles = makeStyles(() => ({
  dialog: {
    minHeight: '340px',
    padding: '20px'
  },
  button: {
    minWidth: '210px'
  }
}))

const OnboardingFeedback = () => {
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  const classes = useStyles()
  const strings = useStrings()

  const AgencyModal = () => (
    <Dialog open={modalOpen}>
      <Grid container spacing={3} className={classes.dialog} alignItems='center'>
        <Grid item xs={12}>
          <Grid container justifyContent='flex-end'>
            <IconButton onClick={() => setModalOpen(false)} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <h1 style={{ margin: 0 }}>Agentur Info</h1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          Du kannst über unsere Plattform mehrere Kunden gleichzeitig verwalten. Erstelle im nächsten Schritt den ersten Kunden-Account. Sollte deine Agentur Rechnungsempfänger
          sein, kannst du einfach eure Daten bei der Rechnungsadresse eingeben.
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <button onClick={() => history.push('/createBrand?agency=true')}>Ersten Kunden anlegen</button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )

  return (
    <Grid container alignItems='center' justifyContent='center' spacing={3}>
      <AgencyModal />
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Button startIcon={<BusinessIcon />} onClick={() => history.push('/createBrand')} variant='outlined' className={classes.button} color='tertiary' id='REGISTER_BRAND_LINK'>
            {strings.onboardingPlattformForBrandsButton}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Button
            startIcon={<PeopleIcon />}
            onClick={() => history.push('/createBrand?agency=true')}
            variant='outlined'
            className={classes.button}
            color='tertiary'
            id='REGISTER_AGENCY_LINK'>
            {strings.onboardingPlattformForAgencyButton}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OnboardingFeedback
