import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Campaign } from '../../stores/campaignStore'
import rootStore from '../../stores/rootStore'
import { VideoType } from '../../models/GeneralTypes'

const CampaignDetailsMenu = ({ campaign }: { campaign: Campaign }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { brandId, campaignId, isActive } = campaign
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const onView = () => {
    const { selectedBrand } = rootStore.brandStore
    if (!selectedBrand) return
    history.push(`/form/${selectedBrand.brandId}/${campaignId}/view`)
  }

  // Open campaign form and fill out fields
  const onDuplicate = () => {
    const { selectedBrand } = rootStore.brandStore
    if (!selectedBrand) return
    history.push(`/form/${selectedBrand.brandId}/${campaignId}/duplicate`)
  }

  const onSetActive = async () => {
    if (campaignId && brandId) {
      await rootStore.campaignStore.setActive(brandId, campaignId)
      // TODO update in campaignStore instead of reload
      window.location.reload()
    }
  }

  const onEditCampaign = () => {
    const { selectedBrand } = rootStore.brandStore

    if (selectedBrand?.role === 'Marketer' || selectedBrand?.role === 'Admin') {
      history.push(`/form/${brandId}/${campaignId}/update`)
    } else {
      enqueueSnackbar('Du hast dazu keine Berechtigung', { variant: 'error' })
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <div style={{ textAlign: 'end' }}>
      <h4 aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon />
      </h4>
      <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {campaign.isVerified && [VideoType.ad, VideoType.premium].includes(campaign.videoType) ? (
          <MenuItem
            onClick={event => {
              onView()
              handleClose(event)
            }}>
            Einsehen
          </MenuItem>
        ) : (
          <MenuItem
            onClick={event => {
              onEditCampaign()
              handleClose(event)
            }}>
            Bearbeiten
          </MenuItem>
        )}
        {campaign.isVerified && (
          <MenuItem
            onClick={event => {
              onSetActive()
              handleClose(event)
            }}>
            {isActive ? 'Deaktivieren' : 'Aktivieren'}
          </MenuItem>
        )}
        {[VideoType.ad, VideoType.premium].includes(campaign.videoType) && (
          <MenuItem
            onClick={event => {
              onDuplicate()
              handleClose(event)
            }}>
            Duplizieren
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default CampaignDetailsMenu
