import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useStrings } from '../../assets/localization/strings'
import { AgeOptions, Gender, VideoType, VoiceOver } from '../../models/GeneralTypes'
import { theme } from '../../theme'
import ReactHookFormSelect, { IOption } from '../common/form/ReactHookFormSelect'
import { IUGCCampaign } from './usePersistedUGCForm'

const ageOptions: IOption[] = [
  { value: AgeOptions.age1, label: '16-18' },
  { value: AgeOptions.age2, label: '18-30' },
  { value: AgeOptions.age3, label: '30+' }
]
const CampaignAudience = () => {
  const genders = useStrings('campaignCreationGender')
  const languages = useStrings('campaign_creation_language_')
  const voiceOver = useStrings('campaign_creation_voice_over_')
  const strings = useStrings()
  const { control } = useFormContext<IUGCCampaign>()
  const videoType = useWatch({ control, name: 'videoType' })

  const genderOptions: IOption<Gender>[] = [
    { value: Gender.ANY, label: genders.any },
    { value: Gender.FEMALE, label: genders.female },
    { value: Gender.MALE, label: genders.male },
    { value: Gender.DIVERSE, label: genders.diverse }
  ]
  const voiceOverOptions: IOption<VoiceOver>[] = [
    { value: VoiceOver.any, label: voiceOver.any },
    { value: VoiceOver.male, label: voiceOver.male },
    { value: VoiceOver.female, label: voiceOver.female },
    { value: VoiceOver.computer, label: voiceOver.computer }
  ]
  const languageOptions: IOption<'de' | 'en'>[] = [
    { value: 'de', label: languages.german },
    { value: 'en', label: languages.english }
  ]

  const renderForShowcase = () => (
    <>
      <Grid item xs={12} sm={6} mt={2} pr={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormSelect name='voiceOver' label={voiceOver.label} options={voiceOverOptions} />
      </Grid>
    </>
  )

  const renderForDefault = () => (
    <>
      <Grid item xs={12} sm={6} mt={2} pr={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormSelect name='gender' label={strings.campaign_creation_gender_label} options={genderOptions} />
      </Grid>
    </>
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.campaign_creation_requirements}</Typography>
      </Grid>
      {/* <Grid item xs={12} sm={6} mt={2} pr={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormMultiSelect name='ageRanges' label={strings.campaign_creation_age_label} options={ageOptions} />
      </Grid> */}
      {videoType == VideoType.showcase ? renderForShowcase() : renderForDefault()}
      <Grid item xs={12} sm={6} mt={2} pl={1} sx={{ [theme.breakpoints.only('xs')]: { padding: '0' } }}>
        <ReactHookFormSelect name='language' label={strings.campaign_creation_language_label} options={languageOptions} />
      </Grid>
    </Grid>
  )
}

export default CampaignAudience
