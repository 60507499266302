import React, { useEffect } from 'react'

import { Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import ReactPlayer from 'react-player'
import xv_seconds from '../../assets/icons/ic_15_seconds.svg'
import xv_seconds_active from '../../assets/icons/ic_15_seconds_active.svg'
import xc_seconds from '../../assets/icons/ic_30_seconds.svg'
import xc_seconds_active from '../../assets/icons/ic_30_seconds_active.svg'
import lx_seconds from '../../assets/icons/ic_60_seconds.svg'
import lx_seconds_active from '../../assets/icons/ic_60_seconds_active.svg'
import mobilescreen from '../../assets/icons/ic_mobilescreen.svg'
import mobilescreen_active from '../../assets/icons/ic_mobilescreen_active.svg'
import rectangle from '../../assets/icons/ic_rectangle.svg'
import rectangle_43 from '../../assets/icons/ic_rectangle_43.svg'
import rectangle_43_active from '../../assets/icons/ic_rectangle_43_active.svg'
import rectangle_active from '../../assets/icons/ic_rectangle_active.svg'
import square from '../../assets/icons/ic_square.svg'
import square_active from '../../assets/icons/ic_square_active.svg'
import { useStrings } from '../../assets/localization/strings'
import { Locale, VideoDuration, VideoFormat, VideoType } from '../../models/GeneralTypes'
import { numberToEuro } from '../../reusableUtils/Helpers'
import { usePricingContext } from '../../reusableUtils/pricing'
import ReactHookFormRadioCardGroupFullImage from '../common/form/ReactHookFormRadioCardGroupFullImage'
import ReactHookFormRadioCardGroupIcon from '../common/form/ReactHookFormRadioCardGroupIcon'
import type { IUGCCampaign } from './usePersistedUGCForm'

// import video from 'sample.mp4'

// {
//   image: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/ad_video.mp4',
//   showControls: true,
//   title: 'Video Testimonial',
//   value: VideoType.testimonial,
//   description: 'Ideal für etwas.',
//   price: pricing[VideoType.testimonial],
//   isPriceAdditional: false
// }

const CampaignType = observer(() => {
  const pricing = usePricingContext()
  const strings = useStrings()
  const { control, setValue } = useFormContext<IUGCCampaign>()
  const videoType = useWatch({ control, name: 'videoType' })
  const videoDuration = useWatch({ control, name: 'videoDuration' })
  const locale = useWatch({ control, name: 'locale' })

  useEffect(() => {
    if (videoType == VideoType.honest) {
      setValue('scenes', [])
      setValue('service', undefined)
    }
    if (videoType == VideoType.showcase && videoDuration > VideoDuration.Sec30) setValue('videoDuration', VideoDuration.Sec30)
  }, [videoType, videoDuration])

  // TODO: 5900 is hard coded
  const videoDurationOptions = (() => {
    const base = pricing[videoType]
    const sec30 = videoType == VideoType.showcase ? pricing.sec30Showcase : pricing[VideoDuration.Sec30]
    const sec60 = videoType == VideoType.premium ? pricing.sec60Premium : pricing[VideoDuration.Sec60]
    return [
      {
        image: xv_seconds,
        imageActive: xv_seconds_active,
        value: VideoDuration.Sec15,
        title: strings.campaign_creation_video_duration_15,
        description: `${numberToEuro(pricing[VideoDuration.Sec15])}€`,
        topBadge: ''
        // descriptionSmall: strings.campaign_creation_video_duration_price(numberToEuro((base + pricing[VideoDuration.Sec15]) / VideoDuration.Sec15))
      },
      {
        image: xc_seconds,
        imageActive: xc_seconds_active,
        value: VideoDuration.Sec30,
        title: strings.campaign_creation_video_duration_30,
        description: `+${numberToEuro(sec30)}€`,
        topBadge: ''
        // descriptionSmall: strings.campaign_creation_video_duration_price(numberToEuro((base + sec30) / VideoDuration.Sec30))
      },
      videoType == VideoType.showcase
        ? null
        : {
            image: lx_seconds,
            imageActive: lx_seconds_active,
            value: VideoDuration.Sec60,
            title: strings.campaign_creation_video_duration_60,
            description: `+${numberToEuro(sec60)}€`,
            topBadge: ''
            // descriptionSmall: strings.campaign_creation_video_duration_price(numberToEuro((base + sec60) / VideoDuration.Sec60))
          }
    ].filterNotNull()
  })()
  const videoTypeOptions = [
    {
      content: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/ad_video.mp4',
      showControls: true,
      title: strings.campaign_creation_video_type_ad_title,
      value: VideoType.ad,
      description: strings.campaign_creation_video_type_ad_desc,
      price: pricing[VideoType.ad],
      isPriceAdditional: false
    },
    {
      content: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/premium_video.mp4',
      showControls: true,
      title: strings.campaign_creation_video_type_premium_title,
      value: VideoType.premium,
      description: strings.campaign_creation_video_type_premium_desc,
      price: pricing[VideoType.premium],
      isPriceAdditional: false
    }
  ].filterNotNull()

  const videoFormatOptions = [
    {
      image: mobilescreen,
      imageActive: mobilescreen_active,
      value: VideoFormat.vertical,
      description: strings.campaign_creation_video_format_vertical,
      title: '9:16',
      descriptionSmall: strings.campaign_creation_video_format_vertical_platform
    },
    {
      image: rectangle,
      imageActive: rectangle_active,
      value: VideoFormat.horizontal,
      description: strings.campaign_creation_video_format_horizontal,
      title: '16:9',
      descriptionSmall: strings.campaign_creation_video_format_horizontal_platform
    },
    { image: rectangle_43, imageActive: rectangle_43_active, value: VideoFormat.fullscreen, description: strings.campaign_creation_video_format_fullscreen, title: '4:3' }
  ]

  const selectedVideoTypeVideo = videoTypeOptions.find(t => t.value == videoType)?.content

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h5'>{strings.campaign_creation_video_type_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroupFullImage name='videoType' defaultValue={VideoType.ad} options={videoTypeOptions} videosOnBottom required />
      </Grid>

      {!!selectedVideoTypeVideo && (
        <>
          <Grid item xs={12} mt={2}>
            <Typography variant='h5'>{strings.campaign_creation_video_type_example_title}</Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <span className='videoPlayerWrapper roundedVideo'>
              <ReactPlayer url={selectedVideoTypeVideo} height='auto' width='auto' controls={true} />
            </span>
          </Grid>
        </>
      )}
      <Grid item xs={12} mt={2}>
        <Typography variant='h5'>{strings.campaign_creation_video_duration_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroupIcon name='videoDuration' defaultValue={15} options={videoDurationOptions} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant='h5'>{strings.campaign_creation_format_title}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <ReactHookFormRadioCardGroupIcon name='videoFormat' defaultValue={VideoFormat.vertical} options={videoFormatOptions} />
      </Grid>
    </Grid>
  )
})

export default CampaignType
