import { CircularProgress, Grid } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, useParams } from 'react-router-dom'
import { ExtractRouteParams } from 'react-router'
import SidebarDrawer from '../components/common/SidebarDrawer'
import rootStore from '../stores/rootStore'

const BrandIdCapturer = ({ setLoadingBrand, setLoadingUser }: { setLoadingBrand(_: boolean): void; setLoadingUser(_: boolean): void }) => {
  const { brandId } = useParams<{ brandId: string }>()

  const getBrands = async () => {
    const currentBrandId = rootStore.brandStore.selectedBrand?.brandId
    if (currentBrandId == null || currentBrandId != brandId) await rootStore.brandStore.getBrands(brandId)
    setLoadingBrand(false)
  }

  const getUser = async () => {
    if (!rootStore.userStore.authenticatedUser) await rootStore.userStore.getUser()
    setLoadingUser(false)
  }

  useEffect(() => {
    Promise.all([getBrands(), getUser()]).catch(() => rootStore.authStore.logout())
  }, [brandId])

  return null
}

type Props<Path extends string> = { path: Path; component: React.ComponentType<any>; exact?: boolean; showSidebar?: boolean }
const BrandRoute = observer(<Path extends string>({ path, component: Component, exact = false, showSidebar = true }: Props<Path>) => {
  const [loadingBrand, setLoadingBrand] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)

  if (!rootStore.authStore.isAuthenticated) return <Route render={() => <Redirect to='/login' />} />

  return (
    <Route
      path={path}
      exact={exact}
      render={props => (
        <>
          <BrandIdCapturer setLoadingBrand={setLoadingBrand} setLoadingUser={setLoadingUser} />
          {loadingBrand || loadingUser ? (
            <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '100vh' }}>
              <CircularProgress className='loadingFreebee' color='primary' />
            </Grid>
          ) : (
            <SidebarDrawer showSidebar={showSidebar}>
              {rootStore.brandStore.selectedBrand ? (
                path === '/' ? (
                  <Redirect from='/' to={`${rootStore.brandStore.selectedBrand.brandId}/dashboard`} />
                ) : (
                  <Component />
                )
              ) : path === '/createBrand' ? (
                <Component {...props.match.params} />
              ) : (
                <Redirect from='/' to='/createBrand' />
              )}
            </SidebarDrawer>
          )}
        </>
      )}
    />
  )
})

export default BrandRoute
