import GroupAddIcon from '@mui/icons-material/GroupAdd'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import { Box, Button, CircularProgress, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import agent from '../../agent'
import { useStrings } from '../../assets/localization/strings'
import { ContentCampaignLocale, UGCTodoType, VideoType } from '../../models/GeneralTypes'
import { delay, Empty, useCancellablePromise } from '../../reusableUtils/Helpers'
import { theme } from '../../theme'
import { ColoredStepper } from '../common/ColoredStepper'
import ReactHookFormCheckbox from '../common/form/ReactHookFormCheckbox'
import { MockView } from '../common/MockView'
import { IUGCCampaign } from './usePersistedUGCForm'
import ErrorIcon from '@mui/icons-material/Error'
import rootStore from '../../stores/rootStore'

type Timeline = Awaited<ReturnType<typeof agent.Brands.getTimeline>>

const CampaignDisclaimer = observer(() => {
  const { control } = useFormContext<IUGCCampaign>()
  const locale = useWatch({ control, name: 'locale' })
  const videoType = useWatch({ control, name: 'videoType' })
  const [timeline, setTimeline] = useState<Timeline>()
  const [loading, setLoading] = useState<Empty | Error>()
  const strings = useStrings()

  const launch = useCancellablePromise()
  const load = (delayDuration = 1000) =>
    launch(async ensureIsActive => {
      setLoading(undefined)
      await delay(delayDuration)
      ensureIsActive()
      try {
        const result = await agent.Brands.getTimeline({ locale: locale as unknown as ContentCampaignLocale, videoType })
        ensureIsActive()
        setTimeline(result)
        setLoading({})
      } catch (e) {
        ensureIsActive()
        setLoading(e)
      }
    })

  useEffect(() => {
    load()
  }, [videoType, locale])

  const steps =
    timeline?.map(({ type, endDate: _endDate }) => {
      const endDate = moment(_endDate).format('DD.MM.YYYY')
      switch (type) {
        case UGCTodoType.awaitFeedback:
          return { label: strings.campaignCreationTimelineApplications(endDate), icon: <GroupAddIcon /> }
        case UGCTodoType.awaitDelivery:
          return { label: strings.campaignCreationTimelineDelivery(endDate), icon: <LocalShippingIcon /> }
        case UGCTodoType.uploadContent:
          return { label: strings.campaignCreationTimelineVideos(endDate), icon: <VideoCameraFrontIcon /> }
        case UGCTodoType.awaitContentFeedback:
          return { label: strings.campaignCreationTimelineFeedback(endDate), icon: <PublishedWithChangesIcon /> }
        default:
          throw `Unexpected: ${type} in CampaignDisclaimer`
      }
    }) ?? []

  const uploadEndDate = timeline?.find(t => t.type == UGCTodoType.uploadContent)?.endDate

  return (
    <>
      <MockView name='CampaignDisclaimer'>
        <Stack direction='row'>
          <Button onClick={() => setLoading(new Error('test'))}>Set Error</Button>
          <Button onClick={() => setLoading(undefined)}>Set Load</Button>
          <Button onClick={() => setTimeline(undefined)}>Clear</Button>
          <Button onClick={() => load()}>Load</Button>
        </Stack>
      </MockView>
      <Grid container>
        {!!uploadEndDate && (
          <Grid item xs={12}>
            <Typography variant='h4'>
              {strings.campaign_creation_disclaimer_title} {moment(uploadEndDate).format('DD.MM.YYYY')}
            </Typography>
          </Grid>
        )}
        {timeline ? (
          <Box className='zstack'>
            <Grid item xs={12} mt={4}>
              <ColoredStepper steps={steps} activeStep={4} sx={{ [theme.breakpoints.only('xs')]: { width: '300px', overflowY: 'hidden' } }} />
            </Grid>
            {(loading instanceof Error || loading == null) && (
              <>
                {!!timeline && <Box sx={{ backgroundColor: 'gray', opacity: 0.2 }} width='100%' height='100%' />}
                {loading == null ? (
                  <CircularProgress sx={{ zIndex: 100 }} />
                ) : (
                  <IconButton onClick={() => load(0)} sx={{ zIndex: 100 }}>
                    <ErrorIcon />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        ) : (
          <Skeleton height='250px' width='100%' />
        )}
        <Grid item xs={12} mt={4}>
          <Typography variant='body2'>{strings.campaign_creation_disclaimer_body}</Typography>
        </Grid>
        <Grid item xs={12} mt={2} sx={{ [theme.breakpoints.down('sm')]: { marginBottom: '30px' } }}>
          <ReactHookFormCheckbox name='informationCheck' label={strings.campaign_creation_disclaimer_info_check_label} />
        </Grid>
        {videoType != VideoType.showcase && (
          <Grid item xs={12} mt={2} sx={{ [theme.breakpoints.down('sm')]: { marginBottom: '30px' } }}>
            <ReactHookFormCheckbox name='contactCheck' label={strings.campaign_creation_disclaimer_contact_check_label} />
          </Grid>
        )}
        {rootStore.userStore.authenticatedUser &&
          (rootStore.userStore.authenticatedUser.email.includes('getnano.io') || rootStore.userStore.authenticatedUser.email.includes('stylink.com')) && (
            <Grid item xs={12} mt={2} sx={{ [theme.breakpoints.down('sm')]: { marginBottom: '30px' } }}>
              <ReactHookFormCheckbox name='noPaymentCheck' label={strings.campaign_creation_disclaimer_no_payment_check} />
            </Grid>
          )}
      </Grid>
    </>
  )
})

export default CampaignDisclaimer
