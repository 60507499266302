import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { SnackbarKey, useSnackbar } from 'notistack'
import React, { useRef } from 'react'
import { useStrings } from '../../assets/localization/strings'
import { State } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'

export type Coupon = Awaited<ReturnType<typeof rootStore.shopStore.getCouponAfterAcceptingLastInfluencer>> & {}
export const CouponDialog = ({ open, setOpen, coupon }: State<'open', boolean> & { coupon: Coupon }) => {
  const [snackbar] = [useRef<SnackbarKey>()]
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const strings = useStrings()

  const copy = () => {
    if (!coupon) return
    navigator.clipboard.writeText(coupon.code)
    closeSnackbar(snackbar.current)
    snackbar.current = enqueueSnackbar(strings.copied, { variant: 'success' })
  }

  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogContent>
        <Stack alignItems='center' spacing={2}>
          <Typography fontWeight={800} variant='h6'>
            {strings.full_slots_coupon_dialog_title}
          </Typography>
          <Typography variant='body2' textAlign='center'>
            {strings.full_slots_coupon_dialog_body} <b>-{coupon.percentOff}%</b>
          </Typography>
          <Typography variant='body2' textAlign='center'>
            {strings.full_slots_coupon_dialog_expiration}
          </Typography>
          <Typography onClick={copy} sx={{ cursor: 'pointer' }} variant='h2' textAlign='center' color='primary'>
            {coupon.code}
          </Typography>
          <Typography variant='body2' textAlign='center'>
            {strings.full_slots_coupon_dialog_valid_until} {moment(coupon.expiresAt).format('DD.MM.YYYY HH:mm')}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mt: -5 }}
          variant='contained'
          onClick={() => {
            copy()
            setOpen(false)
          }}>
          Klar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
