import { createContext, useContext } from 'react'
import german from './de-AT'
import english from './en-US'

export type Strings = typeof german
export const Strings = (language: string): Strings => {
  if (language.startsWith('en')) return english as unknown as Strings
  return german
}

export const StringsContext = createContext(undefined as unknown as { strings: Strings; language: string; setLanguage(_: string): void })

export const useStrings: {
  <T extends string>(prefix: T): { [K in Extract<keyof Strings, `${T}${string}`> as K extends `${T}${infer R}` ? Uncapitalize<R> : never]: Strings[K] }
  (): Strings
} = (prefix?: string): any => {
  const { strings } = useContext(StringsContext)
  if (prefix)
    return Object.fromEntries(
      Object.keys(strings)
        .filter(k => k.startsWith(prefix))
        .map(k => [k[prefix.length].toLowerCase() + k.slice(prefix.length + 1), strings[k]])
    )
  return strings
}
