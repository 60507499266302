import { Box, Button, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { UgcContentFinished } from '../../../requestModels'
import { usePricing } from '../../../reusableUtils/pricing'
import styles from '../../../style/contentCampaign/content.module.css'
import { MockView } from '../../common/MockView'
import { ContentCampaignOverviewContext } from '../ContentCampaignOverview'
import ContentView, { getEditedLinks } from './ContentView'
import { RatingDialog } from './RatingDialog'

interface Props {
  influencers: UgcContentFinished[]
  urlParams: {
    campaignId: string
    brandId: string
    step: 'applications' | 'creators' | 'check' | 'content'
  }
  setInfluencerDialog(influencerId: string): void
}

export const ContentGrid = ({ influencers }: Props) => {
  const pricingObj = usePricing()
  const { campaign, setContent } = useContext(ContentCampaignOverviewContext)

  if (influencers?.length) {
    const influencer = influencers.map(inf => Object.assign(inf, getEditedLinks(inf))).find(inf => !inf.hasRating)
    return (
      <Box>
        <MockView name='Content Grid'>
          <Button
            onClick={() => {
              setContent(p => {
                for (const _ of [...Array(5)]) p?.push({ ...influencers[0], hasRating: false })
                return [...(p ?? [])]
              })
            }}>
            unset rating and multiply
          </Button>
        </MockView>
        <RatingDialog
          open={!!influencer}
          close={() => {
            if (!influencer) return
            influencer.hasRating = true
            setContent(p => [...(p ?? [])])
          }}
          campaignId={campaign.campaignId}
          influencerId={influencer?.influencerId}
          content={influencer?.content}
        />
        <Grid container className={styles.feedGridContainer} mt={3}>
          {influencers.map((influencer, i) => (
            <Grid key={influencer.influencerId + i} item className={styles.feedPostWrapper}>
              <ContentView pricingObj={pricingObj} influencer={influencer} />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }
  return <></>
}

export default ContentGrid
