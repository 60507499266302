import { makeAutoObservable } from 'mobx'
import TagManager from 'react-gtm-module'
import type { PaymentMethod, Role } from '../../../getnano-backend/utils/Types'
import agent, { Arg } from '../agent'
import { Service, PaymentType, VideoDuration, VideoFeature, VideoType, ProductType, VoiceOver } from '../models/GeneralTypes'
import Todo from '../models/Todo'
import { Brand, BrandSimplified, UgcStageTimings } from '../requestModels'
import { check, mock } from '../reusableUtils/Helpers'
import rootStore from './rootStore'

export type newBrand = Arg<typeof agent.Brands.create>
export type updateBrand = Arg<typeof agent.Brands.update>
class BrandStore {
  selectedBrand?: Brand
  todos?: Todo
  brands?: BrandSimplified[]
  ugcStageTimings: UgcStageTimings = {
    daysForApplying: 0,
    daysForDelivery: 0,
    daysFor1stUploadContent: 0,
    daysForContentFeedback: 0
  }

  constructor() {
    mock({
      setIsAgency: (v: boolean) => {
        if (this.selectedBrand) {
          this.selectedBrand.isAgency = v
        }
      }
    })
    makeAutoObservable(this)
  }

  get companyDetailsFinished() {
    if (!this.selectedBrand) return

    const { companyName, address, city, zip, country } = this.selectedBrand
    return !!(companyName && address && city && zip && country)
  }

  async createBrand(newBrand: newBrand): Promise<string> {
    const brand = await agent.Brands.create(newBrand)
    const { brandId } = brand
    this.selectedBrand = await this.getBrandDetails(brandId)
    this.todos = undefined // TODO: change to initial settings for history
    this.brands ? this.brands.push(brand) : [brand]

    try {
      const tagManagerArgs = {
        dataLayer: {
          userId: rootStore.userStore.authenticatedUser?.userId,
          brandId,
          event: 'brandCreated'
        }
      }

      TagManager.dataLayer(tagManagerArgs)
    } catch (e) {
      console.error(e)
    }

    return brandId
  }

  async sepaIntent(payload: { contactPerson: string; contactEmail: string; brandName: string; brandId?: string }) {
    // return agent.Brands.sepaIntent(payload)
  }

  async getBrands(brandId: string | undefined) {
    const result = await agent.Brands.getBrands({})
    console.log(result)
    this.brands = result
    if (brandId && this.brands && this.brands.find(b => b.brandId === brandId)) {
      await this.setSelectedBrand(brandId)
    } else if (result[0]?.brandId) {
      await this.setSelectedBrand(result[0].brandId)
    }
    await this.getUGCStageTimings()
  }

  getBrandDetails(brandId?: string): Promise<Brand> {
    if (brandId) return agent.Brands.get({ brandId })
    return agent.Brands.get({ brandId: this.selectedBrand?.brandId! })
  }

  async updateBrand(brand: updateBrand) {
    await agent.Brands.update(brand)
    this.selectedBrand = await this.getBrandDetails(brand.brandId)
    //  TODO: für Chronik wichtig?
  }

  getAllUsers() {
    return agent.Brands.getAllUsers({ brandId: this.selectedBrand?.brandId! })
  }

  removeUser(email: string) {
    return agent.Brands.removeUser({
      brandId: this.selectedBrand?.brandId!,
      emailToBeRemoved: email
    })
  }

  addUserToBrand(emailToAdd: string, role: Role) {
    const { brandId } = this.selectedBrand!

    return agent.Brands.addUserToBrand({ brandId: brandId!, emailToAdd, role })
  }

  updateBrandOfUser(emailToUpdate: string, role: Role) {
    const { brandId } = this.selectedBrand!

    return agent.Brands.updateBrandOfUser({ brandId: brandId!, emailToUpdate, role })
  }

  getInvoices() {
    if (this.selectedBrand?.brandId) return agent.Brands.getInvoices({ brandId: this.selectedBrand?.brandId })
    return []
  }

  async getCard() {
    if (this.selectedBrand?.brandId) return await agent.Brands.getCard({ brandId: this.selectedBrand?.brandId })
  }

  updateCard(payment_id?: string, type?: 'creditcard' | 'sepa_debit') {
    if (this.todos) {
      this.todos.addPaymentMethod = false
    }
    if (this.selectedBrand?.brandId && payment_id && type) return agent.Brands.updateCard({ brandId: this.selectedBrand?.brandId, payment_id, type })
  }

  reset() {
    this.selectedBrand = undefined
    this.brands = undefined
    this.todos = undefined
  }

  async setSelectedBrand(brandId?: string) {
    console.log(brandId)
    if (brandId) {
      const brand = await agent.Brands.get({ brandId: brandId })
      this.selectedBrand = { ...brand }
      console.log(brand)
      // TODO
      // try {
      //   const td = await agent.Brands.getTodos({ brandId })
      //   this.todos = td
      //   console.log(td)
      // } catch (e) {
      //   this.todos = undefined
      // }
    }
  }

  finishCodesTodo(campaignId: string) {
    try {
      if (this.todos) {
        this.todos.codes = [...this.todos.codes.filter(c => c.campaignId !== campaignId)]
      }
    } catch (e) {
      console.error(e)
    }
  }

  finishApplicantsTodo(campaignId: string) {
    try {
      if (this.todos) {
        const index = this.todos.applicants.findIndex(a => a.campaignId === campaignId)
        const copy = { ...this.todos.applicants[index] }
        copy.value--

        if (copy.value === 0) {
          this.todos.applicants.splice(index, 1)
        } else {
          this.todos.applicants[index] = copy
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  finishDeliveryTodo(campaignId: string) {
    try {
      if (this.todos) {
        const index = this.todos.deliveries.findIndex(a => a.campaignId === campaignId)
        const copy = { ...this.todos.deliveries[index] }
        copy.value--

        if (copy.value === 0) {
          this.todos.deliveries.splice(index, 1)
        } else {
          this.todos.deliveries[index] = copy
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  finishCreateFirstCampaignTodo() {
    if (this.todos) {
      this.todos.createFirstCampaign = false
    }
  }

  finishCreateFollowCampaignTodo() {
    if (this.todos) {
      this.todos.createFollowCampaign = false
    }
  }

  async startPayment(
    paymentType: PaymentType,
    paymentMethodId: string,
    address: string,
    city: string,
    zip: string,
    country: string,
    companyName: string,
    vatNumber: string | undefined
  ) {
    check(this.selectedBrand?.brandId, 'Brand not selected')
    const result = await agent.Brands.startPayment({
      brandId: this.selectedBrand.brandId,
      paymentType: paymentType as string as PaymentMethod,
      paymentMethodId,
      address,
      city,
      zip,
      country,
      companyName,
      vatNumber
    })

    this.selectedBrand = await rootStore.brandStore.getBrandDetails()
    return result
  }

  async previewInvoice(
    numberOfVideos: number,
    videoType: VideoType | undefined,
    videoFeatures: VideoFeature[],
    videoDuration: VideoDuration | undefined,
    coupon: string,
    service: Service | undefined,
    voiceOver: VoiceOver | undefined,
    paymentData: any,
    hooks: string[],
    ctas: string[]
  ) {
    check(this.selectedBrand, 'Missing selected brand')
    return await agent.Brands.previewInvoice({
      brandId: this.selectedBrand.brandId,
      numberOfSlots: numberOfVideos,
      paymentData,
      videoDuration,
      videoFeatures,
      videoType,
      voiceOver,
      coupon,
      service,
      hooks,
      ctas
    })
  }

  async attachPaymentMethod(paymentMethodId: string) {
    if (this.selectedBrand) {
      const { brandId } = this.selectedBrand
      if (brandId) {
        await agent.Brands.attachPaymentMethod({ brandId, paymentMethodId })
      }
    }
  }

  async getUGCStageTimings() {
    this.ugcStageTimings = await agent.Brands.getUGCStageTimings({})
  }
}

export default BrandStore
