import { makeAutoObservable } from 'mobx'
import agent from '../agent'
import { RootStore } from './rootStore'

class ShopStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getInfluencers(brandId: string, campaignId: string) {
    return agent.Shop.getApplied({ brandId, campaignId })
  }

  acceptInfluencer(campaignId: string, influencerId: string, brandId: string) {
    this.rootStore.brandStore.finishApplicantsTodo(campaignId)
    return agent.Shop.acceptInfluencer({ brandId, campaignId, influencerId })
  }

  buyMoreSlots = agent.Shop.buyMoreSlots
  getCouponAfterAcceptingLastInfluencer = agent.Shop.getCouponAfterAcceptingLastInfluencer

  removeInfluencer(brandId: string, campaignId: string, influencerId: string, readd: boolean) {
    return agent.Shop.removeInfluencer({ brandId, campaignId, influencerId, readd })
  }

  denyUgcInfluencer(campaignId: string, influencerId: string, brandId: string, denyText: string) {
    return agent.Shop.denyUgcInfluencer({ campaignId, influencerId, brandId, denyText })
  }
}

export default ShopStore
