import MinimizeIcon from '@mui/icons-material/Remove'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowDown from '../../assets/icons/ic_arrow_down.svg'
import ArrowRight from '../../assets/icons/ic_arrow_right.svg'
import ApplicantIcon from '../../assets/icons/ic_bewerber_active.svg'
import CodesIcon from '../../assets/icons/ic_codes_active.svg'
import ButtonIcon from '../../assets/icons/ic_popupButton.svg'
import ButtonIconActive from '../../assets/icons/ic_popupButton_active.svg'
import SendIcon from '../../assets/icons/ic_send_active.svg'
import ThumbsUpIcon from '../../assets/icons/ic_thumbs_up_active.svg'
import TodosIcon from '../../assets/icons/ic_todos.svg'
import TodosActiveIcon from '../../assets/icons/ic_todos_active.svg'
import TodosInactiveIcon from '../../assets/icons/ic_todos_inactive.svg'
import NoTodos from '../../assets/illustrations/illu_no_todos.png'
import Todo from '../../models/Todo'
import rootStore from '../../stores/rootStore'

const useStyles = makeStyles({
  container: {
    position: 'sticky',
    bottom: '16px',
    paddingRight: '16px',
    pointerEvents: 'none',
    zIndex: 1500
  },
  popupButton: {
    backgroundColor: '#ebebeb',
    margin: '0 !important',
    pointerEvents: 'auto'
  },
  popupButtonActive: {
    backgroundColor: '#a890fe',
    margin: '0 !important',
    pointerEvents: 'auto',
    paddingTop: '3px'
  },
  popupList: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '5px 5px 15px 2px rgba(0,0,0,0.11)',
    width: '350px'
  },
  taskBar: {
    backgroundColor: '#f7f7f7',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    height: '60px',
    pointerEvents: 'auto'
  },
  content: {
    height: '450px',
    pointerEvents: 'auto',
    paddingTop: '10px',
    overflow: 'hidden'
  },
  buttonElement: {
    paddingTop: '8px'
  },
  todo: {
    textTransform: 'none',
    width: '330px',
    minHeight: '40px',
    borderRadius: '10px',
    paddingTop: '5px',
    boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.07)',
    marginBottom: '25px',
    backgroundColor: 'white',
    paddingBottom: '10px',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },
  subTodo: {
    textTransform: 'none',
    width: '300px',
    minHeight: '40px',
    borderRadius: '10px',
    marginTop: '10px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#a890fe !important',
      color: 'black !important'
    }
  },
  todoBig: {
    textTransform: 'none',
    width: '330px',
    height: '100px',
    boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.07)',
    marginBottom: '25px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#a890fe !important',
      color: 'black !important'
    }
  },
  history: {
    textTransform: 'none',
    width: '350px',
    boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.07)',
    minHeight: '40px',
    marginBottom: '10px'
  },
  headerIcon: {
    paddingRight: '10px'
  },
  footer: {
    backgroundColor: '#F8F8F8',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    pointerEvents: 'auto'
  },
  scrollableContainer: {
    maxHeight: '500px',
    overflow: 'auto'
  },
  iconSmall: {
    height: '20px',
    width: '20px'
  },
  iconBig: {
    height: '30px',
    width: '30px'
  },
  arrow: {
    height: '15px',
    width: '15px'
  },
  buttonNumber: {
    display: 'inline',
    paddingRight: '5px'
  },
  popupIconActive: {
    paddingTop: '7px'
  },
  popupIcon: {
    padding: 0
  },
  buttonNumberContainer: {
    paddingTop: '8px'
  }
})

interface Sub {
  icon: string
  link: string
  text: string
}

const TodoPopup = observer(() => {
  const history = useHistory()
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [todosActive, setTodosActive] = useState(true)
  const [applicantsOpen, setApplicantsOpen] = useState(false)
  const [sendOpen, setSendOpen] = useState(false)
  const [codesOpen, setCodesOpen] = useState(false)
  // const [budgetOpen, setBudgetOpen] = useState(false)
  const { todos } = rootStore.brandStore

  if (!todos) return <></>

  const { totalTodos } = todos

  let brandId = ''
  if (rootStore.brandStore.selectedBrand && rootStore.brandStore.selectedBrand.brandId) {
    brandId = rootStore.brandStore.selectedBrand.brandId
  } else {
    return <></>
  }

  const Closed = () => (
    <button onClick={() => setIsOpen(true)} className={totalTodos > 0 ? classes.popupButtonActive : classes.popupButton}>
      <Grid container>
        {totalTodos > 0 && (
          <Grid item xs={4} className={classes.buttonNumberContainer} alignItems='center'>
            <p className={classes.buttonNumber}>{totalTodos}</p>
          </Grid>
        )}
        <Grid item xs={totalTodos > 0 ? 8 : 12}>
          <img src={totalTodos > 0 ? ButtonIconActive : ButtonIcon} className={totalTodos > 0 ? classes.popupIconActive : classes.popupIcon} alt='totalTodos' />
        </Grid>
      </Grid>
    </button>
  )

  const SubButton = (subButton: Sub) => {
    const { icon, link, text } = subButton
    return (
      <div
        onClick={() => {
          history.push(link)
          window.location.reload()
        }}
        style={{ userSelect: 'none' }}>
        <Grid container justifyContent='center' className={classes.subTodo}>
          <Grid item xs={2}>
            <Grid container justifyContent='center' className={classes.buttonElement}>
              <img src={icon} className={classes.iconSmall} alt='todo' />
            </Grid>
          </Grid>
          <Grid item xs={8} className={classes.buttonElement}>
            <Grid container justifyContent='center' style={{ textAlign: 'center' }}>
              <b>{text}</b>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.buttonElement}>
            <Grid container justifyContent='center'>
              <img className={classes.arrow} src={ArrowRight} alt='arrow-right' />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TodoButton = (icon: string, isOpen: boolean, handleChange: () => void, text: string, subButtons?: Sub[]) => (
    <div style={{ userSelect: 'none' }}>
      <Grid container justifyContent='center' className={classes.todo}>
        <Grid item xs={12} onClick={handleChange}>
          <Grid container>
            <Grid item xs={2}>
              <Grid container justifyContent='center'>
                <img src={icon} className={classes.iconBig} alt='iconBig' />
              </Grid>
            </Grid>
            <Grid item xs={8} className={classes.buttonElement}>
              <Grid container justifyContent='center' style={{ textAlign: 'center' }}>
                <b>{text}</b>
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.buttonElement}>
              <Grid container justifyContent='center'>
                <img className={classes.arrow} src={isOpen ? ArrowDown : ArrowRight} alt='arrow' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {subButtons && isOpen ? (
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              {subButtons.map(subButton => (
                <Grid item key={subButton.text}>
                  {SubButton(subButton)}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  )

  const TodoButtons = (todos: Todo) => {
    const {
      applicants,
      deliveries,
      codes,
      createFirstCampaign,
      createFollowCampaign,
      addPaymentMethod
      // budget,
    } = todos

    console.log(todos)

    let numberOfApplicants = applicants.length ? applicants[0].value : 0
    let numberOfDeliveries = deliveries.length ? deliveries[0].value : 0

    let subApplicants: Sub[] = []
    let subDeliveries: Sub[] = []
    let subCodes: Sub[] = []
    let subBudgets: Sub[] = []

    if (applicants.length > 1) {
      applicants.map(applicant => {
        numberOfApplicants += applicant.value
        const subApplicant = {
          icon: ApplicantIcon,
          link: `/${brandId}/${applicant.campaignId}/applicants`,
          text: `${applicant.value} bei ${applicant.title} annehmen`
        }
        subApplicants.push(subApplicant)
      })
    }
    console.log(subApplicants)

    if (deliveries.length > 1) {
      numberOfDeliveries = 0
      deliveries.map(delivery => {
        numberOfDeliveries += delivery.value
        const subDelivery = {
          icon: SendIcon,
          link: `/${brandId}/${delivery.campaignId}/participants`,
          text: `${delivery.value} bei ${delivery.title} versenden`
        }
        subDeliveries.push(subDelivery)
      })
    }

    if (codes.length > 1) {
      codes.map(code => {
        const subCode = {
          icon: CodesIcon,
          link: `/${brandId}/${code.campaignId}/codes`,
          text: `Bei ${code.title} hinzufügen`
        }
        subCodes.push(subCode)
      })
    }

    // if (budget) {
    //     budget.map((bud) => {
    //         const subBudget = {
    //             icon: ThumbsUpIcon,
    //             link: `/${brandId}/${bud.campaignId}/update`,
    //             text: `Bei ${bud.title} hinzufügen`,
    //         }
    //         subBudgets.push(subBudget)
    //     })
    // }

    console.log(applicants.length)

    return (
      <>
        {applicants.length > 0 &&
          TodoButton(
            ApplicantIcon,
            applicantsOpen,
            applicants.length === 1
              ? () => {
                  history.push(`/${brandId}/${applicants[0].campaignId}/applicants`)
                  window.location.reload()
                }
              : () => setApplicantsOpen(!applicantsOpen),
            numberOfApplicants === 1 ? '1 Neuen Bewerber annehmen' : `${numberOfApplicants} Neue Bewerber annehmen`,
            applicants.length !== 1 ? subApplicants : undefined
          )}

        {deliveries.length > 0 &&
          TodoButton(
            SendIcon,
            sendOpen,
            deliveries.length === 1
              ? () => {
                  history.push(`/${brandId}/${deliveries[0].campaignId}/participants`)
                  window.location.reload()
                }
              : () => setSendOpen(!sendOpen),
            numberOfDeliveries === 1 ? '1 Paket versenden' : `${numberOfDeliveries} Pakete versenden`,
            deliveries.length !== 1 ? subDeliveries : undefined
          )}

        {codes.length > 0 &&
          TodoButton(
            CodesIcon,
            codesOpen,
            codes.length === 1
              ? () => {
                  history.push(`/${brandId}/${codes[0].campaignId}/codes`)
                  window.location.reload()
                }
              : () => setCodesOpen(!codesOpen),
            'Mehr Codes hinzufügen',
            codes.length !== 1 ? subCodes : undefined
          )}

        {(createFirstCampaign || createFollowCampaign) &&
          TodoButton(
            ThumbsUpIcon,
            false,
            () => history.push(`/${rootStore.brandStore.selectedBrand?.brandId}/createCampaign`),
            createFirstCampaign ? 'Erste Kampagne erstellen' : 'Follow Up Kampagne erstellen',
            undefined
          )}

        {addPaymentMethod &&
          TodoButton(
            ThumbsUpIcon,
            false,
            () => {
              history.push(`/${brandId}/settings/payment`)
              window.location.reload()
            },
            'Zahlungsmittel hinzufügen',
            undefined
          )}

        {/* {budget &&
                    TodoButton(
                        ThumbsUpIcon,
                        budgetOpen,
                        budget.length === 1
                            ? () => {
                                  history.push(
                                      `/${brandId}/${budget[0].campaignId}/update`
                                  )
                                  window.window.location.reload()
                              }
                            : () => setBudgetOpen(!budgetOpen),
                        budget.length === 1
                            ? '1x Budget erhöhen'
                            : `${budget.length} Budget Erhöhen`,
                        budget.length !== 1 ? subBudgets : undefined
                    )} */}
      </>
    )
  }

  /*
    const historyButtons = (historyItem: Todo[]) => (
        historyItem.map((historyItem) => (
            <Button 
                className={historyItem.text.endsWith('angenommen') ? classes.todoBig : classes.todo} 
                startIcon={<img src={historyItem.icon} style={{width: '20px', height: '20px'}} />} 
                onClick={() => history.push(historyItem.link)}
            >
              <Grid container style={{height: 'inherit'}} alignItems="center">
                  <Grid item xs={12}>
                      {historyItem.text}
                  </Grid>
                  <Grid item xs={12}>
                      {historyItem.text.endsWith('angenommen') ?
                          <p style={{color: 'grey'}}>
                              Nach 10 Tagen ohne Feedback werden Bewerber automatisch angenommen.
                          </p>
                      : <></>}
                  </Grid>
              </Grid>
            </Button>
            )
        )
    )
    */

  const TodoHeader = () => (
    <Grid item xs={12}>
      <Grid container justifyContent='flex-end' style={{ margin: '0 !important', height: '2px' }}>
        <IconButton onClick={() => setIsOpen(false)}>
          <MinimizeIcon />
        </IconButton>
      </Grid>
      <Grid container justifyContent='center'>
        <img className={classes.headerIcon} src={TodosIcon} alt='todos' />
        <h2>Deine Todos</h2>
      </Grid>
    </Grid>
  )

  const Todos = (todos: Todo) => (
    <Grid container justifyContent='center' className={classes.content}>
      <TodoHeader />
      <Grid item xs={12}>
        <Grid container className={classes.scrollableContainer} justifyContent='center'>
          {TodoButtons(todos)}
        </Grid>
      </Grid>
    </Grid>
  )

  /*
    const History = () => (
      <Grid container justifyContent="center" className={classes.content}>
          <Grid item>
              <Grid container justifyContent="center">
                  <img className={classes.headerIcon} src={HistoryIcon} />
                  <h2>Deine Chronik</h2>
              </Grid>
          </ Grid>
          <Grid item xs={12}>
              <Grid container className={classes.scrollableContainer} justifyContent="center">
                  {historyButtons(mockupHistory)}
              </Grid>
          </Grid>
      </Grid>
    )
    */

  const EmptyTodos = () => (
    <Grid container justifyContent='center' className={classes.content}>
      <TodoHeader />
      <Grid container justifyContent='center'>
        <img src={NoTodos} style={{ width: '256px', height: '174px' }} alt='noTodos' />
        <Grid item xs={12}>
          <Grid container justifyContent='center' style={{ marginTop: '-70px' }}>
            <b>Alles erledigt!</b>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container justifyContent='flex-end' className={classes.container}>
      {isOpen ? (
        <Grid container justifyContent='flex-end' direction='column' className={classes.popupList}>
          <Grid item>{todos && (totalTodos === 0 ? <EmptyTodos /> : todosActive && Todos(todos)) /*<History />*/}</Grid>
          <Grid item>
            <Grid container justifyContent='center' className={classes.footer}>
              <Grid item xs={5}>
                <Grid container justifyContent='center'>
                  <IconButton onClick={() => setTodosActive(true)}>
                    {todosActive ? <img src={TodosActiveIcon} alt='activeTodos' /> : <img src={TodosInactiveIcon} alt='inactiveTodos' />}
                  </IconButton>
                </Grid>
              </Grid>
              {/*
                                <Grid item xs={5}>
                                    <Grid container justifyContent="center">
                                        <IconButton onClick={() => setTodosActive(false)}>
                                        {todosActive ? <img src={HistoryInactiveIcon} /> : <img src={HistoryActiveIcon} />}
                                    </IconButton>
                                    </Grid>
                                </Grid>
                                */}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Closed />
      )}
    </Grid>
  )
})

export default TodoPopup
