import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useStrings } from '../../assets/localization/strings'
import { State } from '../../reusableUtils/Helpers'
import { ContentCampaignOverviewContext } from './ContentCampaignOverview'

export const BookMoreDialog = ({ open, setOpen }: State<'open', boolean>) => {
  const strings = useStrings()
  const { campaign } = useContext(ContentCampaignOverviewContext)

  return (
    <Dialog open={open} maxWidth='xs'>
      <DialogContent>
        <Stack alignItems='center' spacing={2}>
          <Typography fontWeight={800} variant='h6'>
            {strings.full_slots_normal_dialog_title}
          </Typography>
          <Typography variant='body2' textAlign='center'>
            {campaign.applied ? strings.full_slots_normal_dialog_body : strings.full_slots_coupon_dialog_body_empty}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mt: -5 }} variant='contained' onClick={() => setOpen(false)}>
          {strings.clear}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
