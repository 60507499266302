import { createContext, useContext } from 'react'

type Props = { mode: 'none' | 'all' | 'some'; names?: string[] }
export const ReadonlyContext = createContext<Props>({ mode: 'none' })
export const useReadonly = (name: string) => {
  const { mode, names = [] } = useContext(ReadonlyContext)
  if (mode == 'none') return false
  const includes = names.includes(name)
  return mode == 'all' ? !includes : includes
}
