import { Dialog, DialogActions, DialogContent, Grid, Hidden } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'
import close from '../../assets/icons/ic_close.svg'
import rootStore from '../../stores/rootStore'
import styles from '../../style/pricingDialog.module.css'
interface Props {
  showModal: boolean
  onCloseModal: () => void
}

const InformationDialog = observer((props: Props) => {
  const hideInformation = async () => {
    if (rootStore.userStore.authenticatedUser?.showInformation) {
      try {
        await rootStore.userStore.hideInformation()
        if (rootStore.userStore.authenticatedUser) rootStore.userStore.authenticatedUser.showInformation = false
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onCloseModal = async () => {
    props.onCloseModal()
    await hideInformation()
  }

  return (
    <div>
      <Dialog open={props.showModal} onClose={onCloseModal} maxWidth='md' className={styles.influencerModal} hideBackdrop={true} disableEscapeKeyDown={true}>
        <img src={close} className={styles.closeIcon} alt='close' onClick={onCloseModal} />

        <DialogContent>
          <Grid container justifyContent='center'>
            <Grid item>
              <h2> Wichtige Änderungen</h2>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                paddingTop: '3em'
              }}>
              <span style={{ textAlign: 'center' }}>
                Ab dem 12. März werden Bewerber bei manuellen Kampagnen nach 7 Tagen automatisch angenommen. <br></br>
                Bis dato wurden Bewerber nach 10 Tagen automatisch abgelehnt. <br></br>
                <br></br>
                Mit dieser Veränderung wollen wir die Interaktion auf unserer Plattform erhöhen und gehen damit auf den Wunsch vieler unserer Nano Influencer ein.
              </span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent='center' alignItems='baseline' className={styles.dialog} spacing={2}>
            <Grid item xs={6} md={2} className={styles.buttonsMobile}>
              <button className={styles.acceptButton} onClick={onCloseModal}>
                Fertig
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default InformationDialog
