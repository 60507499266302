import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Button, ButtonProps, CircularProgress, styled, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React from 'react'
import { useStrings } from '../../../assets/localization/strings'
import { color } from '../../../theme'

const StyledButton = (isLate: boolean) =>
  styled(Button)<ButtonProps>(() => ({
    color: isLate ? red[500] : 'black',
    backgroundColor: 'white',
    borderColor: isLate ? red[500] : 'black',
    '&:hover': {
      backgroundColor: 'whitesmoke',
      borderColor: isLate ? red[700] : 'gray'
    }
  }))
export const ShippingButton = ({ onClick, isLate, loading, className }: { onClick?(): void; isLate: boolean; loading: boolean; className?: string }) => {
  const Button = StyledButton(isLate)
  const strings = useStrings()
  return (
    <Button variant='outlined' sx={{ minWidth: 0, minHeight: 0, p: 0 }} className={className} onClick={loading ? undefined : onClick}>
      <Box sx={{ pl: 1, pr: 1, display: 'flex', width: '100%' }} alignItems='center' justifyContent='center'>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <Typography variant='body1' color={color.grey1} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {strings.shipping_button}
          </Typography>
        )}
      </Box>
      <Box
        sx={{ backgroundColor: isLate ? 'red' : 'black', borderTopRightRadius: '35%', borderBottomRightRadius: '35%', display: 'flex' }}
        height='30px'
        width='24px'
        alignItems='center'
        justifyContent='center'>
        <ArrowForwardIcon fontSize='small' sx={{ color: 'white' }} />
      </Box>
    </Button>
  )
}
