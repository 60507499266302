import { yupResolver } from '@hookform/resolvers/yup'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, CardContent, Dialog, FormControl, FormHelperText, Grid, Typography, useMediaQuery } from '@mui/material'
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement, StripeCardElementChangeEvent, StripeIbanElementChangeEvent } from '@stripe/stripe-js'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { object, ref, string } from 'yup'
import { useStrings } from '../../assets/localization/strings'
import { PaymentType, VideoDuration, VideoFeature, VideoType } from '../../models/GeneralTypes'
import countries from '../../reusableUtils/countries.json'
import rootStore from '../../stores/rootStore'
import { color, theme } from '../../theme'
import vatRegEx from '../../vatRegEx.json'
import { PricingBasedOnInvoice, PricingFormContext, PricingInUGCForm, usePricingForm } from '../createUgcCampaign/Pricing'
import { IUGCCampaign } from '../createUgcCampaign/usePersistedUGCForm'
import ReactHookFormCheckbox from './form/ReactHookFormCheckbox'
import ReactHookFormSelect from './form/ReactHookFormSelect'
import ReactHookFormTextField from './form/ReactHookFormTextField'
import { ICompanyForm, OrderProps } from '../../types/paymentModal'

type Props = {
  open: boolean
  handleClose(): void
  callback(coupon?: string): Promise<void>
} & (
  | {
      paymentType: 'UGC_CAMPAIGN'
      campaignForm: Pick<UseFormReturn<IUGCCampaign>, 'control' | 'setValue'>
      setBrand(brand: any): void
    }
  | {
      title?: string
      body?: string
      paymentType?: never
      numberOfVideos?: number
      videoType?: VideoType
      videoFeatures?: VideoFeature[]
      videoDuration?: VideoDuration
    }
)

const PaymentModal = observer((props: Props) => {
  const strings = useStrings()
  const stripe = useStripe()
  const elements = useElements()
  const { enqueueSnackbar } = useSnackbar()

  const { open, handleClose, paymentType, callback } = props
  const { selectedBrand } = rootStore.brandStore

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentType>()
  const [loading, setLoading] = useState(false)
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)
  const [coupon, setCoupon] = useState<string | undefined>(undefined)

  if (!selectedBrand) return <>{strings.error_reload_body}</>
  const { companyName, address, city, zip, country, vatNumber, hasPaymentMethod, contactEmail, contactPerson } = selectedBrand

  const companyDetailsFinished = companyName && address && city && zip && country
  const hideForm = companyDetailsFinished && hasPaymentMethod

  const schema = object().shape({
    companyName: string().required(strings.payment_modal_yup_company_name),
    address: string().required(strings.payment_modal_yup_company_address),
    country: string().required(strings.payment_modal_yup_company_country),
    city: string().required(strings.payment_modal_yup_company_city),
    zip: string().required(strings.payment_modal_yup_company_zip),
    vatNumber: string().test((vatNumber, { createError, path, resolve }) => {
      const country = resolve(ref('country')) as string
      const check = resolve(ref('vatNumberCheck')) as boolean
      if (!check) return true
      if (!vatNumber) return createError({ message: strings.payment_modal_yup_company_vat_number1, path })
      const regex = (vatRegEx as Record<string, string>)[country]
      const regionNames = new Intl.DisplayNames(['de'], { type: 'region' })
      if (!new RegExp(regex).test(vatNumber)) return createError({ message: strings.payment_modal_yup_company_vat_number2(regionNames.of(country) || ''), path })
      return true
    }),
    payment_method_id: string().required(strings.payment_modal_yup_company_payment_method)
  })
  const methods = useForm<ICompanyForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      vatNumberCheck: hasPaymentMethod ? false : true,
      companyName,
      address,
      city,
      zip,
      country,
      vatNumber,
      payment_method_id: hasPaymentMethod ? 'FULLFILLED' : undefined
    }
  })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    watch,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    trigger
  } = methods

  useEffect(() => {
    setValue('vatNumberCheck', vatNumber ? true : hasPaymentMethod ? false : true)
    setValue('companyName', companyName ?? '')
    setValue('address', address ?? '')
    setValue('city', city ?? '')
    setValue('zip', zip ?? '')
    setValue('country', country ?? '')
    setValue('vatNumber', vatNumber ?? '')
    if (hasPaymentMethod) setValue('payment_method_id', 'FULLFILLED')
  }, [selectedBrand])

  const [payDisabled, setPayDisabled] = useState(false)
  useEffect(() => {
    if (props.paymentType == 'UGC_CAMPAIGN') {
      const obj = watch()
      if (!obj.vatNumberCheck) {
        delete (obj as any).vatNumber
        clearErrors('vatNumber')
      }
      if (schema.isValidSync(getValues())) props.setBrand(obj)
    }
    const isValid = schema.isValidSync(getValues())
    setPayDisabled(!isValid)
    if (getValues('country')) trigger('vatNumber')
    else clearErrors('vatNumber')
  }, [JSON.stringify(watch())])

  const formSubmitHandler = async ({ payment_method_id, companyName, address, city, country, zip, vatNumber, vatNumberCheck }: ICompanyForm) => {
    setLoading(true)

    try {
      // If the form is shown we need to add the paymentmethod & update the company
      const newPaymentMethod = !hideForm && selectedPaymentMethod && stripe && payment_method_id != 'FULLFILLED'
      if (newPaymentMethod) {
        const client_secret = await rootStore.brandStore.startPayment(
          selectedPaymentMethod,
          payment_method_id,
          address,
          city,
          zip,
          country,
          companyName,
          vatNumberCheck ? vatNumber : undefined
        )
        const iban = elements?.getElement(IbanElement)
        if (selectedPaymentMethod == PaymentType.Sepa && iban && client_secret) {
          const { setupIntent } = await stripe.confirmSepaDebitSetup(client_secret, {
            payment_method: { sepa_debit: iban, billing_details: { name: contactPerson, email: contactEmail } }
          })

          if (setupIntent?.payment_method) await rootStore.brandStore.attachPaymentMethod(`${setupIntent.payment_method}`)
        }
      } else if (!companyDetailsFinished) {
        await rootStore.brandStore.updateBrand({ ...selectedBrand, companyName, address, city, zip, country, vatNumber })
      }

      await callback(coupon)

      // update brand after method was saved so that 'iban' is not unmounted prematurely
      if (newPaymentMethod) selectedBrand.hasPaymentMethod = true
    } catch (e) {
      console.error(e)
      if (e.status === 422) {
        enqueueSnackbar(strings.payment_modal_coupon_invalid, { variant: 'error' })
      } else {
        enqueueSnackbar(strings.error, { variant: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCreditChange = async (event: StripeCardElementChangeEvent) => {
    clearErrors('payment_method_id')
    if (!stripe || !elements) return

    if (!event.complete) {
      return setValue('payment_method_id', '')
    }

    const card = elements.getElement(CardElement)

    if (!card) return

    try {
      const payload = await stripe.createPaymentMethod({ type: 'card', card: card as StripeCardElement })

      if (payload?.paymentMethod) {
        setValue('payment_method_id', payload.paymentMethod.id)
      }

      if (payload?.error) {
        setError('payment_method_id', { type: 'custom', message: strings.payment_modal_method_choose_error })
        setValue('payment_method_id', '')
      }
    } catch (e) {
      setError('payment_method_id', { type: 'custom', message: strings.error2 })
    }
  }

  const handleIbanChange = async (event: StripeIbanElementChangeEvent) => {
    clearErrors('payment_method_id')
    if (!stripe || !elements) {
      return
    }

    if (!event.complete) {
      return setValue('payment_method_id', '')
    }

    const iban = elements.getElement(IbanElement)

    if (!iban) return

    // We don't get a payment method here we only receive it after submitting due to sepa intents
    setValue('payment_method_id', 'iban')
  }

  const handleInvoiceLoadingChange = (value: boolean) => {
    setIsInvoiceLoading(value)
  }

  const renderButtons = () => (
    <Grid container item justifyContent='flex-end' mt={2} spacing={2}>
      <Grid item>
        <Button variant='contained' color='secondary' disabled={loading} onClick={() => handleClose()}>
          {strings.payment_modal_cancel}
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          variant='contained'
          onClick={methods.handleSubmit(formSubmitHandler)}
          form='paymentForm'
          loading={loading}
          disabled={payDisabled || isInvoiceLoading}
          id='CREATE_CAMPAIGN_PAY'>
          {strings.payment_modal_pay}
        </LoadingButton>
      </Grid>
    </Grid>
  )

  const renderPaymentMethodContainer = () => {
    const renderSelectPaymentMethod = () => {
      return (
        <Grid container flexDirection='column' spacing={2} mt={0} height={isMobile ? undefined : '100%'} alignItems='center' justifyContent='center'>
          <Grid item>
            <Typography variant='h4'>{strings.payment_modal_choose_method}</Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' startIcon={<CreditCardIcon />} onClick={() => setSelectedPaymentMethod(PaymentType.Creditcard)}>
              {strings.payment_modal_choose_method_creditcard}
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' startIcon={<AccountBalanceIcon />} onClick={() => setSelectedPaymentMethod(PaymentType.Sepa)}>
              {strings.payment_modal_choose_method_sepa}
            </Button>
          </Grid>
        </Grid>
      )
    }

    const renderCreditCard = () => {
      return (
        <>
          <CardElement options={{ hidePostalCode: true, style: { base: { fontSize: '16px' } } }} onChange={handleCreditChange} />
          {!!errors.payment_method_id && (
            <FormControl error>
              <FormHelperText>{errors.payment_method_id.message}</FormHelperText>
            </FormControl>
          )}
        </>
      )
    }

    const renderSepa = () => {
      return (
        <>
          <IbanElement options={{ supportedCountries: ['SEPA'] }} onChange={handleIbanChange} />

          {errors.payment_method_id ? (
            <FormControl error>
              <FormHelperText>{errors.payment_method_id.message}</FormHelperText>
            </FormControl>
          ) : (
            <Typography variant='caption' color={color.black} lineHeight={1} fontSize='8px'>
              {strings.brand_payment_sepa_agreement}
            </Typography>
          )}
        </>
      )
    }

    const renderPaymentMethodForm = () => {
      return (
        <Grid container flexDirection='column' spacing={2} height='100%' alignItems='center' justifyContent={isMobile ? 'start' : 'center'} mt={isMobile ? 2 : 0}>
          <Grid item>
            <Card variant='outlined' sx={{ pl: 2, pr: 2 }}>
              <CardContent>
                <Grid container>
                  {!hasPaymentMethod && (
                    <Grid item xs={12}>
                      {selectedPaymentMethod == PaymentType.Creditcard ? renderCreditCard() : renderSepa()}
                    </Grid>
                  )}
                  <Grid item xs={12} mt={2}>
                    <ReactHookFormCheckbox name='vatNumberCheck' label={strings.payment_modal_method_company_info_vatnumber_check} />
                  </Grid>
                  {watch('vatNumberCheck') && (
                    <Grid item xs={12} mt={2}>
                      <ReactHookFormTextField name='vatNumber' label={strings.payment_modal_method_company_info_vatnumber} />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card variant='outlined' sx={{ pl: 2, pr: 2 }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5'>{strings.payment_modal_method_company_info_title}</Typography>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <ReactHookFormTextField name='companyName' label={strings.payment_modal_method_company_info_name} showHelperError={false} />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <ReactHookFormSelect
                      name='country'
                      label={strings.payment_modal_method_company_info_country}
                      options={countries.map(c => ({ value: c.code, label: c.name }))}
                      showHelperError={false}
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <ReactHookFormTextField name='zip' label={strings.payment_modal_method_company_info_zip} showHelperError={false} />
                  </Grid>
                  <Grid item xs={6} pl={2} mt={2}>
                    <ReactHookFormTextField name='city' label={strings.payment_modal_method_company_info_city} showHelperError={false} />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <ReactHookFormTextField name='address' label={strings.payment_modal_method_company_info_address} showHelperError={false} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {renderButtons()}
        </Grid>
      )
    }

    if (!selectedPaymentMethod) return <>{renderSelectPaymentMethod()}</>

    return <>{renderPaymentMethodForm()}</>
  }

  const renderOrderContainer = (params: OrderProps) => {
    const renderInner =
      paymentType == 'UGC_CAMPAIGN' ? (
        <Box mt={2} width='100%'>
          <PricingInUGCForm {...params} />
        </Box>
      ) : (
        open && <PricingInPaymentModal {...props} {...params} setCoupon={setCoupon} />
      )

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h2' textAlign='center'>
            {strings.payment_modal_title}
          </Typography>
        </Grid>
        {renderInner}
        {hideForm && renderButtons()}
      </Grid>
    )
  }

  const orderContainerParams: OrderProps = {
    formValues: getValues(),
    isInvoiceLoading,
    onChangeLoadingInvoice: handleInvoiceLoadingChange
  }

  return (
    <FormProvider {...methods}>
      <form id='paymentForm'>
        <Dialog keepMounted disableEscapeKeyDown={loading} open={open} onClose={loading ? undefined : handleClose} maxWidth={hideForm ? 'xs' : 'md'} fullWidth>
          <Grid container>
            <Grid container item xs={12} sm={hideForm ? 12 : 6} sx={{ [theme.breakpoints.not('xs')]: { p: 5 } }} alignItems='center'>
              {renderOrderContainer(orderContainerParams)}
            </Grid>
            {!hideForm && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ borderLeft: '1px solid', borderColor: color.grey6, minHeight: isMobile ? undefined : '700px', [theme.breakpoints.not('xs')]: { p: 3 } }}>
                {renderPaymentMethodContainer()}
              </Grid>
            )}
          </Grid>
        </Dialog>
      </form>
    </FormProvider>
  )
})

type PricingInPaymentModalProps = {
  brand?: object
  numberOfVideos?: number
  videoType?: VideoType
  videoFeatures?: VideoFeature[]
  videoDuration?: VideoDuration
  title?: string
  body?: string
  isInvoiceLoading: boolean
  setCoupon: React.Dispatch<React.SetStateAction<string | undefined>>
  onChangeLoadingInvoice: (value: boolean) => void
}
const PricingInPaymentModal = ({
  brand,
  numberOfVideos,
  videoDuration,
  videoType,
  videoFeatures,
  title,
  body,
  isInvoiceLoading,
  setCoupon,
  onChangeLoadingInvoice
}: PricingInPaymentModalProps) => {
  const pricingFormContext = useContext(PricingFormContext)
  const pricingForm = usePricingForm({ numberOfVideos, videoDuration, videoType, videoFeatures, product: undefined, brand })

  useEffect(() => {
    setCoupon(pricingForm.couponCode)
  }, [pricingForm.couponCode])

  const renderPricing = () => <PricingBasedOnInvoice mt={1} title={title} body={body} isInvoiceLoading={isInvoiceLoading} onChangeLoadingInvoice={onChangeLoadingInvoice} />
  return pricingFormContext ? renderPricing() : <PricingFormContext.Provider value={pricingForm}>{renderPricing()}</PricingFormContext.Provider>
}

export default PaymentModal
