import { Storage } from 'aws-amplify'
import imageCompression from 'browser-image-compression'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { v4 } from 'uuid'

export type State<Name extends string, T> = { [N in `${Name}` | `set${Capitalize<Name>}`]: N extends `set${string}` ? Dispatch<SetStateAction<T>> : T }
export type Empty = Record<never, never> & {}

export const mock = <T extends { [key: string]: Function }>(funcs: T) => {
  if (process.env.REACT_APP_STAGE != 'development' && process.env.REACT_APP_STAGE != 'local') return
  const mock = (window as any)['mock'] ?? ((window as any)['mock'] = {})
  Object.assign(mock, Object.fromEntries(Object.entries(funcs).map(p => [p[0], p[1].bind(funcs)])))
}

export function exclude<TObj extends object, TList extends (keyof TObj)[]>(obj: TObj, ...keys: TList) {
  const existing = Object.keys(obj) as (keyof TObj)[]
  type Return = { [P in keyof TObj as P extends TList[number] ? never : TObj[P] extends (...args: any) => any ? never : P]: TObj[P] }
  return keep(obj, ...existing.filter(key => !keys.includes(key))) as unknown as Return
}
const numberToEuro = (x: number) => {
  return (x / 100).toFixed(2)
}

/**
 * Strips the object's fields and saves only those that were written in keys. Types of keys and return are strict
 * @param obj fields to strip from
 * @param keys keep given keys
 * @returns stripped object
 */
export function keep<TObj extends object, TList extends (keyof TObj)[]>(obj: TObj, ...keys: TList) {
  const newObj: Record<string, any> = {}
  for (const key of keys) newObj[key as string] = obj[key]
  return newObj as { [Key in TList[number]]: TObj[Key] }
}

const millisToAge = (x: number) => {
  const ageInMilliseconds = Date.now() - x
  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365) // convert to years
}

const s3bucketLink = `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/`

const uploadToS3 = async (file: File, progress?: (percent: number) => void) => {
  await Storage.put(file.name, file, {
    level: 'public',
    contentType: file.type,
    progressCallback: ({ loaded = 0, total = 0 }) => progress?.(loaded / total),
    completeCallback: () => progress?.(1),
    errorCallback: () => progress?.(1)
  })
  return `${s3bucketLink}${file.name}`
}
const fileToBase64 = async (file: File) => {
  const reader = new FileReader()
  const compressedFile = await imageCompression(file, { maxSizeMB: 2 })
  return await new Promise<string>((resolve, reject) => {
    reader.readAsDataURL(compressedFile)
    reader.onload = () => resolve(reader.result?.toString() || '')
    reader.onerror = error => reject(error)
  })
}
const base64toFile = async (base64: string | URL, filename: string) => {
  const res = await fetch(base64)
  const blob = await res.blob()
  return new File([blob], filename, { type: blob.type })
}

export function useForceUpdate() {
  const [, setState] = useState(true)
  return () => setState(value => !value)
}

export const createBasicPersitence = <T extends Record<string, unknown> & object>(key: string) => {
  const get = () => JSON.parse(window.localStorage.getItem(key) || '{}') as T
  const set = (value: T) => window.localStorage.setItem(key, JSON.stringify(value))
  return {
    get,
    set: (valueOrFunc: T | ((_: T) => void)) => {
      if (typeof valueOrFunc == 'function') {
        const value = get()
        valueOrFunc(value)
        set(value)
      } else set(valueOrFunc)
    }
  }
}

export function check(condition: any, error?: string | Error): asserts condition {
  if (!condition) {
    if (error instanceof Error) throw error
    else throw new Error(error ?? 'Failed a check')
  }
}

export const cancelError = new Error('Cancelled')
export const useCancellablePromise = () => {
  const idRef = useRef(v4())
  return async (execute: (ensureisActive: () => void) => Promise<void>) => {
    const id = v4()
    idRef.current = id
    const ensureisActive = () => {
      if (idRef.current != id) throw cancelError
    }
    try {
      ensureisActive()
      await execute(ensureisActive)
    } catch (e) {
      if (e !== cancelError) throw e
    }
  }
}
export const delay = (delay: number) => new Promise(res => setTimeout(() => res(undefined), delay))

export function extractUserMessageFromError(e: any) {
  if (e?.status != 422) return undefined
  const { errorMessage, message }: { errorMessage?: string; message?: string } = JSON.parse(e.response?.text ?? '{}')
  return (errorMessage ?? message)?.substring(6)
}

declare global {
  interface Array<T> {
    /**
     * Wraps this array in Promise.all
     */
    awaitAll(): Promise<Awaited<T>[]>
    /**
     * Works exactly like .sort, but this function creates new array
     * @returns sorted array
     */
    sorted(compareFn?: (a: T, b: T) => number): T[]
    /**
     * Returns sorted in ascending order, based on value of element
     * @returns sorted array
     */
    ascendingSorted(value: (v: T) => number): T[]
    ascendingSorted(this: { valueOf(): number }[]): T[]
    /**
     * Filter out undefined or null
     */
    filterNotNull(): NonNullable<T>[]
    /**
     * Works exactly like .map, except it doesn't include null elements
     */
    compactMap<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): NonNullable<U>[]
    /**
     * Return last element
     */
    last(): T
    /**
     * Return last element, but can be null
     */
    lastOrNull(): T | undefined
    /**
     * Check if all elements match the predicate
     */
    all(predicate: (value: T) => boolean): boolean
    /**
     * Check if at least one element matches the predicate
     */
    any(predicate: (value: T) => boolean): boolean

    /**
     * Works like .reverse(), but returns new reversed array
     */
    reversed(): T[]
    /**
     * Returns only unique items from array
     */
    unique(): T[]
  }
}

Array.prototype.awaitAll = async function () {
  return await Promise.all(this)
}
Array.prototype.sorted = function <T>(compareFn?: (a: T, b: T) => number): T[] {
  const sortedArray = [...this]
  sortedArray.sort(compareFn)
  return sortedArray
}
Array.prototype.ascendingSorted = function <T>(value: (v: T) => number = v => (v as any).valueOf() as number) {
  const sortedArray = [...this]
  sortedArray.sort((a, b) => value(a) - value(b))
  return sortedArray
}
Array.prototype.filterNotNull = function <T>() {
  return this.filter((v?: T) => v != null)
}
Array.prototype.compactMap = function <T>(callbackfn: (value: T, index: number, array: T[]) => any) {
  return this.map(callbackfn).filterNotNull()
}
Array.prototype.last = function () {
  return this[this.length - 1]
}
Array.prototype.lastOrNull = function () {
  return this.last()
}
Array.prototype.all = function <T>(predicate: (value: T) => boolean) {
  for (const value of this) if (!predicate(value)) return false
  return true
}
Array.prototype.any = function <T>(predicate: (value: T) => boolean) {
  for (const value of this) if (predicate(value)) return true
  return false
}
Array.prototype.reversed = function () {
  const array = [...this]
  return array.reverse()
}
Array.prototype.unique = function () {
  return Array.from(new Set(this))
}

const endsWithNumber = (str?: string) => {
  if (!str) return false
  return isNaN(+str.slice(-1)) ? false : true
}

const URL_REGEX =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

function base64StringtoFile(base64String, filename) {
  const arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export { numberToEuro, millisToAge, uploadToS3, fileToBase64, base64toFile, endsWithNumber, URL_REGEX, base64StringtoFile }
