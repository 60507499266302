import { yupResolver } from '@hookform/resolvers/yup'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ReportIcon from '@mui/icons-material/Report'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, Hidden, IconButton, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { array, boolean, object, SchemaOf, string } from 'yup'
import { Role } from '../../../../getnano-backend/utils/Types'
import { useStrings } from '../../assets/localization/strings'
import { FocusableSections, Sections, useSections } from '../../reusableUtils/useSections'
import rootStore from '../../stores/rootStore'
import { color, theme } from '../../theme'
import { MockView } from '../common/MockView'
import { MediaStore } from '../createUgcCampaign/MediaStore'
import ErrorReload from '../error/ErrorReload'
import BrandContact from './BrandContact'
import BrandInfo from './BrandInfo'
import BrandUsers from './BrandUsers'

type IUser = { id?: string; email: string; role: string }

export interface IBrandForm {
  brandName: string
  brandLogo: string // as base64
  firstname: string
  surname: string
  contactEmail: string
  contactPhone: string
  user: string
  users: IUser[]
  isAgency?: boolean
  industry: string
}

// https://v5.reactrouter.com/web/example/query-parameters
const useQueryParams = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const BrandForm = observer(() => {
  const history = useHistory()
  const query = useQueryParams()

  const [loading, setLoading] = useState(false)
  const { authenticatedUser } = rootStore.userStore
  const strings = useStrings()
  if (!authenticatedUser) return <ErrorReload />

  const userArray: SchemaOf<IUser> = object({
    id: string().optional(),
    email: string().email(strings.brand_creation_yup_email1).required(strings.brand_creation_yup_email2),
    role: string().required(strings.brand_creation_yup_role)
  })

  const schema = object().shape({
    brandName: string().required(strings.brand_creation_yup_brand_name),
    brandLogo: string().optional(),
    firstname: string().required(strings.brand_creation_yup_firstname),
    surname: string().required(strings.brand_creation_yup_surname),
    contactEmail: string().email().required(strings.brand_creation_yup_email),
    contactPhone: string()
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, strings.brand_creation_yup_mobile1)
      .required(strings.brand_creation_yup_mobile),
    users: array().of(userArray).optional(),
    isAgency: boolean().optional(),
    industry: string().required(strings.brand_creation_yup_industry)
  })
  const methods = useForm<IBrandForm>({
    resolver: yupResolver(schema),
    defaultValues: { firstname: authenticatedUser.firstname, surname: authenticatedUser.surname, contactEmail: authenticatedUser.email, isAgency: !!query.get('agency'), users: [] }
  })

  const isAgency = useWatch({ control: methods.control, name: 'isAgency' })
  const { sections, lastVisible, setViewRefs, viewRefs } = useSections([
    [() => <BrandInfo />, isAgency ? strings.brand_creation_section_agency_info : strings.brand_creation_section_brand_info],
    [() => <BrandContact />, strings.brand_creation_section_contact],
    !isAgency ? null : [() => <BrandUsers />, strings.brand_creation_section_users]
  ])

  const formSubmitHandler: SubmitHandler<IBrandForm> = async ({ brandName, brandLogo, firstname, surname, contactEmail, contactPhone, users, industry }) => {
    setLoading(true)

    const brandLogoUrl = brandLogo ? await MediaStore.getPromiseFromStore(brandLogo).catch(() => (alert(strings.campaign_creation_upload_brand_logo_error), '')) : ''

    try {
      const newBrand = {
        brandName,
        brandLogo: brandLogoUrl,
        contactPerson: `${firstname} ${surname}`,
        contactEmail,
        contactPhone,
        users: users.map(({ email, role }) => ({ email, role: role as Role })),
        isAgency: !!query.get('agency'),
        industry
      }

      const brandId = await rootStore.brandStore.createBrand(newBrand)
      history.push(`${brandId}/dashboard`)
    } catch (e) {
      //@ts-ignore
      const error = JSON.parse(e.message)
      //@ts-ignore
      console.log(e.message.errorMessage)
      alert(error.errorMessage)
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <MockView name='BrandForm'>
        <Stack direction='row'>
          <Button onClick={() => methods.setValue('isAgency', !isAgency)}>Toggle isAgency</Button>
        </Stack>
      </MockView>
      <form onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <Grid sx={{ height: 'calc(100vh - 112px)', position: 'relative', background: '#FDFDFD' }}>
          <Hidden mdDown>
            <Grid sx={{ height: '100%', width: '220px', position: 'absolute', top: 0, left: 0, overflowX: 'hidden', borderRight: '1px solid #e0e0e0' }}>
              <Grid container direction='column' alignItems='center' justifyContent='center' height='100%'>
                <Grid item xs={3}>
                  <Sections lastVisible={lastVisible} sections={sections} viewRefs={viewRefs} />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid
            sx={{
              height: '100%',
              ml: '260px',
              mr: '350px',
              overflowX: 'scroll',
              p: '25px',
              pb: '50px',
              maxWidth: '900px',
              [theme.breakpoints.down('md')]: { ml: 0 },
              [theme.breakpoints.down('lg')]: { mr: 0 }
            }}>
            <Grid container direction='column' spacing={3} mt='48px'>
              {rootStore.brandStore.selectedBrand?.brandId && (
                <Grid item xs={12}>
                  <IconButton onClick={() => history.push(`/${rootStore.brandStore.selectedBrand?.brandId}/dashboard`)}>
                    <ArrowBackIosIcon fontSize='small' />
                    <Typography ml={1} variant='body2'>
                      {strings.back}
                    </Typography>
                  </IconButton>
                </Grid>
              )}
              <FocusableSections sections={sections} setViewRefs={setViewRefs} />
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              left: 0,
              overflowX: 'hidden',
              zIndex: 10,
              backgroundColor: color.white,
              padding: '5px',
              borderTop: '1px solid #e0e0e0'
            }}>
            <Grid container pr={2}>
              <Grid item xs={12} justifyContent='end' container alignItems='center'>
                {Object.keys(methods.formState.errors).length !== 0 && (
                  <>
                    <ReportIcon fontSize='small' color='error' />
                    <Typography variant='caption' color='error' pl={1} pr={2}>
                      {strings.brand_creation_error}
                    </Typography>
                  </>
                )}
                <LoadingButton variant='contained' type='submit' loading={loading} id='CREATE_BRAND_FORM'>
                  {isAgency ? strings.brand_creation_create_agency : strings.brand_creation_create_brand}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
})

export default BrandForm
