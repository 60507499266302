import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useStrings } from '../../../assets/localization/strings'
import { ShippingService, ShippingServicesArray } from '../../../models/GeneralTypes'
import { ParticipantUgc } from '../../../requestModels'

type Props = {
  open: boolean
  onClose: () => void
  handleSent: (_: ParticipantUgc, shippingCode: string, shippingService: ShippingService | string) => Promise<void>
  influencer: ParticipantUgc | undefined
  loading: boolean
}

export const ShippingDialog = ({ open, onClose, handleSent, influencer, loading }: Props) => {
  const [shippingCode, setShippingCode] = useState('')
  const [otherShippingService, setOtherShippingService] = useState('')
  const [shippingService, setShippingService] = useState<ShippingService | string>(ShippingService.AT_POST)
  const strings = useStrings()

  const onSent = async () => {
    if (influencer) {
      const service = shippingService !== ShippingService.OTHER ? shippingService : otherShippingService

      await handleSent(influencer, shippingCode, service)
      setShippingCode('')
    } else {
      alert(strings.error)
    }
  }

  const isDisabled = !shippingCode || !shippingService || (shippingService === ShippingService.OTHER && !otherShippingService) || loading

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ maxWidth: '350px' }}>
        <DialogTitle>{strings.shipping_title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>{strings.shipping_body}</DialogContentText>
          <Select
            labelId={strings.shipping_service}
            id='demo-simple-select'
            value={shippingService}
            fullWidth
            label='Age'
            //@ts-ignore
            onChange={event => setShippingService(event.target.value)}>
            {ShippingServicesArray.map(shippingServiceItem => (
              <MenuItem value={shippingServiceItem} key={shippingServiceItem}>
                {shippingServiceItem}
              </MenuItem>
            ))}
          </Select>
          {shippingService === ShippingService.OTHER && (
            <TextField
              autoFocus
              margin='dense'
              id='otherShippingService'
              label={strings.shipping_other_service}
              color='primary'
              value={otherShippingService}
              onChange={event => setOtherShippingService(event.target.value)}
              type='text'
              fullWidth
              variant='standard'
            />
          )}
          <TextField
            autoFocus
            margin='dense'
            id='shippingCode'
            label={strings.shipping_tracking_code_label}
            color='primary'
            value={shippingCode}
            onChange={event => setShippingCode(event.target.value)}
            type='text'
            fullWidth
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <button onClick={onSent} disabled={isDisabled}>
            {strings.shipping_sent}
          </button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ShippingDialog
