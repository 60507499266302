import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import pauseIcon from '../../assets/icons/pauseIcon.svg'
import playIcon from '../../assets/icons/playIcon.svg'
import styles from '../../style/contentCampaign/content.module.css'
import { color } from '../../theme'

export const Player = ({ src, children, height = 340, width = 192 }: { src?: string; children?: JSX.Element; height?: number; width?: number }) => {
  const borderRadius = '20px'
  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<ReactPlayer | null>(null)

  const p = 0.5

  return (
    <Box width={`${width + 8}px`} height={`${height}px`} position='relative'>
      <Paper elevation={5} sx={{ borderRadius, p, position: 'absolute' }}>
        <ReactPlayer
          ref={videoRef}
          playing={playing}
          onEnded={() => {
            setPlaying(false)
            videoRef.current?.seekTo(0)
          }}
          onPlay={() => setPlaying(true)}
          url={src}
          width={`${width}px`}
          height={`${height}px`}
          stopOnUnmount
          style={{ borderRadius, overflow: 'hidden' }}
        />
      </Paper>

      <Box position='absolute' top={`${height / 2 + p * 2 * 8 - 20}px`} left={`${width / 2 - 10}px`}>
        <IconButton onClick={() => setPlaying(p => !p)}>
          <img src={playing ? pauseIcon : playIcon} alt='play' style={{ width: '32px' }} />
        </IconButton>
      </Box>
      <Stack position='absolute' width='inherit' height={`${height + p * 2 * 8}px`} sx={{ pointerEvents: 'none' }}>
        <Box sx={{ pointerEvents: 'auto' }}>{children}</Box>
      </Stack>
    </Box>
  )
}

export const PlayerBottomText = ({ name, icon, onClick }: { name: string; icon?: string; onClick?(): void }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
    <Stack
      onClick={onClick}
      direction='row'
      alignItems='center'
      justifyContent='start'
      sx={{ borderRadius: 1, p: 1, borderEndEndRadius: 0, borderEndStartRadius: 0, position: 'absolute', maxWidth: '70%', bottom: 0, background: color.white }}>
      <img className={styles.infoProfilePicture} src={icon} />
      <Typography textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
        {name}
      </Typography>
    </Stack>
  </Box>
)
