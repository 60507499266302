import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useStrings } from '../../assets/localization/strings'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import rootStore from '../../stores/rootStore'

interface IFeedbackModalProps {
  open: boolean
  onClose: () => void
}

const FeedbackDialog = (props: IFeedbackModalProps) => {
  const strings = useStrings()
  const { enqueueSnackbar } = useSnackbar()

  const { open, onClose } = props
  const [feedback, setFeedback] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
      await rootStore.userStore.giveFeedback(feedback)
      enqueueSnackbar(strings.feedbackDialogSuccess, { variant: 'success' })
      setFeedback('')
    } catch (e) {
      console.error(e)
    }

    setLoading(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{strings.feedbackDialogTitle}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText mb={2}>{strings.feedbackDialogDescription}</DialogContentText>
        <TextField
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          autoFocus
          id='feedback'
          label={strings.feedbackDialogTextfield}
          type='text'
          fullWidth
          size='small'
          variant='outlined'
          multiline={true}
          maxRows={5}
          rows={5}
        />
      </DialogContent>
      <DialogActions sx={{ mt: 1 }}>
        <LoadingButton onClick={() => onSubmit()} loading={loading} variant='outlined' disabled={!feedback.length}>
          {strings.feedbackDialogSubmitButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackDialog
