/* Used for Videotyp and Videopackage */

import { Button, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { color } from '../../../theme'
import InfoChip from './InfoChip'

export interface IRadioCardGroupOption {
  image: string
  imageActive: string
  value: string | number
  title: string
  description: string
  descriptionSmall?: string
  topBadge?: string
  hideRadio?: boolean
}

interface IReactHookFormRadioCardGroupIconProps {
  name: string
  defaultValue: string | number
  options: IRadioCardGroupOption[]
}

const ReactHookFormRadioCardGroupIcon = ({ name, defaultValue, options }: IReactHookFormRadioCardGroupIconProps) => {
  const { control, watch, setValue } = useFormContext()
  const isReadonly = useReadonly(name)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup {...field} onChange={() => {}}>
          <Grid container spacing={2}>
            {options.map(o => (
              <Grid item key={o.value} xs={6} sm={4} md={3}>
                <Button
                  sx={{
                    border: '1.5px solid',
                    borderColor: o.value === watch(name) ? color.stylinkDarkPurple : color.grey3,
                    borderRadius: '10px',
                    textTransform: 'none',
                    height: '100%',
                    alignItems: 'flex-start',
                    position: 'relative',
                    padding: 0,
                    background: o.value === watch(name) ? color.backgroundPurple : 'inherit'
                  }}
                  value={o.value}
                  disabled={isReadonly}
                  onClick={
                    () => setValue(name, o.value) // type is wrong when clicked on radio it gets string
                  }>
                  {!o.hideRadio && <Radio checked={o.value === watch(name)} sx={{ position: 'absolute', right: '0px', top: '0px' }} value={o.value} />}
                  {o.topBadge && <InfoChip label={o.topBadge} />}
                  <Grid container>
                    <Grid item xs={12} pt={3}>
                      <img alt={'' + o.value} src={o.value === watch(name) ? o.imageActive : o.image} height='60' width='60' />
                    </Grid>
                    <Grid item container xs={12} p={1}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: color.grey2
                          }}
                          textAlign='center'>
                          {o.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: '15px',
                            fontWeight: 600,
                            color: o.value === watch(name) ? color.stylinkPurple : color.grey2
                          }}
                          textAlign='center'>
                          {o.title}
                        </Typography>
                      </Grid>
                      {o.descriptionSmall && (
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: 600,
                              color: color.grey2
                            }}
                            textAlign='center'>
                            {o.descriptionSmall}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      )}
    />
  )
}

export default ReactHookFormRadioCardGroupIcon
