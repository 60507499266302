import { Tab, Tabs } from '@mui/material'
import React from 'react'

interface ITab {
  value: string
  label: string
  icon?: React.ReactElement
}

const TabNavigation = ({ tabs, selectedTab, setTab }: { tabs: ITab[]; selectedTab: string; setTab: (id: string) => void }) => {
  return (
    <Tabs value={selectedTab} onChange={(_, id) => setTab(id)} variant='scrollable' allowScrollButtonsMobile>
      {tabs.map(tab => (
        <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} iconPosition='start' />
      ))}
    </Tabs>
  )
}

export default TabNavigation
